<template>
    <div>
        <span v-if="notification.read" class="badge badge-success badge-status" @click="changeStatus()"><i
            class="fa fa-envelope-open mr-2 "></i>{{ $t_notifications('read') }}</span>
        <span v-else class="badge badge-warning badge-status" @click="changeStatus()"><i
            class="fa fa-envelope mr-2 "></i>{{ $t_notifications('unread') }}</span>
    </div>
</template>
<script>


export default {
    name: 'NotificationsChangeStatus',
    props: {
        notification: {required: true},
    },
    created() {
    },
    computed: {},
    data() {
        return {}
    },
    methods: {
        changeStatus() {
            this.$store.dispatch('notifications/setReadNotification', this.notification.id)
        }
    }
}

</script>
<style>
.badge-status {
    font-size: 0.8rem !important;
    cursor: pointer;
}
</style>
