<template>
    <div>
        <v-client-table :ref='idTable' :data='items' :columns='columns' :options='options'>
            <div slot='beforeTable'>
                <div class='text-right'>
                    <button v-if="$helpers.hasPermission('alarms.edit')" @click='newItem' class='btn btn-primary btn-sm mb-2'><i class='fa fa-plus'></i>
                        {{ this.$alarms_locale.global.buttons.new }}
                    </button>
                </div>
            </div>
            <template v-for="(item) in customFields">
                <div v-html :slot="item.field" v-html="item.content"> {{ item.content }}</div>
            </template>

            <div slot='options' slot-scope='{row}' class=" text-center">
                <button v-if="$helpers.hasPermission('alarms.edit')" @click='editItem(row)' class='btn btn-xs btn-warning'><i class='fa fa-pencil'></i></button>
                <button v-if="$helpers.hasPermission('alarms.delete')" @click='deleteItem(row)' class='btn btn-xs btn-danger'><i class='fa fa-trash'></i></button>

            </div>
        </v-client-table>

    </div>
</template>
<script>
import 'animate.css';

export default {
    name: "AlarmTypesTable",
    props: {
        btnConfig: {default: false},
        customFilters: {required: false},
        columnsData: {required: true},
        idTable: {required: true},
        rowItems: {required: true},
        customFields: {required: false},
        enableCreateBtn: {default: true},
    },
    computed: {},
    components: {
    },
    created() {
        this.setColumns()
        this.items = [...this.rowItems]
    },
    mounted() {

    },

    watch: {
        rowItems: function (newItems) {
            this.items = [...newItems]
        },
    },
    data() {
        return {
            items: [],
            columns: [],
            currentFilter: [],
            options: {
                rowClassCallback: row => {
                    return row.disabled ? 'disabled-item' : null
                },

                resizableColumns: true,
                perPage: 10,
                perPageValues: [5, 10, 50, 100],
                pagination: {chunk: 5},
                datepickerOptions: {
                    showDropdowns: true,
                    autoUpdateInput: true,
                },
                sortIcon: {
                    base: 'fa float-right',
                    is: 'fa-sort',
                    up: 'fa-sort-up',
                    down: 'fa-sort-down',
                },
                texts: {
                    count: `${this.$t('Showing {from} to {to} of {count} records|{count} records|One record')}`,
                    first: `${this.$t('First')}`,
                    last: `${this.$t('Last')}`,
                    filter: `${this.$t('Filter')}:`,
                    filterPlaceholder: `${this.$t('Search query')}`,
                    limit: `${this.$t('Records')}:`,
                    page: `${this.$t('Page')}:`,
                    noResults: `${this.$t('No matching records')}`,
                    filterBy: `${this.$t('Filter by {column}')}`,
                    loading: `${this.$t('Loading')}...`,
                    defaultOption: `${this.$t('Select {column}')}`,
                    columns: `${this.$t('Columns')}`,
                },
                columnsDisplay: {},
            },
            selectChangeState: '',
        }
    },
    methods: {
        setColumns() {
            const headings = {}
            this.columnsData.map(item => {
                this.columns.push(item.field)
                headings[item.field] = item.label
                if (item.display) {
                    this.options.columnsDisplay[item.field] = item.display
                }
            })
            this.options.headings = headings
        },
        refreshData(items) {
            this.items = items
        },

        editItem(item) {
            this.$emit('edit', item)
        },
        deleteItem(item) {
            this.$emit('delete', item)
        },
        newItem() {
            this.$emit('new')
        },
        restore(item) {
            axios.get(`/api-admin/alarms/getAlarms/restore`).then(response => {
                this.filterResults()
            })
        },
    },
}
</script>

<style lang='scss'>

tr.VueTables__row.disabled-item {
    background-color: #ffe5e5 !important;
}

.VueTables {
    label {
        margin-right: 10px;
    }
}

.VueTables__wrapper {
    max-height: 500px;
    overflow-y: scroll;
}

.VueTables__search-field {
    display: flex;
}

.VueTables__limit-field {
    display: flex;
}

.VueTables__columns-dropdown-wrapper {
    margin-right: 10px;
}

.VueTables__row {
    td {
        padding: 3px 5px;
        vertical-align: middle;
    }
}

.VueTables__columns-dropdown {
    input {
        margin-right: 3px;
    }
}

.VueTables__limit-field {
    select {
        margin: 5% 0;
    }

}


</style>
