<template>
    <div>
        <card-template>
            <template slot="titleCard">{{ this.$t_notifications('channels.title') }}</template>
            <div class="table-responive">
                <table class="table table-striped table-condensed table-hover">
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>{{ this.$t_notifications('channels.columns.key') }}</th>
                        <th class="text-right" style="padding-right: 40px">{{ this.$t_notifications('channels.columns.status') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                     <channel-table-item v-for="channel in channelsList" :key="channel.key" :channel="channel"/>
                    </tbody>
                </table>
            </div>
        </card-template>
    </div>
</template>
<script>
import {mapGetters} from 'vuex'
import ChannelTableItem from './ChannelTableItem'

export default {
    name: 'NotificationChannelsConfig',
    created() {
        this.$store.dispatch('notifications/setChannelsListAction')
    },
    components: {
        ChannelTableItem
    },
    computed: {
        ...mapGetters('notifications', ['channelsList'])
    },
    data() {
        return {}
    },
    methods: {

    }
}

</script>
