<template>
    <card-template>
        <template slot="titleCard">
           <div class="row align-items-center">
               <div class="col-10">
                   {{ this.$tasks_locale.global.tasks_list }}
               </div>
               <div class="col-2 text-right">
                   <button class="btn btn-primary  " @click="goToCallendar()"><i class=" fa fa-calendar-days"></i></button>
               </div>
           </div>

        </template>
        <div class="table-responsive">
            <task-table
                :enableCreateBtn="true"
                :idTable="idTable"
                :states="states"
                :search="search"
                :columnsData="columns"
                :rowItems="items"
                :customFields="customFields"
                :customFilters="customFilters"
                :is_admin="is_admin"
                :btnConfig="false"
                @edit="editItem"
                @delete="deleteItem"
                @new="newItem"
                @config="setItem"
            >

            </task-table>
<!--            <inno-core-datatable-->
<!--                :enableCreateBtn="true"-->
<!--                :idTable="idTable"-->
<!--                :search="search"-->
<!--                :columnsData="columns"-->
<!--                :rowItems="items"-->
<!--                :customFields="customFields"-->
<!--                :customFilters="customFilters"-->
<!--                :btnConfig="false"-->
<!--                @edit="editItem"-->
<!--                @delete="deleteItem"-->
<!--                @new="newItem"-->
<!--                @config="setItem"-->
<!--            >-->
<!--            </inno-core-datatable>-->
        </div>
        <modal-confirm-delete @confirmed="removeItem"/>

    </card-template>
</template>
<script>
import TaskTable from './TaskTable'

export default{
    name: 'TasksList',
    components: {TaskTable},
    props:['states','is_admin'],
    created() {
        this.getTasks()
    },
    data() {
        return {
            idTable: 'tasksTable',
            items: [],
            search: '',
            customFields: [

            ],
            customFilters:[

            ],
            columns: [
                {
                    field: 'id',
                    label: '#',
                    display: 'min_tabletP',
                },
                {
                    field: 'name',
                    label: this.$tasks_locale.global.table_columns.name,
                },
                {
                    field: 'type',
                    label: this.$tasks_locale.global.table_columns.type,
                },
                {
                    field: 'installation_name',
                    label: this.$tasks_locale.global.table_columns.installation,
                },
                {
                    field: 'items_count',
                    label: 'P. Control/Dep./Acc.'/*this.$tasks_locale.global.table_columns.customer*/,

                },
                // {
                //     field: 'zone',
                //     label: 'Zona'/*this.$tasks_locale.global.table_columns.options*/
                // },
                {
                    field: 'format_date',
                    label: this.$tasks_locale.global.table_columns.start_date
                },
                {
                    field: 'format_expiration_date',
                    label: this.$tasks_locale.global.table_columns.expiration_date
                },
                {
                    field: 'link_cert',
                    label: this.$tasks_locale.global.table_columns.image_cert
                },
                {
                    field: 'state',
                    label: this.$tasks_locale.global.table_columns.state
                },
                {
                    field: 'options',
                    label: this.$tasks_locale.global.table_columns.options,
                    // display:'min_desktop'

                }
                // ,
                // {
                //     field: 'optionsResponsive',
                //     label: this.$tasks_locale.global.table_columns.options,
                //     display:'max_desktop'
                // }
                // {
                //     field: 'events',
                //     label: 'Eventos'/*this.$tasks_locale.global.table_columns.options*/
                // },
                // {
                //     field: 'actions',
                //     label: 'Intervenciones'/*this.$tasks_locale.global.table_columns.options*/
                // }
            ]
        }
    },
    computed: {},
    methods: {
        getTasks() {
            const filter = {task_status:'1'}
            const queryParams = this.$helpers.setUrlParameters(filter)
            axios.get(`/api-admin/tasks/getTasks/${queryParams}`).then(response => {
                const rows = response.data
                this.items = rows
            })
        },
        editItem(item) {
            window.location = `/tasks/${item.id}`
        },
        deleteItem(item) {
            this.$bus.$emit('fireModalConfirmDelete', {
                text: `${this.$tasks_locale.global.modals.delete_task} ${item.name} `,
                parameters: item
            })
        },
        removeItem(item) {
            axios.delete(`/api-admin/tasks/${item.id}`).then(response => {
                const rows = response.data
                this.items = rows
                this.$toast.success(this.$t('toast.delete_success'))
                this.getTasks()
            })
        },
        newItem() {
            window.location = '/tasks/create'
        },
        setItem(item){
            window.location = `/tasks/${item.id}/config`
        },
        goToCallendar(){
            window.open(`/tasks/calendar`,'_blank')

        }

    }

}
</script>
