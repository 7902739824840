<template>
    <div>
        <v-client-table :ref='idTable' :data='items' :columns='columns' :options='options'>
            <div slot='afterFilter' style="margin-left:10px;align-items: center;display: flex;margin-bottom: 0;">
                <label for="">{{ this.$tasks_locale.global.table_columns.state }}:</label>
                <task-filter :states="states" @setFilter="setFilter" :current="currentFilter" class='my-2'
                             :refresh_fn='refreshData'></task-filter>
            </div>
            <div slot='beforeTable'>
                <div class='text-right'>
                    <button v-if="this.$helpers.hasPermission('tasks.edit')" @click='newItem'
                            class='btn btn-primary btn-sm mb-2'><i class='fa fa-plus'></i>
                        {{ this.$tasks_locale.global.buttons.new }}
                    </button>
                </div>
            </div>
            <template v-for="(item) in customFields">
                <div v-html :slot="item.field" v-html="item.content"> {{ item.content }}</div>
            </template>
            <div slot='link_cert' slot-scope='{row}' class='text-center'>
                <div class="row">
                   <div class="col-2" v-for="image in row.images_cert">
                        <span   class=" m-2 cursor-pointer badge badge-primary"
                               @click="goShowImageCert(image.image,image.cert_blockchain_hash,image.created_at)">
                        <i class="fa fa-image h4 p-0 m-0"></i>
                    </span>
                   </div>
                </div>
            </div>
            <div slot='ac_icon' slot-scope='{row}' class='text-center' v-html='row.ac_icon'></div>
            <div slot='af_icon' slot-scope='{row}' class='text-center' v-html='row.af_icon'></div>
            <div slot='mix_icon' slot-scope='{row}' class='text-center' v-html='row.mix_icon'></div>
            <div slot='options' slot-scope='{row}'>
                <button v-if="$helpers.hasPermission('tasks.edit')" @click='editItem(row)'
                        class='btn btn-xs btn-warning'><i class='fa fa-pencil'></i></button>
                <button v-if="$helpers.hasPermission('tasks.delete')" @click='deleteItem(row)'
                        class='btn btn-xs btn-danger'><i class='fa fa-trash'></i></button>
                <button @click='downloadProcess(row)' :disabled="!row.process_file_id"
                        :class='"btn btn-secondary  btn-xs"'><i class='fa fa-download'></i></button>
                <button v-if='btnConfig' @click='config(row)' class='btn btn-xs btn-primary'><i class='fa fa-cog'></i>
                </button>
                <button :title='restore_label' v-if='row.disabled === true' @click='restore(row)'
                        class='btn btn-xs btn-info'>
                    <i class='fa fa-sync'></i></button>
            </div>
            <div slot="optionsResponsive" slot-scope='{row}'>
                <button v-if="$helpers.hasPermission('tasks.edit')" @click='editItem(row)'
                        class='btn btn-xs btn-warning'><i class='fa fa-pencil'></i></button>
                <button @click='deleteItem(row)' class='btn btn-xs btn-danger'><i class='fa fa-trash'></i></button>
                <button @click='showDetail(row)' class='btn btn-xs btn-primary px-2'>
                    <i class="fa text-white fa-info-circle"></i>
                </button>
            </div>
        </v-client-table>
        <transaction-info-modal v-if="transactionInfo!=null" :transactionInfo="transactionInfo"
                               :show="showModalTransaction"
                               @close="closeModal()"/>
    </div>
</template>

<script>
import TaskFilter from './TaskFilter'
import CheckpointTreeModal from "./CheckpointTreeModal.vue";
import TransactionInfoModal from "./TransactionInfoModal.vue";

export default {
    name: "TaskTable",
    props: {
        btnConfig: {default: false},
        columnsData: {required: true},
        idTable: {required: true},
        rowItems: {required: true},
        customFields: {required: false},
        enableCreateBtn: {default: true},
        states: {required: true},
        is_admin: {required: true},
        // optionsTable: {required: true},
        // zone_id: {required: true},
    },
    computed: {
        restore_label: function () {
            return this.$installations_locale.global.restore_checkpoint
        },
    },
    components: {
        TransactionInfoModal,
        CheckpointTreeModal,
        TaskFilter,
    },
    created() {
        this.customFields.forEach((v) => {
            console.log(v)
        })
        this.setColumns()
        this.items = [...this.rowItems]
    },
    watch: {
        rowItems: function (newItems) {
            this.items = [...newItems]
        },
    },
    data() {
        return {
            items: [],
            columns: [],
            currentFilter: null,
            showModalTransaction:false,
            transactionInfo:null,
            options: {
                rowClassCallback: row => {
                    return row.disabled ? 'disabled-item' : null
                },
                resizableColumns: true,
                perPage: 10,
                perPageValues: [5, 10, 50, 100],
                pagination: {chunk: 5},
                datepickerOptions: {
                    showDropdowns: true,
                    autoUpdateInput: true,
                },
                sortIcon: {
                    base: 'fa float-right',
                    is: 'fa-sort',
                    up: 'fa-sort-up',
                    down: 'fa-sort-down',
                },
                texts: {
                    count: `${this.$t('Showing {from} to {to} of {count} records|{count} records|One record')}`,
                    first: `${this.$t('First')}`,
                    last: `${this.$t('Last')}`,
                    filter: `${this.$t('Filter')}:`,
                    filterPlaceholder: `${this.$t('Search query')}`,
                    limit: `${this.$t('Records')}:`,
                    page: `${this.$t('Page')}:`,
                    noResults: `${this.$t('No matching records')}`,
                    filterBy: `${this.$t('Filter by {column}')}`,
                    loading: `${this.$t('Loading')}...`,
                    defaultOption: `${this.$t('Select {column}')}`,
                    columns: `${this.$t('Columns')}`,
                },
                columnsDisplay: {},
            },
        }
    },
    methods: {
        goShowImageCert(cert,hash,imageDateSaved) {
            const url = 'https://testardor.jelurida.com/nxt?requestType=getTransaction&chain=2&fullHash=' + hash
            axios.get(url).then(res => {
                console.log(res)
                this.transactionInfo = res
                this.transactionInfo.image = cert
                this.transactionInfo.date_image_cert = imageDateSaved
                this.showModalTransaction = true
            })

        },
        setColumns() {
            const headings = {}
            this.columnsData.map(item => {
                this.columns.push(item.field)
                headings[item.field] = item.label
                if (item.display) {
                    this.options.columnsDisplay[item.field] = item.display
                }
            })
            this.options.headings = headings
        },

        refreshData(items) {
            this.items = items
        },
        formatDate(date) {
            return [
                this.padTo2Digits(date.getDate()),
                this.padTo2Digits(date.getMonth() + 1),
                date.getFullYear(),
            ].join('/');
        },
        padTo2Digits(num) {
            return num.toString().padStart(2, '0');
        },
        editItem(item) {
            this.$emit('edit', item)
        },
        deleteItem(item) {
            this.$emit('delete', item)
        },
        newItem() {
            this.$emit('new')
        },
        config(item) {
            this.$emit('config', item)
        },

        setFilter(filter) {
            this.currentFilter = filter
            this.filterResults()
        },
        downloadProcess(task) {
            let aumentaWebUrl = this.$aumenta_locale.config.webapp_aumenta_url;
            let processFileURL = this.$aumenta_locale.config.webapp_aumenta_procedure_edit;
            window.open(aumentaWebUrl + processFileURL + task.process_file_id, '_blank')
        },
        downloadURI(uri, name) {
            var link = document.createElement("a");
            link.download = name;
            link.href = uri;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            link.remove();
        },
        filterResults() {
            const filter = this.currentFilter ? {task_status: this.currentFilter.key} : {}
            const queryParams = this.$helpers.setUrlParameters(filter)
            axios.get(`/api-admin/tasks/getTasks${queryParams}`).then(response => {
                const rows = response.data
                this.items = rows
                this.refreshData(rows)
            })
        },

        restore(item) {
            axios.get(`/api-admin/tasks/getTasks/restore`).then(response => {
                this.filterResults()

            })
        },
        closeModal(){
            this.showModalTransaction = false
        }
    },
}
</script>

<style lang='scss'>

tr.VueTables__row.disabled-item {
    background-color: #ffe5e5 !important;
}

.VueTables {
    label {
        margin-right: 10px;
    }
}

.VueTables__wrapper {
    max-height: 500px;
    overflow-y: scroll;
}

.VueTables__search-field {
    display: flex;
}

.VueTables__limit-field {
    display: flex;
}

.VueTables__columns-dropdown-wrapper {
    margin-right: 10px;
}

.VueTables__row {
    td {
        padding: 3px 5px;
        vertical-align: middle;
    }
}

.VueTables__columns-dropdown {
    input {
        margin-right: 3px;
    }
}
</style>
