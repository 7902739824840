<template>
    <div>
        <notifications-general :notification_id="notification_id"></notifications-general>
    </div>
</template>
<script>

import NotificationsGeneral from "../NotificationsGeneral.vue";

export default {
    name: 'NotificationsUserTable',
    components: {NotificationsGeneral},
    props: {
        notification_id: {default: null, required: false}
    },

    computed: {},
    created() {
        this.$store.commit('notifications/setManagement',false)
    },
    data() {
        return {}
    },
    methods: {

    }
}

</script>
