<template>
    <card-template>
        <div class="row">
            <div class="col-sm-4">
                <img class="img-fluid avatar mb-3" :src="image_path">
                <div class="mb-3">
                    <div class="custom-file">
                        <input type="file" @change="setFile" class="custom-file-input" id="file">
                        <label class="custom-file-label" for="file">{{ fileName }}</label>
                    </div>
                </div>
            </div>
            <div class="col-sm-8">
                <div class="mb-3">
                    <label for="name" class="required">{{ this.$installations_locale.global.name }}</label>
                    <input type="text" v-model="formData.name" id="name" class="form-control">
                </div>
                <div class="mb-3">
                    <CustomersCombo :label="this.$installations_locale.global.customer " :current="customer" @add="setCustomer"/>
                </div>
            </div>
        </div>

        <hr>

        <button @click="save" class="btn btn-primary">{{ this.$installations_locale.global.buttons.save }}</button>
        <a v-show="installation_id" @click="setItem"  class="btn btn-dark">
            <i class="fa fa-cog"></i>
            {{ this.$installations_locale.global.config }}
        </a>



    </card-template>

</template>
<script>
import CustomersCombo from '../../../../Customers/Resources/assets/components/CustomerCombo.vue'
export default {
    name: 'InstallationForm',
    props: ['installation_id'],
    components: {
        CustomersCombo,
    },
    created() {
        if (this.installation_id) {
            this.getItem()
        }
    },
    data() {
        return {
						installation_namespace: 'Modules\\Installations\\Entities\\Task',
            fileName: this.$installations_locale.global.choose_file,
            image_path: '/images/no-image.jpg',
            formData: {
                id: null,
                name: '',
                customer_id: null,
                image: '',
            },
            customer: null
        }
    },
    methods: {
        setItem(item){
            this.$store.commit('installations/setCurrent',this.formData);
            window.location = `/installations/${this.formData.id}/config`
        },
        getItem() {
            axios.get(`/api-admin/installations/${this.installation_id}`).then(response => {
                const item = response
                this.formData.name = item.name
                this.formData.id = item.id
                this.image_path = item.avatar
                if(item.customer){
                    this.customer = item.customer
                    this.formData.customer_id = item.customer.id
                }
            })
        },
        setFile(e) {
            const file = e.target.files[0]
            this.fileName = file.name
            this.formData.image = file
        },

        setCustomer(customer) {
            this.formData.customer_id = customer.id ? customer.id : null
        },
        save() {
            let formData = new FormData()
            Object.keys(this.formData).map(field => {
                formData.append(field, this.formData[field]===null ? '' : this.formData[field])
            })
            axios.post('/api-admin/installations', formData, {headers: {'content-type': 'multipart/form-data'}}).then(response => {
                this.$helpers.showLoading()
                this.$helpers.toastSuccess()
                setTimeout(() => {
                    window.location = `/installations/${response.id}`
                }, 1000)
            })
        }
    }
}
</script>
<style lang="css" scoped>
.custom-file-input:lang(en) ~ .custom-file-label:after {
    content: "\f093"; /* inspect an icon for this value */
    font-family: FontAwesome; /* intentionally no fallback */
    font-size: 16px;
}

.avatar {
    border-radius: 10px;
    border: solid 1px #ccc;
    padding: 10px;
}
</style>
