<template>
	<card-template padding='p-2' :show_header='false'>
		<div class='table-responsive'>
			<checkpoints-table
				:enableCreateBtn='true'
				:idTable='idTable'
				:columnsData='columns'
				:rowItems='items'
				:customFields='customFields'
				:installation_id='installation_id'
				:zone_id='zone_id'
				@edit='editItem'
				@delete='deleteItem'
				@new='newItem'
			>
			</checkpoints-table>
		</div>
		<modal-confirm-delete-fn />
		<checkpoints-modal :refresh_fn='refreshItems' :zone_id='zone_id' :installation_id='installation_id' :id='modalId' :current='current'></checkpoints-modal>
	</card-template>
</template>
<script>
import {mapGetters} from 'vuex'
import CheckpointsTable from './CheckpointsTable'
import CheckpointsModal from './CheckpointsModal'

export default {
	name: 'CheckpointsList',
	props: {
		'zone_id': {},
		'installation_id': {},
	},

	components: {
		CheckpointsTable,
		CheckpointsModal,
	},
	created() {
		this.getItems()
		// this.$store.commit('checkpoints/removeCurrent')
	},
	data() {
		return {
			idTable: 'checkpointsTable',
			items: [],
			modalId: 'checkpointForm',
			current: null,
			customFields: [
				// {field: 'name', content: 'pepito'}
			],

			columns: [
				{
					field: 'type_label',
					label: this.$installations_locale.global.type,
				},
				{
					field: 'id',
					label: '#',
					display: 'min_tabletP',
				},
				{
					field: 'client_code',
					label: this.$installations_locale.global.client_code,
					display: 'min_tabletP',
				},
				{
					field: 'ac_icon',
					label: 'AC',
				},
				{
					field: 'af_icon',
					label: 'AF',
				},
				{
					field: 'mix_icon',
					label: 'MIX',
				},

				{
					field: 'cat_label',
					label: this.$installations_locale.global.category,
					display: 'min_tabletP',
				},
				{
					field: 'comments',
					label: this.$installations_locale.global.comments,
					display: 'min_tabletP',
				},

				{
					field: 'options',
					label: this.$installations_locale.global.options,
				},
			],
		}
	},
	computed: {
		...mapGetters('checkpoints',['newCheckpointUrl','editCheckpointUrl'])
	},
	methods: {
		getItems() {
			const url = `/api-admin/installations/${this.installation_id}/checkpoints/zone/${this.zone_id}`
			axios.get(url).then(response => {
				const rows = response.data
				this.refreshItems(rows)
			})
		},
		refreshItems(items) {
			this.items = items
		},
		editItem(item) {
			// this.current = item
			// $(`#${this.modalId}`).modal('show')
			this.$store.commit('checkpoints/setCurrent',item)
			window.location.href = this.editCheckpointUrl

		},
		deleteItem(item) {
			this.$bus.$emit('fireModalConfirmDeleteFn', {
				text: `${this.$installations_locale.global.modals.delete_checkpoint} `,
				parameters: item,
				fn: this.removeItem
			})
		},
		removeItem(item) {
			axios.delete(`/api-admin/installations/${this.installation_id}/checkpoints/${item.id}/zone/${this.zone_id}`).then(response => {
				const rows = response.data
				this.refreshItems(rows)
				this.$toast.success(this.$t('toast.delete_success'))
			})
		},
		newItem() {
			// this.current = null
			// $(`#${this.modalId}`).modal('show')
			window.location.href = this.newCheckpointUrl
		},
	},

}
</script>
