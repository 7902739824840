<template>
    <div>
        <v-client-table :ref='idTable' :data='items' :columns='columns' :options='options'>
<!--            <span slot="ac_temperature" slot-scope="props" :class="props.row.ac_temperature_alarm==1?'text-danger':''">{{-->
<!--                    props.row.ac_temperature-->
<!--                }}-->
<!--            </span>-->
            <span slot="purge" slot-scope="props" >{{
                    props.row.purge==1?'Purgado':''
                }}
            </span>

            <div slot='reading_value'  slot-scope='{row}'>
                <span>{{getAlarmReadingValue(alarmKey,row[alarmKey])}}</span>
            </div>

            <div slot='afterFilter' style="margin-left:10px;align-items: center;display: flex;margin-bottom: 0;">
                <label v-if="this.customFilters.includes('checkpoints')"
                       for="">{{ this.$readings_locale.global.table_columns.checkpoint }}:</label>
                <reading-filter-checkpoint v-if="this.customFilters.includes('checkpoints')" @setFilter="setFilter"
                                           :current="currentFilter" class='my-2'
                                           :refresh_fn='refreshData'></reading-filter-checkpoint>
            </div>
            <div slot='afterFilter' style="margin-left:10px;align-items: center;display: flex;margin-bottom: 0;">
                <label v-if="this.customFilters.includes('date_range')"
                       for="">{{ this.$readings_locale.global.table_columns.reading_date }}:</label>
                <reading-filter-date-range v-if="this.customFilters.includes('date_range')" @setFilter="setFilter"
                                           :current="currentFilter" class='my-2'
                                           :refresh_fn='refreshData'></reading-filter-date-range>
            </div>

            <template v-for="(item) in customFields">
                <div v-html :slot="item.field" v-html="item.content"> {{ item.content }}</div>
            </template>
            <div slot='options' slot-scope='{row}' class="text-center">
                <button @click='showDetail(row)' class='btn btn-xs btn-light px-2'>
                    <i  :class="(row.hasAlarm?'text-danger fa-warning':'text-primary fa-info-circle') + ' fa mr-1'"></i>Detalle
                </button>

            </div>
        </v-client-table>
    </div>
</template>
<script>
import ReadingFilter from './ReadingFilterCheckpoint'
import ReadingFilterCheckpoint from "./ReadingFilterCheckpoint";
import 'animate.css';

export default {
    name: "ReadingTable",
    props: {
        btnConfig: {default: false},
        customFilters: {required: false},
        columnsData: {required: true},
        idTable: {required: true},
        rowItems: {required: true},
        customFields: {required: false},
        enableCreateBtn: {default: true},
        type: {required: true},
        readingsId: {required: false},
        alarmData: {required: false},
        // optionsTable: {required: true},
        // zone_id: {required: true},
    },
    computed: {},
    components: {
        ReadingFilterCheckpoint,
        ReadingFilter,
    },
    created() {

        this.setColumns()
        this.items = [...this.rowItems]
    },
    watch: {
        rowItems: function (newItems) {
            this.items = [...newItems]
        },
    },
    data() {
        return {
            items: [],
            columns: [],
            booleanColumns:this.$readings_locale.config.boolean_keys,

            currentFilter: [],
            alarmKey:this.alarmData!=null?this.alarmData.reading_key.key:null,
            options: {
                rowClassCallback: row => {
                    return row.disabled ? 'disabled-item' : null
                },
                resizableColumns: true,
                perPage: 10,
                perPageValues: [5, 10, 50, 100],
                pagination: {chunk: 5},
                datepickerOptions: {
                    showDropdowns: true,
                    autoUpdateInput: true,
                },
                sortIcon: {
                    base: 'fa float-right',
                    is: 'fa-sort',
                    up: 'fa-sort-up',
                    down: 'fa-sort-down',
                },
                texts: {
                    count: `${this.$t('Showing {from} to {to} of {count} records|{count} records|One record')}`,
                    first: `${this.$t('First')}`,
                    last: `${this.$t('Last')}`,
                    filter: `${this.$t('Filter')}:`,
                    filterPlaceholder: `${this.$t('Search query')}`,
                    limit: `${this.$t('Records')}:`,
                    page: `${this.$t('Page')}:`,
                    noResults: `${this.$t('No matching records')}`,
                    filterBy: `${this.$t('Filter by {column}')}`,
                    loading: `${this.$t('Loading')}...`,
                    defaultOption: `${this.$t('Select {column}')}`,
                    columns: `${this.$t('Columns')}`,
                },
                columnsDisplay: {},
            },

        }
    },
    methods: {

        formatDate(date) {
            return [
                this.padTo2Digits(date.getDate()),
                this.padTo2Digits(date.getMonth() + 1),
                date.getFullYear(),
            ].join('/');
        },
        padTo2Digits(num) {
            return num.toString().padStart(2, '0');
        },
        setColumns() {
            const headings = {}
            this.columnsData.map(item => {
                this.columns.push(item.field)
                headings[item.field] = item.label
                if (item.display) {
                    this.options.columnsDisplay[item.field] = item.display
                }
            })
            this.options.headings = headings
        },
        refreshData(items) {
            this.items = items
        },

        editItem(item) {
            this.$emit('edit', item)
        },
        deleteItem(item) {
            this.$emit('delete', item)
        },
        newItem() {
            this.$emit('new')
        },
        config(item) {
            this.$emit('config', item)
        },

        setFilter(filter) {
            let filterKey = Object.keys(filter)[0];
            this.currentFilter[filterKey] = filter[filterKey]
            // this.currentFilter = filter
            this.filterResults()
        },

        filterResults() {
            let filtersKey = Object.keys(this.currentFilter);
            let queryParams = '?';
            filtersKey.forEach((value, key) => {
                queryParams += `${value}=${this.currentFilter[value]}&`
            })
            queryParams+=`readings_id=${JSON.stringify(this.readingsId)}`
            //const filter = this.currentFilter ? {checkpoint_filter:this.currentFilter.key} : {}
            // queryParams = this.$helpers.setUrlParameters(filter)
            axios.get(`/api-admin/readings/getReadings/${this.type}/${queryParams}`).then(response => {
                const rows = response.data
                this.items = rows
                this.refreshData(rows)
            })
        },
        restore(item) {
            let queryParams=`?readings_id=${JSON.stringify(this.readingsId)}`
            axios.get(`/api-admin/readings/getReadings/${this.type}/restore${queryParams}`).then(response => {
                this.filterResults()
            })
        },
        showDetail(data) {
            let text = `
                <div class="w-100 text-left">
                    <small>${this.$readings_locale.global.table_columns.reading}: #${data.id}</small>
                </div>
                <div class="w-100 text-left">
                    <small>${this.$readings_locale.global.table_columns.reading_date}: ${data.reading_date}</small>
                </div>
                <div class="w-100 text-left">
                    <small >${this.$readings_locale.global.table_columns.operator}: ${data.user.name}</small>
                </div>

                <table class="mt-1 table-reading-detail table table-bordered table-striped" >
                    <tbody class="text-left">
                        <tr class="${!data.reading_key.includes('ac_temperature')?'d-none':''}">
                            <td class="${data.alarms.includes('ac_temperature')?'text-danger':''}">${this.$readings_locale.global.table_columns.temperature_ac}</td>
                            <td class="${data.alarms.includes('ac_temperature')?'text-danger':''}"}>${data.ac_temperature!=null?data.ac_temperature:'X'}</td>
                        </tr>
                          <tr class="${!data.reading_key.includes('af_temperature')?'d-none':''}">
                            <td class="${data.alarms.includes('af_temperature')?'text-danger':''}">${this.$readings_locale.global.table_columns.temperature_af}</td>
                            <td class="${data.alarms.includes('af_temperature')?'text-danger':''}">${data.af_temperature!=null?data.af_temperature:'X'}</td>
                        </tr>
                        <tr class="${!data.reading_key.includes('chlorine')?'d-none':''}">
                            <td class="${data.alarms.includes('chlorine')?'text-danger':''}">${this.$readings_locale.global.table_columns.chlorine_control}</td>
                            <td class="${data.alarms.includes('chlorine')?'text-danger':''}">${data.chlorine!=null?data.chlorine:'X'}</td>
                        </tr>
                        <tr class="${!data.reading_key.includes('cleaning')?'d-none':''}">
                            <td class="${data.alarms.includes('cleaning')?'text-danger':''}">${this.$readings_locale.global.table_columns.cleaning}</td>
                            <td class="${data.alarms.includes('cleaning')?'text-danger':''}">${data.cleaning == 1 ? 'Si' : 'No'}</td>
                        </tr>
                        <tr class="${!data.reading_key.includes('cleaning')?'d-none':''}">
                            <td class="${data.alarms.includes('disinfection')?'text-danger':''}">${this.$readings_locale.global.table_columns.disinfection}</td>
                            <td class="${data.alarms.includes('disinfection')?'text-danger':''}">${data.disinfection == 1 ? 'Si' : 'No'}</td>
                        </tr>
                        <tr class="${!data.reading_key.includes('cleaning')?'d-none':''}">
                            <td class="${data.alarms.includes('cleaning_review')?'text-danger':''}">${this.$readings_locale.global.table_columns.cleaning_review}</td>
                            <td class="${data.alarms.includes('cleaning_review')?'text-danger':''}">${data.cleaning_review == 1 ? 'Correcto' : 'Incorrecto'}</td>
                        </tr>
                        <tr class="${!data.reading_key.includes('purge')?'d-none':''}">
                            <td class="${data.alarms.includes('purge')?'text-danger':''}">${this.$readings_locale.global.table_columns.purge}</td>
                            <td class="${data.alarms.includes('purge')?'text-danger':''}">${data.purge == 1 ? 'Si' : 'No'}</td>
                        </tr>
                        <tr class="${!data.reading_key.includes('ph')?'d-none':''}">
                            <td class="${data.alarms.includes('ph')?'text-danger':''}">${this.$readings_locale.global.table_columns.ph}</td>
                            <td class="${data.alarms.includes('ph')?'text-danger':''}">${data.ph!=null?data.ph:'X'}</td>
                        </tr>
                        <tr class="${!data.reading_key.includes('hardness')?'d-none':''}">
                            <td class="${data.alarms.includes('hardness')?'text-danger':''}">${this.$readings_locale.global.table_columns.hardness}</td>
                            <td class="${data.alarms.includes('hardness')?'text-danger':''}">${data.hardness!=null?data.hardness:'X'}</td>
                        </tr>

                        <tr class="${!data.reading_key.includes('legionella_analysis')?'d-none':''}">
                            <td class="${data.alarms.includes('legionella_analysis')?'text-danger':''}">${this.$readings_locale.global.table_columns.legionella_analysis}</td>
                            <td class="${data.alarms.includes('legionella_analysis')?'text-danger':''}">${data.legionella_analysis == 0 ? 'Correcto' : data.legionella_analysis < 100 ? 'Aceptable' : 'Incorrecto'}</td>
                        </tr>
                        <tr class="${!data.reading_key.includes('control_time_temperature')?'d-none':''}">
                            <td class="${data.alarms.includes('control_time_temperature')?'text-danger':''}">${this.$readings_locale.global.table_columns.control_time_temperature}</td>
                            <td class="${data.alarms.includes('control_time_temperature')?'text-danger':''}">${data.control_time_temperature!=null?data.control_time_temperature:'X'}</td>
                        </tr>
                        <tr class="${!data.reading_key.includes('chlorine_dioxide')?'d-none':''}">
                            <td class="${data.alarms.includes('chlorine_dioxide')?'text-danger':''}">${this.$readings_locale.global.table_columns.chlorine_dioxide}</td>
                            <td class="${data.alarms.includes('chlorine_dioxide')?'text-danger':''}">${data.chlorine_dioxide!=null?data.chlorine_dioxide:'X'}</td>
                        </tr>
                        <tr>
                            <td>${this.$readings_locale.global.table_columns.comments}</td>
                            <td><small>${data.comments!=null?data.comments:''}</small></td>
                        </tr>
                    </tbody>
                </table>
            `;
            this.$swal.fire({
                title: this.$readings_locale.global.modals.reading_detail,
                html: text,
                showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                },
                customClass:'modal-detail-reading',
                // icon: 'info',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonText: this.$readings_locale.global.buttons.close
            })
        },
        getAlarmReadingValue(key,value){

            if (this.booleanColumns.includes(key)){
                    return value==1?'Si':'No'
            }
            return value;
        }
    },
}
</script>

<style lang='scss'>

tr.VueTables__row.disabled-item {
    background-color: #ffe5e5 !important;
}

.VueTables {
    label {
        margin-right: 10px;
    }
}

.VueTables__wrapper {
    max-height: 500px;
    overflow-y: scroll;
}

.VueTables__search-field {
    display: flex;
}

.VueTables__limit-field {
    display: flex;
}

.VueTables__columns-dropdown-wrapper {
    margin-right: 10px;
}

.VueTables__row {
    td {
        padding: 3px 5px;
        vertical-align: middle;
    }
}

.VueTables__columns-dropdown {
    input {
        margin-right: 3px;
    }
}

.VueTables__limit-field {
    select {
        margin: 5% 0;
    }

}
.table-reading-detail{
    font-size: 13px;
  tr{
      text-align: left;
  }
    td{
        text-align: center;
        padding: 2px 0;
        color:black;
        vertical-align: middle;
    }
}
.modal-detail-reading{
    color:black;
    font-size: 13px;

}
</style>
