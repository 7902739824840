Vue.component('installations-list', require('./InstallationsList.vue').default)
Vue.component('installations-form', require('./InstallationForm.vue').default)
Vue.component(
	'installation-config',
	require('./config/InstallationConfig.vue').default
)

Vue.component(
	'accumulator-form',
	require('./config/circuits/accumulators/AccumulatorForm.vue').default
)

Vue.component(
	'deposit-form',
	require('./config/circuits/deposits/DepositForm.vue').default
)

Vue.component(
	'installation-zones',
	require('./zones/ZoneModule.vue').default
)

Vue.component(
	'zone-form',
	require('./zones/ZoneForm.vue').default
)

Vue.component('checkpoints',require('./checkpoints/CheckpointsList.vue').default)

Vue.component(
	'checkpoint-form',
	require('./checkpoints/CheckpointForm.vue').default
)
