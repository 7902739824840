export const getCurrentZone = (state) => {
	return state.current
}

export const listZoneUrl = (state, getters, rootState, rootGetters) => {
	const installation = rootGetters['installations/getCurrent']
	return  installation ? state.list_url.replace(':installation_id',installation.id) : null
}

export const newZoneUrl = (state, getters, rootState, rootGetters) => {
	const installation = rootGetters['installations/getCurrent']
	return  installation ? state.create_url.replace(':installation_id',installation.id) : null
}

export const editZoneUrl = (state, getters, rootState, rootGetters) => {
	const installation = rootGetters['installations/getCurrent']
	const zone = state.current
	let url = installation ?  state.edit_url.replace(':installation_id',installation.id) : null
	return url && zone ? url.replace(':zone_id',zone.id) : null
}
