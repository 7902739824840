<template>
    <card-template>
        <template slot="titleCard">{{ this.$t_notifications('users_management.title_form') }}</template>
        <div id="formUserManagement" class="w-100">
            <div class="row">
                <div class="col-12 mb-3">
                    <label for="phone">{{ this.$t_notifications('users_management.columns.enabled_user') }}</label>
                    <VueToggles :width="50" :value="formData.enabled"
                                @click="toggleEnable()"></VueToggles>
                </div>

                <div class="col-12 col-md-3 mb-3">
                    <label for="name">{{ this.$t_notifications('users_management.select_user') }}</label>
                    <v-select @input="addUser" v-model="formData.user" :multiple="false" :options="getUsersSelector"
                              label="name" code="id"></v-select>
                    <small><i
                        class="fa fa-info-circle mr-2"></i>{{ this.$t_notifications('users_management.set_user_name') }}</small>
                </div>
                <div class="col-12 col-md-3 mb-3">
                    <label for="name" class="required">{{
                            this.$t_notifications('users_management.columns.name')
                        }}</label>
                    <input type="text" v-model="formData.name" id="name" class="form-control">

                </div>
                <div class="col-12 col-md-3 mb-3">
                    <label for="email"
                           class="required">{{ this.$t_notifications('users_management.columns.email') }}</label>
                    <input type="email" v-model="formData.email" id="email" class="form-control">
                </div>
                <div class="col-12 col-md-3 mb-3">
                    <label for="phone"
                           class="required">{{ this.$t_notifications('users_management.columns.phone') }}</label>
                    <!--                    <input type="text" v-model="formData.phone" id="phone" class="form-control">-->
                    <vue-tel-input
                        :inputOptions="inputTelOptions"
                        mode="international"
                        :validCharactersOnly="true"
                        v-model="formData.phone"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-3 mb-3">
                    <channels-selector :current="formData.channels" :multiple="true"
                                       @add="addChannels"></channels-selector>
                </div>

            </div>
        </div>
        <hr>

        <button @click="save" class="btn btn-primary">{{ $t_notifications('buttons.save') }}</button>
        <button @click="cancel" class="btn btn-danger">{{ $t_notifications('buttons.cancel') }}</button>
    </card-template>
</template>

<script>
import vSelect from 'vue-select'
import "vue-select/dist/vue-select.css"
import ChannelsSelector from '../ChannelsSelector.vue'
import VueToggles from 'vue-toggles/dist/vue-toggles.ssr';
import 'vue-toggles/dist/vue-toggles.ssr.css';
import {mapGetters} from "vuex";
import {VueTelInput} from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';


export default ({
    name: "UserManagementForm",
    components: {
        vSelect,
        VueToggles,
        ChannelsSelector,
        VueTelInput
    },
    props: {
        user_id: {default: null, required: false}
    },
    computed: {
        ...mapGetters('notifications', ['getUsersSelector', 'getUserNotificationData']),
        inputTelOptions: function () {
            return {
                placeholder: this.$t_notifications('users_management.phone_number')
            }
        }
    },
    data() {
        return {
            formData: {
                id: null,
                user: null,
                user_id: null,
                name: null,
                email: null,
                phone: null,
                enabled: true,
                channels: null
            },
        }
    },

    created() {
        this.getUsers()
        if (this.user_id) {
            this.getUserConfig()
        }
    },
    methods: {
        async getUserConfig() {
            await this.$store.dispatch('notifications/getUserConfig', this.user_id).then(resp => {
                this.formData = this.getUserNotificationData
            })
        },
        save() {
            this.$store.dispatch('notifications/save', this.formData)
        },
        addUser(user) {
            this.formData.user_id = user ? user.id : null;
            user && !this.user_id? this.formData.name = user.name : null
            user && !this.user_id? this.formData.email = user.email : null

        },
        addChannels(channels) {
            this.formData.channels = channels
        },
        toggleEnable() {
            this.formData.enabled = !this.formData.enabled
        },
        getUsers() {
            this.$store.dispatch('notifications/getUsersSelector')
        },
        cancel() {
            window.location = `/notifications/users/`

        }
    }
})
</script>
