<template>
    <div>
        <label>{{ label ? label : this.$installations_locale.global.combo_label }}</label>

        <v-select  autocomplete="off"
				   @option:selected="add"
				   @option:deselected="add"
				   v-model="selected"
				   :multiple="multiple"
				   :options="items"
				   label="name" code="id"></v-select>
    </div>
</template>
<script>
import vSelect from 'vue-select'
import "vue-select/dist/vue-select.css"

export default {
    name: 'InstallationsCombo',
    props: {
        'current': {},
        'multiple': {default: false},
        label: {default: null}
    },
    components: {
        vSelect
    },
    created() {
        this.setData()
        if(this.multiple){
            this.selected = this.current ? [...this.current] : null
        }else{
            this.selected = this.current ? {...this.current} : null
        }

    },
    watch: {
        current: function () {
            if(this.multiple){
                this.selected = this.current ? [...this.current] : null
            }else{
                this.selected = this.current ? {...this.current} : null
            }

        },
        selected: function(){
          if(this.selected === null) this.add()
        }
    },
    data() {
        return {
            selected: null,
            items: []
        }
    },
    methods: {
        setData() {
            axios.get('/api-admin/installations/combo').then(response => {
                this.items = response
            })
        },
        add() {
            this.$emit('add', this.multiple ? [...this.selected] : {...this.selected})
        }
    }
}
</script>
