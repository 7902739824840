<template>
    <h6 class="m-0 font-weight-bold text-primary mb-3">
		<small class="font-bold font-italic">#{{zone.item.id}}</small>
<!--        <small class="btn btn-xs mr-1 btn-dark">{{zone.item.code}}</small> - -->
		{{zone.item.name}}

        <button @click="deleteItem(zone.item)" class="ml-1 btn btn-danger btn-xs float-right">
            <i class="fa fa-trash"></i></button>
        <button @click="editZone(zone.item)" class="ml-1 btn btn-warning btn-xs float-right">
            <i class="fa fa-pencil"></i></button>

        <button v-if="!zone.item.wet_zone" @click="newZone" class="ml-1 btn btn-success btn-xs float-right">
            <i class="fa fa-plus"></i></button>

		<button v-else @click='editCheckpoints(zone.item)' class='btn btn-secondary btn-xs float-right ml-1'>
			<i class="fa fa-tachometer"></i>
		</button>

        <br>
        <small class="mt-2 d-block text-gray-600">
            <span class="btn btn-xs btn-info">{{zone.item.type.name}}</span> <i v-for="(path) in zone.path.split('/')" class="btn btn-xs btn-secondary mr-1">{{ path }}</i>
        </small>
        <small class="mt-2 d-block text-gray-600">
            <span class="btn btn-xs btn-info">{{this.$installations_locale.global.circuits}}</span> <i :key="circuit.id" v-for="(circuit) in zone.item.circuits" class="btn btn-xs btn-secondary mr-1">{{ circuit.code }}</i>
        </small>
    </h6>
</template>
<script>
import {mapGetters} from 'vuex'

export default{
    name: 'ZoneItem',
    props:{
        installation_id:{required:true},
        zone:{required:true},
        edit_fn: {required: false, type: Function},
        refresh_fn: {required: true, type: Function},
    },
	computed:{
		...mapGetters('zones',['newZoneUrl','editZoneUrl']),
		...mapGetters('checkpoints',['listCheckpointUrl'])
	},
    methods:{
        deleteItem(item){
            this.$bus.$emit('fireModalConfirmDeleteFn', {
                text: `${this.$installations_locale.global.modals.delete_zone} ${item.name} `,
                parameters: item,
                fn: this.removeItem
            })
        },
        removeItem(item){
            const url = `/api-admin/installations/${this.installation_id}/zones/${item.id}`
            axios.delete(url).then(response=>{
                this.$toast.success(this.$t('toast.delete_success'))
                this.refresh_fn(response)
            })
        },
		newZone(e){
			e.preventDefault()
			window.location.href = this.newZoneUrl + '?parent=' + this.zone.item.id
		},
		editZone(item){
			this.$store.commit('zones/setCurrent',item);
			window.location.href = this.editZoneUrl
		},
		editCheckpoints(zone){
			this.$store.commit('zones/setCurrent',zone);
			window.location.href = this.listCheckpointUrl
		}
    }
}
</script>
