<template>
    <div>
        <v-client-table :ref='idTable' :data='items' :columns='columns' :options='options'>
            <div slot='afterFilter'>
                <checkpoint-filter @setFilter="setFilter" :current="currentFilter" class='mx-2 my-2'
                                   :installation_id='installation_id' :zone_id='zone_id'
                                   :refresh_fn='refreshData'></checkpoint-filter>
            </div>
            <div slot='beforeTable'>
                <div class='text-right'>
                    <button @click='newItem' class='btn btn-primary btn-sm mb-2'><i class='fa fa-plus'></i>
                        {{ this.$t('buttons.new') }}
                    </button>
                </div>
            </div>

            <div slot='ac_icon' slot-scope='{row}' class='text-center' v-html='row.ac_icon'></div>
            <div slot='af_icon' slot-scope='{row}' class='text-center' v-html='row.af_icon'></div>
            <div slot='mix_icon' slot-scope='{row}' class='text-center' v-html='row.mix_icon'></div>

            <div slot='options' slot-scope='{row}'>
                <button @click='editItem(row)' class='btn btn-xs btn-warning'><i class='fa fa-pencil'></i></button>
                <button @click='deleteItem(row)' class='btn btn-xs btn-danger'><i class='fa fa-trash'></i></button>
                <button v-if='btnConfig' @click='config(row)' class='btn btn-xs btn-primary'><i class='fa fa-cog'></i>
                </button>
                <button :title='restore_label' v-if='row.disabled === true' @click='restore(row)'
                        class='btn btn-xs btn-info'>
                    <i class='fa fa-sync'></i></button>
                <button @click='showQr(row.client_code)' class='btn btn-xs btn-dark'>
                    <i class='fa fa-qrcode'></i></button>
            </div>

        </v-client-table>
        <modal-qr :qr="currentQR"></modal-qr>
    </div>
</template>
<script>
import CheckpointFilter from './CheckpointsFilter'
import ModalQr from "../ModalQR.vue";

export default {
    name: 'CheckpointsTable',
    props: {
        btnConfig: {default: false},
        columnsData: {required: true},
        idTable: {required: true},
        rowItems: {required: true},
        customFields: {required: false},
        enableCreateBtn: {default: true},
        installation_id: {required: true},
        zone_id: {required: true},
    },
    computed: {
        restore_label: function () {
            return this.$installations_locale.global.restore_checkpoint
        },
    },
    components: {
        ModalQr,
        CheckpointFilter,
    },
    created() {
        this.setColumns()
        this.items = [...this.rowItems]
    },
    watch: {
        rowItems: function (newItems) {
            this.items = [...newItems]
        },
    },
    data() {
        return {
            items: [],
            columns: [],
            currentFilter: null,
            currentQR: null,
            options: {
                rowClassCallback: row => {
                    return row.disabled ? 'disabled-item' : null
                },
                resizableColumns: true,
                perPage: 10,
                perPageValues: [5, 10, 50, 100],
                pagination: {chunk: 5},
                datepickerOptions: {
                    showDropdowns: true,
                    autoUpdateInput: true,
                },
                sortIcon: {
                    base: 'fa float-right',
                    is: 'fa-sort',
                    up: 'fa-sort-up',
                    down: 'fa-sort-down',
                },
                texts: {
                    count: `${this.$t('Showing {from} to {to} of {count} records|{count} records|One record')}`,
                    first: `${this.$t('First')}`,
                    last: `${this.$t('Last')}`,
                    filter: `${this.$t('Filter')}:`,
                    filterPlaceholder: `${this.$t('Search query')}`,
                    limit: `${this.$t('Records')}:`,
                    page: `${this.$t('Page')}:`,
                    noResults: `${this.$t('No matching records')}`,
                    filterBy: `${this.$t('Filter by {column}')}`,
                    loading: `${this.$t('Loading')}...`,
                    defaultOption: `${this.$t('Select {column}')}`,
                    columns: `${this.$t('Columns')}`,
                },
                columnsDisplay: {},
                // columnsDropdown: true,
                // columnsDisplay: {
                //     email: 'min_tabletP',
                //     id: 'min_tabletP'
                // },
                // selectable:{
                //     mode: 'multiple', // or 'multiple'
                //     only: function(row) {
                //         return true // any condition
                //     },
                //     selectAllMode: 'all', // or 'page',
                //     programmatic: false
                // }
            },
        }
    },
    methods: {
        setColumns() {
            const headings = {}
            this.columnsData.map(item => {
                this.columns.push(item.field)
                headings[item.field] = item.label
                if (item.display) {
                    this.options.columnsDisplay[item.field] = item.display
                }
            })
            this.options.headings = headings

        },

        refreshData(items) {
            this.items = items
        },

        editItem(item) {
            this.$emit('edit', item)
        },
        deleteItem(item) {
            this.$emit('delete', item)
        },
        newItem() {
            this.$emit('new')
        },
        config(item) {
            this.$emit('config', item)
        },

        setFilter(filter) {
            this.currentFilter = filter
            this.filterResults()
        },

        filterResults() {
            const filter = this.currentFilter ? {checkpoint_status: this.currentFilter.key} : {}
            const queryParams = this.$helpers.setUrlParameters(filter)
            const url = `/api-admin/installations/${this.installation_id}/checkpoints/zone/${this.zone_id}${queryParams}`
            axios.get(url).then(response => {
                const rows = response.data
                this.refreshData(rows)
            })
        },

        restore(item) {
            const url = `/api-admin/installations/${this.installation_id}/checkpoints/${item.id}/zone/${this.zone_id}/restore`
            axios.post(url).then(response => {
                this.filterResults()
            })
        },
        showQr(qr) {
            this.currentQR = 'cpp' + qr
            console.log($(`#modalQr`))
            $(`#modalQr`).modal('show')
        }
    },

}
</script>
<style lang='scss'>

tr.VueTables__row.disabled-item {
    background-color: #ffe5e5 !important;
}

.VueTables {
    label {
        margin-right: 10px;
    }
}

.VueTables__wrapper {
    max-height: 500px;
    overflow-y: scroll;
}

.VueTables__search-field {
    display: flex;
}

.VueTables__limit-field {
    display: flex;
}

.VueTables__columns-dropdown-wrapper {
    margin-right: 10px;
}

.VueTables__row {
    td {
        padding: 3px 5px;
        vertical-align: middle;
    }
}

.VueTables__columns-dropdown {
    input {
        margin-right: 3px;
    }
}
</style>
