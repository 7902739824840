<template>
	<div>
		<div class='modal-body'>
			<div v-if='parent_zone' class='alert alert-info mb-2'>
				<i class='fa'></i>
				{{ `${this.$installations_locale.global.modals.parent_zone_info} ${parent_zone.item.name}` }}
			</div>

			<div class='form-group mb-2'>
				<label class='required'>{{ this.$installations_locale.global.name }}</label>
				<input type='text' v-model='item.name' class='form-control'>
			</div>

			<div class='mb-2'>
				<circuit-combo :current='item.circuits' :multiple='true' :installation_id='installation_id' @selected='setCircuit' :required='true' />
				<div class='form-check'>
					<input v-model='item.circuit_propagation' type='checkbox' class='form-check-input' id='circuitPropagation'>
					<label class='form-check-label' for='circuitPropagation'>
						{{ this.$installations_locale.global.circuit_propagation }}
						<i class='fa fa-warning text-danger font-bold'></i>
					</label>
				</div>
			</div>

			<div class='mb-2'>
				<zone-type-combo :required='true' :current='current_type' @selected='setType' />

			</div>

			<div class='mb-2'>
				<div class='form-check'>
					<input v-model='item.wet_zone' type='checkbox' class='form-check-input' id='wetZone'>
					<label class='form-check-label' for='wetZone'>{{
							this.$installations_locale.global.wet_zone
						}}</label>
				</div>
			</div>

			<div class='mb-2'>
				<div class='form-check'>
					<input v-model='set_consigns' type='checkbox' class='form-check-input' id='setConsigns'>
					<label class='form-check-label' for='setConsigns'>
						{{ this.$t('Consigns.propagation_check') }}
					</label>
				</div>
			</div>

			<div v-show='set_consigns'>
				<hr class='my-3'>
<!--				<consign-component :consigns="item.consigns" :show_alert='true' @setConsigns='setConsigns' />-->
			</div>

		</div>

		<div class='modal-footer'>
			<button type='submit' @click='saveData' class='btn btn-primary'>{{
					this.$installations_locale.global.buttons.save
				}}
			</button>
		</div>
		<ModalConfirmFn />
	</div>

</template>
<script>
import {mapGetters} from 'vuex'
import CircuitCombo from '../config/circuits/CircuitCombo'
import ZoneTypeCombo from './ZoneTypeCombo'
// import ConsignComponent from '/../Modules/Alarms/Resources/assets/components/Consigns/ConsignsComponent'

export default {
	name: 'ZoneForm',
	props: {
		installation_id: {required: true},
		current: {default: null},
		parent_zone: {default: null},
	},
	components: {
		ZoneTypeCombo,
		CircuitCombo,
		// ConsignComponent,
	},
	created() {
		this.resetItem()
		if (this.parent_zone) {
			this.item.parent_id = this.parent_zone.item.id
		}

		if (this.current) {
			this.setCurrentItem()
		}

	},
	watch: {
		current: function(newItem) {
			if (!newItem) {
				this.resetItem()
			} else {
				this.setCurrentItem()
			}
		},
		current_type: function(data) {
			this.item.type_id = data ? data.id : null
		},
		parent_zone: function(value) {
			if (value) {
				this.item.parent_id = this.parent_zone.item.id
			} else {
				this.resetItem()
			}

		},
		set_consigns: function(value){
			if(!value){
				this.item.consigns = []
			}
		}

	},
	computed:{
		...mapGetters('zones',['listZoneUrl'])
	},
	data() {
		return {
			set_consigns: false,
			current_type: null,
			current_parent: null,
			item: {},
		}
	},
	methods: {
		resetItem() {
			this.item = {
				name: null,
				type_id: null,
				installation_id: this.installation_id,
				parent_id: null,
				wet_zone: false,
				circuits: [],
				circuit_propagation: false,
				consigns: [],
			}
			this.current_type = null
			this.current_parent = null
		},
		setCurrentItem() {
			this.item = {...this.current}
			this.item.consigns = []
			this.current_type = this.current.type
		},

		setType(item) {
			this.item.type_id = item ? item.id : null
		},

		setCircuit(items) {
			this.item.circuits = items
		},

		setConsigns(data) {
			this.item.consigns = data
		},

		saveData(e) {
			e.preventDefault()
			if (this.item.circuit_propagation) {
				this.$bus.$emit('fireModalConfirmFn', {
					text: this.$installations_locale.global.modals.confirm_propagation,
					parameters: null,
					fn: this.confirmSaveData,
				})
			} else {
				this.confirmSaveData()
			}
		},

		confirmSaveData() {
			const data = {...this.item}
			axios.post(`/api-admin/installations/${this.installation_id}/zones`, this.item).then(response => {
				this.$helpers.toastSuccess()
				setTimeout(()=>{
					window.location.href = this.listZoneUrl
				},1000)
				// this.refresh_fn(response)
				//this.resetItem()
			})
		},

	},
}
</script>
