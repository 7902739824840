<template>
    <div>
        <a :href="`#item-${item.id}`" class="d-block card-header py-3 collapsed" data-toggle="collapse"
           role="button" aria-expanded="true" :aria-controls="`#item-${item.id}`">
            <h6 class="m-0 font-weight-bold text-primary mb-3">
                <span class="nav-tab-info">{{ item.code }}</span>
                <button @click="deleteItem(item)" class="ml-1 btn btn-danger btn-xs float-right">
                    <i class="fa fa-trash"></i></button>
                <button @click="editItem(item)" class="ml-1 btn btn-warning btn-xs float-right">
                    <i class="fa fa-pencil"></i></button>
                <br>
                <small class="mt-2 d-block text-gray-600" v-if="item.recirculation && item.recirculation === 1">
                    <i>{{this.$installations_locale.global.recirculation}}</i>
                </small>
            </h6>
            <small>{{ item.description }}</small>
        </a>
        <!-- Card Content - Collapse -->
        <div :class="`collapse ${show} p-2` " collapsed="false" :id="`item-${item.id}`" data-parent="#CircuitAccordion_ac">
            <ul class="nav nav-pills mb-3" id="CircuitTabs_ac" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active nav-link-info" id="pills-accumulator-tab" data-toggle="pill" :href="'#tab-accumulators_'+item.id" role="tab" :aria-controls="'tab-accumulators_'+item.id" aria-selected="true">
                        {{this.$installations_locale.global.accumulators}}
                    </a>

                </li>

            </ul>
            <div class="tab-content" id="pills-tabContent">
                <accumulators
                    :installation_id="installation_id"
                    :circuit_id="item.id"
                    :accumulators="item.accumulators"
                    :refresh_fn = refresh_fn
                    class="tab-pane show fade  active "
                    :id="'tab-accumulators_'+item.id"
                    role="tabpanel"
                    aria-labelledby="pills-accumulator-tab"
                ></accumulators>
            </div>
        </div>
    </div>
</template>
<script>
import Accumulators from './accumulators/Accumulators.vue'
export default {
    name: "Circuit",
    props: {
        'installation_id': {required: true},
        'show': {default: false},
        'item': {required: true},
        refresh_fn: {}
    },
    components: {
        Accumulators
    },
    data() {
        return {}
    },
    methods: {
        editItem(item) {
            this.$emit('edit',item)
        },
        deleteItem(item) {
            this.$bus.$emit('fireModalConfirmDeleteFn', {
                text: `${this.$installations_locale.global.modals.delete_circuit} ${item.code} `,
                parameters: item,
                fn: this.removeItem
            })
        },
        removeItem(item) {
            axios.delete(`/api-admin/installations/${this.installation_id}/circuits/${item.id}`).then(response => {
                this.$toast.success(this.$t('toast.delete_success'))
                this.refresh_fn(response.data)
            })
        },

    }
}
</script>
