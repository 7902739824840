<template>
    <card-template>
        <template slot="titleCard">{{ this.$readings_locale.global.readings_list }}</template>
        <div class="table-responsive">
            <reading-table
                :type="'checkpoints'"
                :enableCreateBtn="false"
                :idTable="idTable"
                :columnsData="columns"
                :customFilters="customFilters"
                :rowItems="items"
                :customFields="customFields"
                :btnConfig="false"
                @edit="editItem"
                @delete="deleteItem"
            ></reading-table>
        </div>
    </card-template>
</template>
<script>
import ReadingTable from './ReadingTable'

export default {
    name: 'ReadingCheckpointList',
    props:['readings_ids'],
    mounted() {
        this.checkTabClick()
    },
    data() {
        return {
            idTable: 'readingsTable',
            items: [],
            customFilters: [
                'checkpoints',
                'date_range'
            ],
            customFields: [
                // {
                //     field: 'state',
                //     content: '<span class="badge badge-primary">ASDA</span>"'
                // }
            ],
            columns: [
                {
                    "field": "id",
                    "label": "#",
                    "display": "min_tabletP"
                },
                // {
                //     "field": "type",
                //     "label": this.$readings_locale.global.table_columns.type
                // },
                {
                    "field": "reading_date",
                    "label": this.$readings_locale.global.table_columns.reading_date
                },
                {
                    "field": "installation_name",
                    "label": this.$readings_locale.global.table_columns.installation
                },
                {
                    "field": "zone_path",
                    "label": this.$readings_locale.global.table_columns.ubication
                },
                {
                    "field": "item_name",
                    "label": this.$readings_locale.global.table_columns.checkpoint
                },
                // {
                //     "field": "purge",
                //     "label": this.$readings_locale.global.table_columns.purge
                // },
                // {
                //     "field": "ac_temperature",
                //     "label": this.$readings_locale.global.table_columns.temperature_ac
                // },
                // {
                //     "field": "af_temperature",
                //     "label": this.$readings_locale.global.table_columns.temperature_af
                // },
                // {
                //     "field": "chlorine",
                //     "label": this.$readings_locale.global.table_columns.chlorine
                // },
                // {
                //     "field": "comments",
                //     "label": this.$readings_locale.global.table_columns.comments
                // },
                // {
                //     "field": "user.name",
                //     "label": this.$readings_locale.global.table_columns.operator
                // },
                {
                    "field": "options",
                    "label": this.$readings_locale.global.table_columns.detail
                }
            ]
        }
    },
    computed: {},
    methods: {
        checkTabClick(){
            $('#pills-checkp-tab').one('click',()=>{
                this.getReadings()
            })
        },
        getReadings() {
            let queryParams = '';
            queryParams=`?readings_id=${JSON.stringify(this.readings_ids)}`
            axios.get(`/api-admin/readings/getReadings/checkpoints/${queryParams}`).then(response => {
                const rows = response.data
                this.items = rows
            })
        },
        editItem(item) {
            window.location = `/readings/${item.id}`
        },
        deleteItem(item) {
            this.$bus.$emit('fireModalConfirmDelete', {
                text: `${this.$readings_locale.global.modals.delete_task} ${item.name} `,
                parameters: item
            })
        },
        removeItem(item) {
            axios.delete(`/api-admin/readings/${item.id}`).then(response => {
                const rows = response.data
                this.items = rows
                this.$toast.success(this.$t('toast.delete_success'))
            })
        },
        newItem() {
            window.location = '/readings/create'
        },
        setItem(item) {
            window.location = `/readings/${item.id}/config`
        }
    }
}
</script>
