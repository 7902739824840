<template>
    <div>
        <label v-if="label" :class="required ? 'required' : null">{{ this.$t('sensors') }}</label>
        <v-select
            :placeholder="this.$t('sensors')"
            :options="sensors"
            @option:selected="emitSelected"
            @option:deselected="emitSelected"
            v-model="current_sensors"
            label="code"
            :reduce="item => item.id"
            :multiple="multiple"
            code="id"></v-select>
    </div>

</template>

<script>
import vSelect from 'vue-select'
import "vue-select/dist/vue-select.css"
export default {
    name: "SensorCombo",
    props:{
        required: {default: false},
        current: {default: null},
        label: {default: true},
        multiple: {default: false}
    },
    components: {
        vSelect
    },
    created() {
        this.getData()
        if(this.current){
            this.current_sensors = [...this.current]
        }
    },
    watch: {
        current_circuit: function () {
            this.emitSelected()
        },
        current: function (value) {
            this.current_sensors = value ? value : null
        }
    },
    data() {
        return {
            sensors: [],
            current_sensors: null,
        }
    },
    methods: {
        getData() {
            const url = `/api-admin/sensors/combo`
            axios.get(url).then(response => {
                this.sensors = response.data
            })
        },
        emitSelected() {
            this.$emit('selected', this.current_sensors)
        },

    }
}
</script>

<style scoped>

</style>
