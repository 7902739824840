<template>
	<div>
		<label v-if="label" :class="required ? 'required' : null">{{ this.$installations_locale.global.category }}</label>
		<v-select
			:placeholder="this.$installations_locale.global.category"
			:options="categories"
			v-model="current_cat"
			label="label"
			@input='emitSelected'
			code="key"></v-select>
	</div>

</template>
<script>
import vSelect from 'vue-select'
import "vue-select/dist/vue-select.css"
export default{
	name:'CheckpointCatCombo',
	props: {
		required: {default: false},
		current: {default: null},
		label: {default: true},
		installation_id: {required: true}
	},
	components: {
		vSelect
	},
	created() {
		this.getData()
		if(this.current) this.current_cat = {...this.current}
	},
	watch: {
		current: function (value){
			this.current_cat = value ?  {...value} : null
		},
		categories: function (value) {
			if(!value)	this.current_cat = {}
		}
	},
	data() {
		return {
			categories: [],
			current_cat: null,
		}
	},
	methods: {
		getData() {
			const url = `/api-admin/installations/${this.installation_id}/checkpoints/categories`
			axios.get(url).then(response => {
				this.categories = response
			})
		},
		emitSelected() {
			this.$emit('selected', this.current_cat)
		}
	}
}
</script>