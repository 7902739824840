<template>
	<div>
		<div class='row mb-2'>
            <div class="col-md-10 col-12">
             <div class="row">
                 <div class='mb-2 col-12'>
                     <label>{{ this.$installations_locale.global.code }}</label>
                     <input type='text' v-model='formData.client_code' class='form-control'>
                 </div>

                 <div class='col-sm-6 mb-2'>
                     <checkpoint-type-combo @selected='setType' :current='current_checkpoint_type' :installation_id='installation_id' />
                 </div>
                 <div class='col-sm-6 mb-2'>
                     <checkpoint-cat-combo @selected='setCat' :current='current_checkpoint_cat' :installation_id='installation_id' />
                 </div>

                 <div class='col-sm-12'>
                     <div class='row'>
                         <div class='col-sm-4 mb-2'>
                             <div class='form-check'>
                                 <input v-model='formData.ac' type='checkbox' class='form-check-input' id='CheckpointAc'>
                                 <label class='form-check-label' for='CheckpointAc'>{{
                                         this.$installations_locale.global.checkpoint_ac
                                     }}</label>
                             </div>
                         </div>
                         <div class='col-sm-4 mb-2'>
                             <div class='form-check'>
                                 <input v-model='formData.af' type='checkbox' class='form-check-input' id='CheckpointAf'>
                                 <label class='form-check-label' for='CheckpointAf'>{{
                                         this.$installations_locale.global.checkpoint_af
                                     }}</label>
                             </div>
                         </div>
                         <div class='col-sm-4 mb-2'>
                             <div class='form-check'>
                                 <input v-model='formData.a_mix' type='checkbox' class='form-check-input' id='CheckpointMix'>
                                 <label class='form-check-label' for='CheckpointMix'>{{
                                         this.$installations_locale.global.checkpoint_mix
                                     }}</label>
                             </div>
                         </div>
                     </div>

                 </div>
                 <div class='col-md-6 mb-3'>
                     <sensor-combo :current="this.formData.sensors" @selected="setSensors" :multiple="true"></sensor-combo>
                 </div>
                 <div class='col-sm-12 mb-2'>
                     <label>{{ this.$installations_locale.global.comments }}</label>
                     <textarea class='form-control' v-model='formData.comments' id='comments'></textarea>
                 </div>
             </div>
            </div>
            <div class="col-12 col-md-2">
                <QR
                    :token="'cpp'+this.formData.client_code"
                    :width="200"
                    align="text-right"
                />
            </div>

		</div>
		<hr class='my-3'>
<!--		<consigns-component-->
<!--			:consigns='formData.consigns'-->
<!--			@setConsigns ="setConsigns"-->
<!--		/>-->
		<div class='text-right'>
			<button @click='save' class='btn btn-primary  mt-3'>
				{{ this.$installations_locale.global.buttons.save }}
			</button>
		</div>

	</div>
</template>
<script>
import {mapGetters} from 'vuex'
import CheckpointTypeCombo from './CheckpointTypeCombo'
import CheckpointCatCombo from './CheckpointCatCombo'
// import ConsignsComponent from '/../Modules/Alarms/Resources/assets/components/Consigns/ConsignsComponent'
import SensorCombo from '../../../../../Sensors/Resources/assets/components/SensorCombo'
import QR from "../../../../../../resources/js/components/QR/QR.vue";

export default {
	name: 'CheckpointForm',
	props: {
		current: {},
		installation_id: {required: true},
		zone_id: {required: true},
	},
	components: {
        QR,
		CheckpointTypeCombo,
		CheckpointCatCombo,SensorCombo
		// ConsignsComponent,
	},
	created() {
		this.current ? this.setCurrent() : this.resetForm()
	},
	watch: {
		current: function(value) {
			this.current ? this.setCurrent() : this.resetForm()
		},
	},
	computed: {
		...mapGetters('checkpoints', ['listCheckpointUrl']),
	},
	data() {
		return {
			checkpoint: null,
			current_checkpoint_type: null,
			current_checkpoint_cat: null,
			formData: {},
		}
	},
	methods: {
		setCurrent() {
			this.formData = {
				id: this.current.id,
				client_code: this.current.client_code,
				zone_id: this.zone_id,
				installation_id: this.installation_id,
				checkpoint_type: this.current.type_key,
				ac: this.current.ac,
				af: this.current.af,
				a_mix: this.current.mix,
				comments: this.current.comments,
				checkpoint_cat: this.current.cat_key,
				consigns: this.current.consigns,
				sensors: this.current.sensors
			}
			this.current_checkpoint_type = {key: this.current.type_key, label: this.current.type_label}
			this.current_checkpoint_cat = {key: this.current.cat_key, label: this.current.cat_label}
		},
        setSensors(sensors){
            this.formData.sensors = sensors
        },
		resetForm() {
			this.formData = {
				zone_id: this.zone_id,
				client_code: null,
				installation_id: this.installation_id,
				checkpoint_type: null,
				ac: false,
				af: false,
				a_mix: false,
				comments: '',
				checkpoint_cat: null,
				consigns: []
			}
			this.current_checkpoint_type = null
			this.current_checkpoint_cat = null
		},

		setType(type) {
			this.formData.checkpoint_type = type ? type.key : null
			this.current_checkpoint_type = type
		},

		setCat(cat) {
			this.formData.checkpoint_cat = cat ? cat.key : null
			this.current_checkpoint_cat = cat
		},

		setConsigns(data){
			this.formData.consigns = data
		},

		saveCheckpoint(e) {
			if (e) e.preventDefault()
			this.save()
		},

		save() {
			const url = `/api-admin/installations/${this.installation_id}/checkpoints/zone/${this.zone_id}`
			const data = this.formData
			axios.post(url, data).then(response => {
				this.$helpers.toastSuccess()
				setTimeout(() => {
					window.location.href = this.listCheckpointUrl
				}, 1000)
			})
		},
	},
}
</script>
