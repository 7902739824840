<template>
    <div>
        <Datepicker range circle show-clear-button lang="es" placeholder="Selecciona fecha"
             v-model="filterDateRange" @input='getData' @reset='restore' :date-format="this.formatDate" text-format="short"
        ></Datepicker>

        <!--        <v-select-->
        <!--            style='min-width: 200px'-->
        <!--            :placeholder='this.$readings_locale.global.buttons.filter'-->
        <!--            :options='options'-->
        <!--            v-model='current'-->
        <!--            label='label'-->
        <!--            @input='getData'-->
        <!--            code='key'></v-select>-->
    </div>
</template>

<script>
import VueDatepickerUi from 'vue-datepicker-ui'
import 'vue-datepicker-ui/lib/vuedatepickerui.css';

export default {
    name: "ReadingFilterDateRange",
    props: {
        refresh_fn: {required: true},
        // task_id: {required: true},
    },
    mounted() {
        console.log(this.options)
    },
    components: {
        Datepicker: VueDatepickerUi,
    },
    data() {
        return {
            filterDateRange: [null, null],
            current: [null, null],
            formatDate:{
                day: '2-digit',
                month: '2-digit',
                year: 'numeric' }
        }
    },
    methods: {
        getData() {
            if (!this.filterDateRange.includes(null)) {
                this.filterDateRange[0]=new Date(this.filterDateRange[0]).toISOString().slice(0, 10);
                this.filterDateRange[1]=new Date(this.filterDateRange[1]).toISOString().slice(0, 10);
                this.$emit('setFilter', {'date_range': this.filterDateRange})
            }
        },
        restore() {
            this.$emit('setFilter', {'date_range': []})

        },
        convertOptionsSelect(data) {
            let result = [];
            Object.keys(data).forEach((value, key) => {
                result.push({'key': value, 'label': data[value]})
            })
            return result;
        }
    },
}
</script>

<style lang="scss">
    .calendar.range{
        z-index: 9999;
    }
</style>
