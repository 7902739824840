<template>
    <VueToggles :checkedText="$t_notifications('users_management.enable') "
                :uncheckedText="$t_notifications('users_management.disable')"
                :checkedBg="'#1cc88a'" :uncheckedBg="'#e74a3b'"
                :value="channel.enabled"
                @click="toggleEnableChannel"></VueToggles>
</template>

<script>
export default{
    name:'ToggleChannel',
    props:['channel'],
    methods:{
        toggleEnableChannel(){
            this.$store.commit('notifications/setCurrentChannelKey',this.channel.key)
            this.$store.dispatch('notifications/toggleChannelStatusAction')
        }
    }
}
</script>
