<template>
    <div>
        <h3>{{this.$alarms_locale.global.alarms}}</h3>
        <hr>
        <card-template :showHeader='false'>

        </card-template>
    </div>
</template>
<script>

export default{
    name: 'AlarmComponent',
    components:{

    }
}
</script>
