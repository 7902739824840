<template>
    <div>
        <v-client-table :ref='idTable' :data='items' :columns='columns' :options='options'>
<!--            <div slot='afterFilter' style="margin-left:10px;align-items: center;display: flex;margin-bottom: 0;">-->
<!--                <label v-if="this.customFilters.includes('date_range')"-->
<!--                       for="">{{ this.$alarms_locale.global.table_columns.alarm_date }}:</label>-->
<!--                <reading-filter-date-range v-if="this.customFilters.includes('date_range')" @setFilter="setFilter"-->
<!--                                           :current="currentFilter" class='my-2'-->
<!--                                           :refresh_fn='refreshData'></reading-filter-date-range>-->
<!--            </div>-->
            <template v-for="(item) in customFields">
                <div v-html :slot="item.field" v-html="item.content"> {{ item.content }}</div>
            </template>
            <div slot='alarm_state' slot-scope='{row}' class="text-center">
                <span class="p-2 w-100 text-center":class="(row.alarm_state_id==1?'badge badge-danger':'badge badge-light')">{{row.alarm_state}}</span>
            </div>
            <div slot='options' slot-scope='{row}' class=" text-center">
                <button @click='editItem(row)' class='btn btn-xs btn-warning'><i class='fa fa-pencil'></i></button>
                <button @click='showDetail(row)' class=' btn btn-xs btn-primary px-2'>
                    <i  :class="'text-white fa-info-circle fa'"></i>
                </button>
            </div>
        </v-client-table>
    </div>
</template>
<script>
import AlarmsFilter from './AlarmsFilter'
import 'animate.css';

export default {
    name: "AlarmHistoryList",
    props: {
        btnConfig: {default: false},
        customFilters: {required: false},
        columnsData: {required: true},
        idTable: {required: true},
        rowItems: {required: true},
        customFields: {required: false},
        enableCreateBtn: {default: true},
        type: {required: true},
    },
    computed: {},
    components: {
        AlarmsFilter,
    },
    created() {
        this.setColumns()
        this.items = [...this.rowItems]
    },
    mounted() {

    },

    watch: {
        rowItems: function (newItems) {
            this.items = [...newItems]
        },
    },
    data() {
        return {
            items: [],
            columns: [],
            currentFilter: [],
            options: {
                rowClassCallback: row => {
                    return row.disabled ? 'disabled-item' : null
                },

                resizableColumns: true,
                perPage: 10,
                perPageValues: [5, 10, 50, 100],
                pagination: {chunk: 5},
                datepickerOptions: {
                    showDropdowns: true,
                    autoUpdateInput: true,
                },
                sortIcon: {
                    base: 'fa float-right',
                    is: 'fa-sort',
                    up: 'fa-sort-up',
                    down: 'fa-sort-down',
                },
                texts: {
                    count: `${this.$t('Showing {from} to {to} of {count} records|{count} records|One record')}`,
                    first: `${this.$t('First')}`,
                    last: `${this.$t('Last')}`,
                    filter: `${this.$t('Filter')}:`,
                    filterPlaceholder: `${this.$t('Search query')}`,
                    limit: `${this.$t('Records')}:`,
                    page: `${this.$t('Page')}:`,
                    noResults: `${this.$t('No matching records')}`,
                    filterBy: `${this.$t('Filter by {column}')}`,
                    loading: `${this.$t('Loading')}...`,
                    defaultOption: `${this.$t('Select {column}')}`,
                    columns: `${this.$t('Columns')}`,
                },
                columnsDisplay: {},
            },
            selectChangeState: '',
        }
    },
    methods: {

        setColumns() {
            const headings = {}
            this.columnsData.map(item => {
                this.columns.push(item.field)
                headings[item.field] = item.label
                if (item.display) {
                    this.options.columnsDisplay[item.field] = item.display
                }
            })
            this.options.headings = headings
        },
        refreshData(items) {
            this.items = items
        },

        editItem(item) {
            this.$emit('edit', item)
        },
        deleteItem(item) {
            this.$emit('delete', item)
        },
        newItem() {
            this.$emit('new')
        },
        config(item) {
            this.$emit('config', item)
        },

        setFilter(filter) {
            let filterKey = Object.keys(filter)[0];
            this.currentFilter[filterKey] = filter[filterKey]
            // this.currentFilter = filter
            this.filterResults()
        },

        filterResults() {
            let filtersKey = Object.keys(this.currentFilter);
            let queryParams = '?';
            filtersKey.forEach((value, key) => {
                queryParams += `${value}=${this.currentFilter[value]}&`
            })
            axios.get(`/api-admin/readings/getReadings/${this.type}${queryParams}`).then(response => {
                const rows = response.data
                this.items = rows
                this.refreshData(rows)
            })
        },
        restore(item) {
            axios.get(`/api-admin/readings/getReadings/${this.type}/restore`).then(response => {
                this.filterResults()
            })
        },
    },
}
</script>

<style lang='scss'>

tr.VueTables__row.disabled-item {
    background-color: #ffe5e5 !important;
}

.VueTables {
    label {
        margin-right: 10px;
    }
}

.VueTables__wrapper {
    max-height: 500px;
    overflow-y: scroll;
}

.VueTables__search-field {
    display: flex;
}

.VueTables__limit-field {
    display: flex;
}

.VueTables__columns-dropdown-wrapper {
    margin-right: 10px;
}

.VueTables__row {
    td {
        padding: 3px 5px;
        vertical-align: middle;
    }
}

.VueTables__columns-dropdown {
    input {
        margin-right: 3px;
    }
}

.VueTables__limit-field {
    select {
        margin: 5% 0;
    }

}
.table-reading-detail{
    font-size: 13px;
    tr{
        text-align: left;
    }
    td{
        text-align: center;
        padding: 2px 0;
        color:black;
        vertical-align: middle;
    }
}
.modal-detail-reading{
    color:black;
    font-size: 13px;

}
</style>
