<template>
    <card-template>
        <div class="row">
            <div class="col-12 col-lg-12">
                <div class="row justify-content-center">
                    <div class="col-12 col-lg-2 mb-3">
                        <div class="mb-3">
                            <div class="col-sm-12 mb-3">
                                <button class="btn btn-primary" type="button" @click.stop="toggleModal(1)">Seleccionar puntos de control</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-2 mb-3">
                        <div class="mb-3">
                            <div class="col-sm-12 mb-3">
                                <label for="tipoEvento" class="required">Tipo lectura</label>
                                <select class="form-control" name="tipoEvento" id="tipoEvento">
                                    <option value="incidencia" selected>Cloro</option>
                                    <option value="mantenimiento">Temperatura</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-12 mb-3">
                        <div class="mb-3">
                            <div class="col-sm-12 mb-3">
                                <label for="fechaFinEvento" class="required">Valor mínimo de lectura</label>
                                <input type="number"  id="fechaFinEvento" class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-12 mb-3">
                        <div class="mb-3">
                            <div class="col-sm-12 mb-3">
                                <label for="fechaFinEvento" class="required">Valor máximo de lectura</label>
                                <input type="number"  id="fechaFinEvento" class="form-control">
                            </div>
                        </div>
                    </div>

                </div>
                <div class="row justify-content-center">
                    <div class="col-12 col-lg-2 mb-3">
                        <div class="mb-3">
                            <div class="col-sm-12 mb-3">
                                <button class="btn btn-success h-100" type="button" >Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div :key="1">
                    <checkpoint-tree-modal
                        :show="showModal(1)"
                        @close="toggleModal(1)" />
                </div>
            </div>
        </div>
    </card-template>
</template>

<script>
import CheckpointTreeModal from './CheckpointTreeModal.vue'

export default {
    name: "ReadingsForm",
    components:{
        CheckpointTreeModal
    },
    data() {
        return {
            activeModal: 0,

        }
    },
    created() {
    },
    mounted() {

    },
    methods: {
        showModal: function(id) {
            return this.activeModal === id
        },

        toggleModal: function (id) {

            if(this.activeModal !== 0) {
                this.activeModal = 0
                return false
            }
            this.activeModal = id
        },
    }
}
</script>

<style lang="css" >

</style>
