<template>
    <div>
        <div id="Filter">
            <h4>{{ this.$installations_locale.global.filter }}</h4>
            <form :action="search" method="POST">
                <div class="row">
                    <div class="col-sm-10 mb-2">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group ">
                                    <input type="text" v-model="filter.name" :placeholder="this.$installations_locale.global.name" id="search" class="form-control">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <zone-type-combo :label="false" @selected="setType" />
                            </div>
                        </div>

                        <div class="form-check">
                            <input v-model="filter.wet_zone" type="checkbox" class="form-check-input" id="wetZoneFilter">
                            <label class="form-check-label" for="wetZoneFilter">{{
                                    this.$installations_locale.global.wet_zone
                                }}</label>
                        </div>
                    </div>
                    <div class="col-sm-2 mb-2">
                        <button @click="search" class="btn btn-primary"><i class="fa fa-search"></i>
                            {{ this.$installations_locale.global.buttons.search }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <hr>
        <ul class="list-group">
            <li class="list-group-item" :key="i" v-for="(zone,i) in zones">
                <zone-item  :refresh_fn="refreshData" :edit_fn="edit_fn" :zone="zone" :installation_id="zone.item.installation_id"></zone-item>
            </li>
        </ul>

    </div>
</template>
<script>
import ZoneItem from './ZoneItem'
import ZoneTypeCombo from './ZoneTypeCombo'
export default {
    name: 'SearchComponent',
    props: {
        installation_id: {required: true},
        edit_fn: {required:true, type:Function},
    },
    components: {
        ZoneItem,
        ZoneTypeCombo
    },
    data() {
        return {
            filter: {
                name: null,
                wet_zone: false,
                type_id: null
            },
            zones: []
        }
    },
    methods: {
        setType(item){
            this.filter.type_id = item ? item.id : null
        },
        search(e) {
            if(e) e.preventDefault()
            const filter = this.filter
            if (
                (filter.name === null || filter.name === '')
                && filter.wet_zone === false
                && filter.type_id === null

            ) {
                return this.$toast.error(this.$installations_locale.global.errors.empty_filter)
            }
            const url = `/api-admin/installations/${this.installation_id}/zones/search`
            axios.post(url, {filter: this.filter}).then(response => {
                if (response.data.length < 1) {
                    this.$toast.info(this.$installations_locale.global.errors.empty_results)
                }
                this.zones = response.data
            })
        },

        refreshData() {
            this.search()
        },

    }
}
</script>
