<template>
	<card-template :show_header='false'>
		<div class='row mb-3'>
			<div class='col-md-4'>
				<img class='img-fluid avatar mb-3' :src='image_path'>
				<div class='mb-3'>
					<div style="max-width: 380px;" class='custom-file'>
						<input type='file' @change='setFile' class='custom-file-input' id='file'>
						<label class='custom-file-label' for='file'>{{ fileName }}</label>
					</div>
				</div>
			</div>
			<div class='col-md-8'>
				<div class='row'>
                    <div class="col-6 p-0">
                        <div class='col-md-12 mb-3'>
                            <label for='code' class='required'>{{ this.$installations_locale.global.code }}</label>
                            <input type='text' id='code' v-model='item.code' class='form-control'>
                        </div>

                        <div class='col-md-12 mb-3'>
                            <label class='required'>{{ this.$installations_locale.global.function }}</label>
                            <v-select
                                :options='function_options'
                                v-model='current_function'
                                label='label'
                                code='code'></v-select>
                        </div>
                    </div>
                    <div class="col-2 m-auto">
                        <QR
                            :token="'cpp'+item.code"
                            :width="200"
                            align="text-center"
                        />
                    </div>


					<div class='col-md-6 mb-3'>
						<label class='required'>{{ this.$installations_locale.global.registry }}</label>
						<v-select
							:options='registry_options'
							v-model='current_registry'
							label='label'
							code='code'></v-select>
					</div>
                    <div class='col-md-6 mb-3'>
                       <sensor-combo :current="this.item.sensors" @selected="setSensors" :multiple="true"></sensor-combo>
                    </div>
				</div>
				<hr class='col-10 my-3'>

				<div class='form-group mb-3'>
					<label>{{ this.$installations_locale.global.capacity }}</label>
					<textarea class='form-control' v-model='item.capacity' id='capacity'></textarea>
				</div>

			</div>
		</div>

		<div class='row'>
			<div class='col-12 my-3'>
			</div>
		</div>

		<hr>
		<button @click='save' class='btn btn-primary'>{{ this.$installations_locale.global.buttons.save }}</button>
	</card-template>
</template>
<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import SensorCombo from '../../../../../../../Sensors/Resources/assets/components/SensorCombo'
import QR from "../../../../../../../../resources/js/components/QR/QR.vue";
export default {
	name: 'AccumulatorForm',
	props: {
		installation_id: {required: true},
		circuit_id: {required: true},
		accumulator_id: {},
		registry_combo: {required: true, type: Object},
		function_combo: {required: true, type: Object},
	},
	components: {
        QR,
		vSelect,
        SensorCombo
		// ConsignsComponent,
	},
	created() {
		this.setCombos()
		if (this.accumulator_id) {
			this.getItem()
		} else {
			this.resetItem()
		}
	},
	watch: {
		current_function: function(value) {
			this.item.function = value ? value.code : null
		},
		current_registry: function(value) {
			this.item.registry = value ? value.code : null
		},
	},
	data() {
		return {
			fileName: this.$installations_locale.global.choose_file,
			image_path: '/images/no-image.jpg',
			registry_options: [],
			function_options: [],
			current_registry: null,
			current_function: null,
			item: {},
		}
	},
	methods: {
		updateConsigns(consigns){
			this.item.consigns = consigns
		},
		resetItem() {
			this.item = {
				circuit_id: this.circuit_id,
				code: null,
				client_code: null,
				capacity: null,
				registry: null,
				function: null,
				consigns: [],
				image: null,
			}
		},
		getItem() {
			axios.get(`/api-admin/installations/${this.installation_id}/circuits/${this.circuit_id}/accumulators/${this.accumulator_id}`).then(response => {
				this.item = response.data
				this.current_registry = {code: response.data.registry, label: response.data.registry_label}
				this.current_function = {code: response.data.function, label: response.data.function_label}
				this.image_path = response.data.foto
			})
		},
        setSensors(sensors){
            this.item.sensors = sensors
        },
		setFile(e) {
			const file = e.target.files[0]
			this.fileName = file.name
			this.item.image = file
		},

		setCombos() {
			Object.keys(this.registry_combo).map((key) => {
				const data = {
					code: key,
					label: this.registry_combo[key],
				}
				this.registry_options.push(data)
			})

			Object.keys(this.function_combo).map(key => {
				const data = {
					code: key,
					label: this.function_combo[key],
				}
				this.function_options.push(data)
			})
		},
		save() {
			let formData = new FormData()
			const data = {...this.item}
			if (data.code.includes(' ')) {
				return this.$toast.error('El código solo puede contener letras, números,-,_.  sin acentos ni espacios')
			}
			Object.keys(data).map(field => {
				console.log(field,data[field])
				formData.append(field, data[field] === null ? '' : typeof data[field] === "object" ? JSON.stringify(data[field]):data[field])
			})

			axios.post(`/api-admin/installations/${this.installation_id}/circuits/${this.circuit_id}/accumulators`, formData, {headers: {'content-type': 'multipart/form-data'}}).then(response => {
				this.$helpers.showLoading()
				this.$helpers.toastSuccess()
				setTimeout(() => {
					window.location = `/installations/${this.installation_id}/config/circuits/${this.circuit_id}/accumulators/${response.data.id}`
				}, 1000)
			})
		},
	},

}
</script>
<style lang='css' scoped>
.custom-file-input:lang(en) ~ .custom-file-label:after {
	content: "\f093"; /* inspect an icon for this value */
	font-family: FontAwesome; /* intentionally no fallback */
	font-size: 16px;
}

.avatar {
	border-radius: 10px;
	border: solid 1px #ccc;
	padding: 10px;
}
</style>

