<template>
    <tr>
        <td>{{channel.id}}</td>
        <td>
            <img class="mr-2" width="25" :src="`/notifications/images/${channel.key}.png`" alt="">
            <a :href="`/notifications/channels/${channel.key}`">{{channel.key}}</a>
        </td>
        <td>
            <ToggleChannel class="float-right" :channel="channel" />
        </td>
    </tr>
</template>
<script>
import VueToggles from 'vue-toggles/dist/vue-toggles.ssr';
import 'vue-toggles/dist/vue-toggles.ssr.css';
import ToggleChannel from "./ToggleChannel.vue";
export default{
    name: 'ChannelTableItem',
    props: ['channel'],
    components:{VueToggles, ToggleChannel},
}
</script>
