<template>
	<div>
		<v-select
			style='min-width: 200px'
			:placeholder='this.$installations_locale.global.buttons.filter'
			:options='options'
			v-model='current'
			label='label'
			@input='getData'
			code='key'></v-select>
	</div>
</template>
<script>
import vSelect from 'vue-select'

export default {
	name: 'CheckpointFilter',
	props: {
		refresh_fn: {required: true},
		installation_id: {required: true},
		zone_id: {required: true},
	},
	components: {
		vSelect,
	},
	created() {
	},
	watch: {},
	data() {
		return {
			options: [
				{key: 'all', label: this.$installations_locale.global.filter_all},
				{key: 'only_enabled', label: this.$installations_locale.global.filter_enabled},
				{key: 'only_disabled', label: this.$installations_locale.global.filter_disabled},
			],
			current: {key: 'only_enabled', label: this.$installations_locale.global.filter_enabled},
		}
	},
	methods: {
		getData() {
			this.$emit('setFilter',this.current)
		},
	},
}
</script>
