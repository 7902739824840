<template>
    <div :class="align" v-html="this.qrBase64"></div>
</template>
<script>
import QRCode from 'qrcode'

export default {
    name: 'QR',
    props: {
        'token': {
            type: String,
            required: true,
        },
        width: {
            type: Number,
            default: 350
        },
        align: {
            type: String,
            default: 'text-center',
        }
    },
    components: {},
    data() {
        return {
            options: {
                color: {
                    dark: '#000',
                    light: '#fff'
                },
                type: 'svg',
                quality: 1,
            }

        }
    },
    computed: {
        qrBase64: function () {
            let imageQr = ''
            const options = {
                width: this.width,
                ...this.options
            }
            QRCode.toString(this.token, options, function (err, url) {
                if (err) throw err
                imageQr = url
            })
            return imageQr
        }
    },
    methods: {}

}
</script>
