<template>
  <button class="btn btn-dark" @click="goToEditAlarm()">{{this.$t('edit_alarm_configuration')}}</button>
</template>

<script>
export default {
    name: "ButtonHref",
    props: {
      item_id: { required:true},
      item_type: { required:true },
      item_model: { required:true }
    },
    created() {
    },
    data() {
        return {
            item_type_class:''
        }
    },
    methods: {
        goToEditAlarm(){
          window.open('/alarms/config/'+this.item_type+'/'+this.item_model+'/'+this.item_id)
        },

    }
}
</script>

<style scoped>

</style>
