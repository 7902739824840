export const getCurrentCheckpoint = (state) => {
	return state.current
}

export const listCheckpointUrl = (state, getters, rootState, rootGetters) => {
	const installation = rootGetters['installations/getCurrent']
	const zone =  rootGetters['zones/getCurrentZone']
	if(installation && zone){
		let url = state.list_url.replace(':installation_id',installation.id)
		return url.replace(':zone_id',zone.id)
	}
	return null
}

export const newCheckpointUrl = (state, getters, rootState, rootGetters) => {
	const installation = rootGetters['installations/getCurrent']
	const zone =  rootGetters['zones/getCurrentZone']
	if(installation && zone){
		let url = state.create_url.replace(':installation_id',installation.id)
		return url.replace(':zone_id',zone.id)
	}
	return null
}

export const editCheckpointUrl = (state, getters, rootState, rootGetters) => {
	const installation = rootGetters['installations/getCurrent']
	const zone =  rootGetters['zones/getCurrentZone']
	const checkpoint = state.current
	if(installation && zone && checkpoint){
		let url = state.edit_url.replace(':installation_id',installation.id)
		url = url.replace(':zone_id',zone.id)
		return url.replace(':checkpoint_id',checkpoint.id)
	}
	return null
}