<template>
    <card-template>
        <template slot="titleCard">{{ this.$sensors_locale.global.nav }}</template>
        <div class="table-responsive">
            <inno-core-datatable
                :enableCreateBtn="this.$helpers.hasPermission('sensors.edit')"
                :enableEditBtn="this.$helpers.hasPermission('sensors.edit')"
                :enableDelBtn="this.$helpers.hasPermission('sensors.delete')"
                :idTable="idTable"
                :columnsData="columns"
                :rowItems="items"
                :customFields="customFields"
                @edit="editItem"
                @delete="deleteItem"
                @new="newItem"


            />
        </div>
        <modal-confirm-delete @confirmed="removeItem"/>
    </card-template>
</template>

<script>

export default{
    name: 'SensorList',
    created() {
        this.getItems()
    },
    data() {
        return {
            idTable: 'sensorsTable',
            items: [],
            customFields: [],
            columns: [

                {
                    field: 'name',
                    label: this.$sensors_locale.global.columns.name,
                },
                {
                    field: 'code',
                    label: this.$sensors_locale.global.columns.code,
                },
                {
                    field: 'last_value',
                    label: this.$sensors_locale.global.columns.last_value
                },

                {
                    field: 'options',
                    label: this.$sensors_locale.global.columns.options
                }
            ]
        }
    },
    computed: {},
    methods: {
        getItems() {
            axios.get('/api-admin/sensors').then(response => {
                const rows = response.data
                this.items = rows
            })
        },
        editItem(item) {
            window.location = `/sensors/${item.id}/edit`
        },
        deleteItem(item) {
            this.$bus.$emit('fireModalConfirmDelete', {
                text: `${this.$sensors_locale.global.modals.delete_sensor} ${item.name} `,
                parameters: item
            })
        },
        removeItem(item) {
            axios.delete(`/api-admin/sensors/${item.id}`).then(response => {
                this.$toast.success(this.$t('toast.delete_success'))
                this.getItems()
            })
        },
        newItem() {
            window.location = '/sensors/create'
        }
    }

}
</script>
<style>
.table-responsive{
 min-height: auto !important;
}
</style>
