<template>
	<div>

		<ul class='nav nav-pills mb-3' id='pills-tab' role='tablist'>
			<li class='nav-item'>
				<a class='nav-link active' id='pills-ac-tab' data-toggle='pill' href='#tab-circuits_ac' role='tab' aria-controls='pills-ac' aria-selected='true'>
					{{ this.$installations_locale.global.circuit_hot }}
				</a>
			</li>

			<li class='nav-item'>
				<a class='nav-link ' id='pills-af-tab' data-toggle='pill' href='#tab-circuits_af' role='tab' aria-controls='pills-af' aria-selected='true'>
					{{ this.$installations_locale.global.circuit_cold }}
				</a>
			</li>


			<li class='nav-item'>
				<a class='nav-link' id='pills-zones-tab' :href="'/installations/'+id+'/zones'" aria-selected='false'>
					{{ this.$installations_locale.global.zones }}
				</a>
			</li>
		</ul>
		<div class='tab-content' id='pills-tabContent'>

			<circuits circuit_type='ac' :installation_id='id' class='tab-pane show fade  active' id='tab-circuits_ac' role='tabpanel' aria-labelledby='pills-ac-tab'></circuits>

			<circuits circuit_type='af' :installation_id='id' class='tab-pane show fade ' id='tab-circuits_af' role='tabpanel' aria-labelledby='pills-af-tab'></circuits>

<!--			<card-template :show_header='false'-->
<!--						   class='tab-pane show fade '-->
<!--						   id='tab-consigns'-->
<!--						   role='tabpanel'-->
<!--						   aria-labelledby='pills-consigns-tab'>-->
<!--				<p>-->
<!--					{{ this.$t('Consigns.propagation_installation') }}-->
<!--				</p>-->
<!--				<consign-component-->
<!--					:consigns='consigns'-->
<!--					:show_alert='true'-->
<!--					:show_title='false'-->
<!--					@setConsigns='setConsigns'>-->

<!--					<div class='col-12'>-->
<!--						<hr>-->
<!--						<button @click="saveConsigns" class='btn btn-primary'>{{ this.$t('buttons.apply') }}</button>-->
<!--					</div>-->
<!--				</consign-component>-->
<!--			</card-template>-->

		</div>
	</div>
</template>
<script>
import Circuits from './circuits/Circuits'
// import ConsignComponent from '/../Modules/Alarms/Resources/assets/components/Consigns/ConsignsComponent'

export default {
	name: 'InstallationConfig',
	props: ['id'],
	components: {
		Circuits,
		// ConsignComponent,
	},
	created() {
		this.setData()
	},
	data() {
		return {
			consigns: [],
			item: {},
		}
	},
	methods: {
		setData() {
			axios.get(`/api-admin/installations/${this.id}`).then(response => {
				this.item = response
			})
		},

		setConsigns(data) {
			this.consigns = data
		},

		saveConsigns() {
			const url = `/api-admin/installations/${this.id}/consigns`
			axios.post(url, {consigns: this.consigns}).then(response => {
				this.$helpers.toastSuccess()
			})
		},
	},
}
</script>
