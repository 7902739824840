<template>
    <div class="row  m-0  align-items-center w-100 justify-content-between">
        <div class="col-md-4 col-12 p-0 d-flex align-items-center">
            <label
                class="my-0" for="">{{ this.$t('Installation') }}:</label>
            <v-select class="w-100" v-model="current_installation" @input='setInstallation' :options="installations"
                      label="name"
                      :settings="{width:'100%' }">
            </v-select>
        </div>
        <div class="col-md-auto col-12 p-0 d-flex align-items-center">
            <label
                class="mx-lg-3 my-0" for="">{{ this.$t('filter_by_date') }}:</label>
            <Datepicker range circle show-clear-button lang="es" placeholder="Selecciona fecha"
                        v-model="filterDateRange" @input='setDateRange' @reset='restore' :date-format="this.npformatDate" text-format="short"
            ></Datepicker>
        </div>
        <div class="col-md-auto col-12 d-flex p-0 align-items-center">
            <label
                class="mx-lg-3 my-0" for="">{{ this.$t('Period') }}:</label>
            <button :class="this.periodDate=='today'?'btn-dark':'btn-outline-dark'" class="mx-1 btn"
                    @click="setFilterPeriod('today')">
                {{ this.$t('today') }}
            </button>
            <button :class="this.periodDate=='week'?'btn-dark':'btn-outline-dark'" class="mx-1 btn"
                    @click="setFilterPeriod('week')">
                {{ this.$t('week') }}
            </button>
            <button class="btn btn-excel" style="background-color: #1D6F42;color:white;" @click="exportCsv()"><i class="fa fa-file-excel"></i></button>

        </div>
<!--        <div class="col-md-auto col-12">-->
<!--            <button class="btn btn-excel" style="background-color: #1D6F42;color:white;" @click="exportCsv()"><i class="fa fa-file-excel"></i></button>-->
<!--        </div>-->

    </div>
</template>

<script>
import VueDatepickerUi from 'vue-datepicker-ui'
import 'vue-datepicker-ui/lib/vuedatepickerui.css';
import VSelect from "vue-select";

export default {
    name: "SensorFilter",
    props: {
        refresh_fn: {required: true},
        installations: {required: true},
        data: {required: true},
    },

    created() {
        this.getInstallation()
    },
    components: {
        Datepicker: VueDatepickerUi,VSelect
    },

    data() {
        return {
            filterDateRange: [null, null],
            current_installation: null,
            filterDate: null,
            periodDate: 'today',
            current: [null, null],
            npformatDate:{
                day: '2-digit',
                month: '2-digit',
                year: 'numeric' }
        }
    },
    methods: {

        restore() {
            this.$emit('setFilter', {'date_range': []})
        },
        setInstallation(installation) {
            this.current_installation = installation
            this.$emit('setFilter', {installation:installation})
        },
        setFilterPeriod(periodDate) {
            this.periodDate = periodDate
            this.$emit('setFilter', {'date_period': this.periodDate,'date_range': [null,null]})
        },
        setDateRange(dateRange){
            if (!this.filterDateRange.includes(null)) {
                this.filterDateRange[0]=new Date(this.filterDateRange[0]);
                this.filterDateRange[1]=new Date(this.filterDateRange[1]);
                this.$emit('setFilter', {'date_range': this.filterDateRange,'date_period': null})
            }
        },
        getInstallation(){
            this.current_installation = this.installations ? this.installations[0] : null
        },
        exportCsv(){
            let data = this.data;
            axios.post('/api-admin/sensors/exportCsv',data).then((res)=>{
                const blob = new Blob([res], { type: 'text/csv' })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = `sensors_${this.formatDate(new Date()) }.csv`
                link.click()
                URL.revokeObjectURL(link.href)
            })
        },
        formatDate(date) {
            return [
                this.padTo2Digits(date.getDate()),
                this.padTo2Digits(date.getMonth() + 1),
                date.getFullYear(),
            ].join('/');
        },
        padTo2Digits(num) {
            return num.toString().padStart(2, '0');
        },
    },
}
</script>

<style lang="scss">
.calendar.range{
    z-index: 9999;
}
</style>
