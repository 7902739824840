<template>
  <div class="modal fade" :id="id" tabindex="-1" role="dialog" aria-labelledby="ZoneModal"
       aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{
              current !== null ? this.$installations_locale.global.zone + ' ' + current.name :
                  this.$installations_locale.global.new_zone
            }}
          </h5>
          <button @click="hideModal" class="close" type="button" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <zone-form
			:refresh_fn='refresh_fn'
			:installation_id='installation_id'
			:current='current'
			:parent_zone='parent_zone'
		/>
      </div>
    </div>
  </div>
</template>
<script>
import ZoneForm from './ZoneForm'
export default {
  name: 'ZoneModalForm',
  props: {
    id: {required: true},
    installation_id: {required: true},
    current: {default: null},
    refresh_fn: {required: true, type: Function},
    parent_zone: {default: null}
  },
  components: {
	  ZoneForm
  },
  created() {
  },
  watch: {

  },
  data() {
    return {

    }
  },
  methods: {
    hideModal() {
      $(`#${this.id}`).modal('hide')
    }
  }
}
</script>
