<template>
    <card-template>
        <template slot="titleCard">{{ this.$installations_locale.global.installations_list }}</template>
        <div class="table-responsive">
            <inno-core-datatable
                :enableCreateBtn="this.$helpers.hasPermission('installations.edit')"
                :enableEditBtn="this.$helpers.hasPermission('installations.edit')"
                :enableDelBtn="this.$helpers.hasPermission('installations.delete')"
                :idTable="idTable"
                :columnsData="columns"
                :rowItems="items"
                :customFields="customFields"
                :customOptions="customOptions"
                :btnConfig="this.$helpers.hasPermission('installations.edit')"
                @edit="editItem"
                @delete="deleteItem"
                @new="newItem"
                @config="setItem"
            >
            </inno-core-datatable>
        </div>
        <modal-confirm-delete @confirmed="removeItem"/>

    </card-template>
</template>
<script>
export default{
    name: 'InstallationsList',
    created() {
		this.$store.commit('installations/removeCurrent')
        this.getItems()
    },
    data() {
        return {
            idTable: 'installationsTable',
            items: [],
            customOptions:[
                {fn: this.openCreateTask,
                    icon:'tasks',
                    permission:this.$helpers.hasPermission('tasks.edit'),
                    params: ''
                }
            ],
            customFields: [
                 // {field: 'options', content: ''}
            ],
            columns: [
                {
                    field: 'id',
                    label: '#',
                    display: 'min_tabletP',
                    target:'1'
                },
                {
                    field: 'name',
                    label: this.$installations_locale.global.name,
                },
                {
                    field: 'customer_name',
                    label: this.$installations_locale.global.customer,
                },
                {
                    field: 'options',
                    label: this.$installations_locale.global.options
                }
            ]
        }
    },
    computed: {},
    methods: {
        openCreateTask(){
            let installationId=$(event.target).closest('tr').attr('data-id');
            window.open('/tasks/create?i='+installationId,'_blank')
        },
        getItems() {
            axios.get('/api-admin/installations').then(response => {
                const rows = response.data
                this.items = rows
            })
        },
        editItem(item) {
            window.location = `/installations/${item.id}`
        },
        deleteItem(item) {
            this.$bus.$emit('fireModalConfirmDelete', {
                text: `${this.$installations_locale.global.modals.delete_installation} ${item.name} `,
                parameters: item
            })
        },
        removeItem(item) {
            axios.delete(`/api-admin/installations/${item.id}`).then(response => {
                const rows = response.data
                this.items = rows
                this.$toast.success(this.$t('toast.delete_success'))
            })
        },
        newItem() {
            window.location = '/installations/create'
        },
        setItem(item){
			this.$store.commit('installations/setCurrent',item);
            window.location = `/installations/${item.id}/config`
        }
    }

}
</script>
