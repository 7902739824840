<template>
    <card-template>
        <template slot="titleCard">{{ this.$readings_locale.global.readings_list }}</template>
        <div class="table-responsive">
            <reading-table
                :type="'ac'"
                :enableCreateBtn="false"
                :idTable="idTable"
                :columnsData="columns"
                :readingsId="readings_ids"
                :customFilters="customFilters"
                :rowItems="items"
                :customFields="customFields"
                :btnConfig="false"
                @edit="editItem"
                @delete="deleteItem"
            >
            </reading-table>
        </div>
        <modal-confirm-delete @confirmed="removeItem"/>

    </card-template>
</template>
<script>

export default{
    name: 'ReadingAccumulatorList',
    props:['readings_ids'],
    created() {
        this.getReadings()
    },
    data() {
        return {
            idTable: 'readingsTable',
            items: [],
            customFilters: [
                'date_range'
            ],
            customFields: [
            ],
            columns:[
                {
                    "field": "id",
                    "label": "#",
                    "display": "min_tabletP"
                },
                {
                    "field": "reading_date",
                    "label": this.$readings_locale.global.table_columns.reading_date
                },
                {
                    "field": "installation_name",
                    "label": this.$readings_locale.global.table_columns.installation
                },{
                    "field": "item_name",
                    "label": this.$readings_locale.global.table_columns.accumulator
                },

                {
                    "field": "options",
                    "label": this.$readings_locale.global.table_columns.detail
                }
            ]
        }
    },
    computed: {},
    methods: {
        getReadings() {
            let queryParams = '';
            queryParams=`?readings_id=${JSON.stringify(this.readings_ids)}`
            axios.get(`/api-admin/readings/getReadings/ac/${queryParams}`).then(response => {
                const rows = response.data
                this.items = rows
            })
        },
        editItem(item) {
            window.location = `/readings/${item.id}`
        },
        deleteItem(item) {
            this.$bus.$emit('fireModalConfirmDelete', {
                text: `${this.$readings_locale.global.modals.delete_task} ${item.name} `,
                parameters: item
            })
        },
        removeItem(item) {
            axios.delete(`/api-admin/readings/${item.id}`).then(response => {
                const rows = response.data
                this.items = rows
                this.$toast.success(this.$t('toast.delete_success'))
            })
        },
        newItem() {
            window.location = '/readings/create'
        },
        setItem(item){
            window.location = `/readings/${item.id}/config`
        }
    }

}
</script>

