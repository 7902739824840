<template>
    <div class="card shadow mb-4">
        <div class="card-body">
            <h6 v-if="user !== undefined" class="text-right">
                <i>#{{ user.id }}</i>
            </h6>
            <div class="row">
                <div class="col-xs-12 col-sm-6 mb-2">
                    <div class="col-12 mb-2">
                        <label class="required">{{ this.$trans.users.name }}</label>
                        <input v-model="formData.name" type="text" class="form-control">
                    </div>
                    <div class="col-12 mb-2">
                        <label class="required">{{ this.$trans.users.email }}</label>
                        <input v-model="formData.email" type="email" class="form-control">
                    </div>

                    <div v-if="!isAdmin" class="col-12 mb-2">
                        <installations-combo :current="formData.installations" @add="syncInstallation" :multiple="true"
                                             :label="this.$trans.users.assign_installations"/>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-6 mb-2">
                    <div class="col-12 mb-2">
                        <label class="required">{{ this.$trans.users.password }}</label>
                        <input v-model="formData.password" type="password" class="form-control">
                    </div>
                    <div class="col-12 mb-2">
                        <label class="required">{{ this.$trans.users.repeat_password }}</label>
                        <input v-model="formData.password_confirmation" type="password" class="form-control">
                    </div>
                </div>
            </div>

            <hr/>
            <div v-if="!isAdmin" class="row">
                <div class="col-sm-6">
                    <assign-permissions ref="assignPermissions"
                                        :expanded="false" :current="formData.permissions"
                                        @toggled="togglePermission"></assign-permissions>
                </div>
                <div class="col-sm-6">
                    <assign-roles :current="formData.roles" @toggled="toggleRole"></assign-roles>
                </div>
            </div>

        </div>
        <div class="card-footer">
            <button @click="save" class="btn btn-primary">{{ this.$t('buttons.save') }}</button>
        </div>
    </div>
</template>
<script>
import AssignPermissions from '../permissions/AssignPermissions'
import AssignRoles from '../roles/AssignRoles'
import InstallationsCombo from '../../../../../Installations/Resources/assets/components/InstallationsCombo.vue'

export default {
    name: 'UserForm',
    props: {
        user: {required: false}
    },
    components: {
        AssignPermissions,
        AssignRoles,
        InstallationsCombo
    },
    created() {
        if (this.user) this.setUser()
    },
    computed: {
        isAdmin: function () {
            return this.user && this.user.is_admin
        }
    },
    data() {
        return {
            formData: {
                id: null,
                name: null,
                email: null,
                password: null,
                password_confirmation: null,
                permissions: [],
                roles: [],
                installations: []
            }
        }
    },
    methods: {
        setUser() {
            this.formData.id = this.user.id
            this.formData.name = this.user.name
            this.formData.email = this.user.email
            this.formData.permissions = this.user.permissions
            this.formData.roles = this.user.roles
            this.formData.installations = this.user.installations
        },
        save() {
            const parameters = this.formData
            axios.post('/api-admin/admin/users', parameters).then(response => {
                this.$toast.success(this.$t('toast.create_success'))
                if (!this.user)
                    setTimeout(() => {
                        window.location = `/admin/users/${response.data.id}`
                    }, 1000)


            })
        },
        togglePermission(currentSelected) {
            this.formData.permissions = currentSelected
        },
        toggleRole(currentRolesSelected) {
            this.formData.roles = currentRolesSelected
            this.manageRolesPermissions(currentRolesSelected)
        },
        manageRolesPermissions(currentRolesSelected) {
            let totalPermissions = this.$refs.assignPermissions.permissions;
            this.resetPermissions(totalPermissions)
            Object.keys(currentRolesSelected).forEach((index) => {
                let permissionsRole =  currentRolesSelected[index]['permissions']; // permissions of the role selected
                permissionsRole.forEach(permission=>{
                    this.formData.permissions.push(permission);
                })
                permissionsRole.forEach((permissionRole) => {
                    let permissionGroup = totalPermissions[permissionRole.group]
                    this.switchPermissionRole(permissionGroup, permissionRole)
                })
            })
        },
        switchPermissionRole(permissionGroup, permissionRole) {
            const permission = permissionGroup.filter(item => item.name == permissionRole.name)
            if (permission) {
                permission[0].selected = true
            }
        },
        resetPermissions(totalPermissions) {
            Object.keys(totalPermissions).forEach(group => {
                totalPermissions[group].forEach(permission => {
                    permission.selected = false
                })
            })
        },
        syncInstallation(installations) {
            this.formData.installations = installations ? installations : []
        }

    }
}
</script>
