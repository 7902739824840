<template>
    <card-template>
        <div class="row">
            <div class="col-12 col-md-3">
                <div class="mb-3">
                    <label for="name">{{ this.$sensors_locale.global.columns.name }}</label>
                    <input type="text"  v-model="formData.name" id="sensor_name"
                           class="form-control">
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div class="mb-3">
                    <label for="name">{{ this.$sensors_locale.global.columns.code }}</label>
                    <input :disabled="sensor" type="text"  v-model="formData.code" id="sensor_code"
                           class="form-control">
                </div>
            </div>
            <div class="col-12 col-md-2">
                <div class="mb-3">
                    <label for="sensor_last_value">{{ this.$sensors_locale.global.columns.last_value }}</label>
                    <input type="text" v-model="formData.last_value" id="sensor_last_value" class="form-control">
                </div>
            </div>
            <div class="col-12 col-md-2">
                <div class="mb-3">
                    <label for="sensor_type">{{ this.$sensors_locale.global.columns.type }}</label>
                    <input type="text" v-model="formData.type" id="sensor_type" class="form-control">
                </div>
            </div>
            <div class="col-12 col-md-2">
                <div class="mb-3">
                    <label for="sensor_unit">{{ this.$sensors_locale.global.columns.unit }}</label>
                    <input type="text" v-model="formData.unit" id="sensor_unit" class="form-control">
                </div>
            </div>
        </div>

        <hr>
        <button @click="save" class="btn btn-primary">{{ this.$sensors_locale.global.buttons.save }}</button>
        <button-href v-if="sensor" :item_id="this.sensor.id" :item_type="'sensors'" :item_model="'sensor'"></button-href>
    </card-template>
</template>

<script>
import ButtonHref from '../../../../Alarms/Resources/assets/components/ConfigAlarms/ButtonHref'

export default {
    name: "SignalForm",
    components: {
        ButtonHref
    },
    props: {
        sensor: {required: false},
    },
    created() {
        if (this.sensor) {
            this.formData._method = 'put'
            this.editPath = '/' + this.sensor.id
            this.getItem()
        }
    },
    data() {
        return {
            device: {
                code: ''
            },
            editPath: '',
            formData: {
                id: null,
                code: '',
                name: '',
                last_value: '',
                type: '',
                unit: '',
                _method: 'post'
            },
        }
    },
    methods: {
        getItem() {
            this.formData.name = this.sensor.name
            this.formData.code = this.sensor.code
            this.formData.id = this.sensor.id
            this.formData.last_value = this.sensor.last_value
            this.formData.type = this.sensor.type
            this.formData.unit = this.sensor.unit
        },

        save: function () {
            let formData = new FormData()
            Object.keys(this.formData).map(field => {
                formData.append(field, this.formData[field] === null ? '' : this.formData[field])
            })
            axios.post(`/api-admin/sensors${this.editPath}`, formData, {headers: {'content-type': 'multipart/form-data'}}).then(response => {
                this.$helpers.showLoading()
                this.$helpers.toastSuccess()
                setTimeout(() => {
                    window.location = `/sensors/${response.id}/edit`
                }, 1000)
            })
        }
    }
}
</script>

<style scoped>

</style>
