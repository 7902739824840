<template>
    <div class="w-100">
        <apexchart v-if="series.length > 0" :options="chartOptions" :series="series" type="line" height="400"/>

    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
    name: 'SensorChart',
    components: {
        apexchart: VueApexCharts
    },
    props: {
        sensorData: {required: true}
    },
    created() {
        this.generateSeries()
    },
    data() {
        return {
            series: [],
            categories: []
        }
    },
    methods: {
        generateSeries() {
            this.sensorData.map(item => {
                let currentSerie = this.series.find(serie => {
                    if (!this.categories.includes(item.date_xaxis)) this.categories.push(item.date_xaxis)
                    if (serie.name == item.code) {
                        serie.data.push(item.value)

                    }
                    return serie.name == item.code
                })
                if (!this.categories.includes(item.date_xaxis)) this.categories.push(item.date_xaxis)
                if (!currentSerie) {
                    this.series.push({
                        name: item.code,
                        data: [item.value]
                    })
                }

            })
        },
    },
    computed: {
        chartOptions() {
            return {
                chart: {
                    type: 'line',
                    width: "100%",
                    height: 400,

                },
                series: this.chartSeries,
                xaxis: {
                    categories: this.categories,
                    labels: {
                        formatter: function (value, timestamp, opts) {

                            return " " + value
                        },
                    },
                    tickAmount: this.checkMobile? 10: this.categories.length > 48 ? 12 : this.categories.length
                },

                yaxis: {
                    labels: {
                        formatter: function (value) {
                            // Ajustar el número de decimales
                            return value.toFixed(2); // Mostrar 2 decimales
                            // return value.toFixed(3); // Mostrar 3 decimales
                        }
                    }
                },
                responsive: [
                    {
                        breakpoint: 1000,
                        options: {
                            plotOptions: {
                                bar: {
                                    horizontal: false
                                }
                            },
                            legend: {
                                position: "bottom"
                            }
                        }
                    }
                ]
            };
        },

        chartSeries() {
            return [
                {
                    series: this.series,
                    chart: {
                        height: 350,
                        type: 'line',
                        dropShadow: {
                            enabled: true,
                            color: '#000',
                            top: 18,
                            left: 7,
                            blur: 10,
                            opacity: 0.2
                        },
                        zoom: {
                            type: 'x',
                            enabled: true,
                            autoScaleYaxis: true
                        },
                        toolbar: {
                            show: false,
                            autoSelected: 'zoom'

                        },

                    },
                    colors: ['#77B6EA', '#545454'],
                    dataLabels: {
                        enabled: true,
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    title: {
                        text: 'Average High & Low Temperature',
                        align: 'left'
                    },
                    grid: {
                        borderColor: '#e7e7e7',
                        row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                        },
                    },
                    markers: {
                        size: 1
                    },

                    yaxis: {
                        title: {
                            text: 'Values'
                        },
                        min: 5,
                        max: 40
                    },
                    legend: {
                        position: 'top',
                        horizontalAlign: 'right',
                        floating: true,
                        offsetY: -25,
                        offsetX: -5
                    }
                },
            ];
        },
        checkMobile() {
            const userAgent = navigator.userAgent;
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
        }
    }

}
</script>
