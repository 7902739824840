<template>
		<card-template :show_header="false">
				<div class="row">
						<div class="col-6">
								<h4>
										<i class="fa fa-user"></i> {{user.name}}
								</h4>
								<small>{{user.email}}</small>
						</div>
						<div class="col-6 text-right" >
								<QR
										:token="api_token"
										:width="80"
										align="text-right"
								/>
						</div>
				</div>


		</card-template>
</template>
<script>
import QR from '../QR/QR'
export default{
		name: 'UserInfoModule',
    created() {
    },
    props:{
				user: {
						required:true,
						type:Object
				},
				api_token:{
						required: true,
				}
		},
		components: {
				QR
		}
}
</script>
