<template>
	<div>
		<div class=' table-responsive'>
			<div class='mb-3 text-right'>
				<button @click="newItem" class='btn btn-sm btn-success'><i class='fa fa-plus'></i>
					{{ this.$installations_locale.global.new_deposit }}
				</button>
			</div>
			<table class='table'>
				<thead>
				<tr>
					<th :key='column.field' v-for='column in columns'>{{ column.label }}</th>
				</tr>
				</thead>
				<tbody>
				<tr :key='item.id' v-for='item in deposits'>
					<td>{{ item.id }}</td>
					<td>{{ item.code }}</td>
					<td>
						<button @click='editItem(item)' class='btn btn-xs btn-warning'><i class='fa fa-pencil'></i></button>
						<button @click='deleteItem(item)' class='btn btn-xs btn-danger'><i class='fa fa-trash'></i></button>
                        <button @click="showQr(item.code)" class="btn btn-xs btn-dark"><i class="fa fa-qrcode"></i>
                        </button>
                    </td>
				</tr>
				</tbody>
			</table>

		</div>
        <modal-qr id="depositModalQr" :qr="currentQR"></modal-qr>

    </div>

</template>
<script>
import ModalQr from "../../../ModalQR.vue";

export default {
	name: 'Deposits',
    components: {ModalQr},
	props: {
		deposits: {type: Array},
		circuit_id: {required: true},
		installation_id: {required: true},
		refresh_fn: {}
	},
	data() {
		return {
			idTable: 'DepositTable',
            currentQR:null,

            columns: [
				{
					field: 'id',
					label: '#',
					display: 'min_tabletP',
				},
				{
					field: 'code',
					label: this.$installations_locale.global.code,
				},
				{
					field: 'options',
					label: this.$installations_locale.global.options,
				},
			],
		}
	},
	methods: {
		editItem(item) {
			window.location = `/installations/${this.installation_id}/config/circuits/${this.circuit_id}/deposits/${item.id}`
		},
		deleteItem(item) {
			this.$bus.$emit('fireModalConfirmDeleteFn', {
				text: `${this.$installations_locale.global.modals.delete_deposit} ${item.code} `,
				parameters: item,
				fn: this.removeDeposit
			})
		},
		removeDeposit(item) {
			const url = `/api-admin/installations/${this.installation_id}/circuits/${item.circuit_id}/deposits/${item.id}`
			axios.delete(url).then(response => {
				this.$toast.success(this.$t('toast.delete_success'))
				this.refresh_fn(response.data)
			})
		},
		newItem() {
			window.location = `/installations/${this.installation_id}/config/circuits/${this.circuit_id}/deposits`
		},
        showQr(qr){
            this.currentQR = 'cpp'+qr
            $(`#depositModalQr`).modal('show')
        }
	},
}
</script>
