<template>
    <li class="list-group-item bg-gray-200">
        <zone-item  :refresh_fn="refresh_fn" :zone="zone" :installation_id="installation_id" :edit_fn="edit_fn"/>
    </li>
</template>
<script>
import ZoneItem from './ZoneItem'
export default {
    name: 'ZoneWet',
    props: {
        zone: {required: true},
        installation_id: {required: true},
        edit_fn:{},
        refresh_fn:{required:true, type:Function},
    },
    components: {
        ZoneItem
    },
    data() {
        return {

        }
    }
}
</script>
