<template>
    <div class="box-form">
        <div v-for="(structure_key) in Object.keys(getCurrentServiceStructure)" :key="structure_key" class="col-12">
            <label for="" class="required">{{ $t_notifications('channels.columns.' + structure_key) }}</label>
            <input class="form-control mb-2" :data-key="structure_key" @change="setCurrentConfig" type="text"
                   :value="channelCurrentServiceConfig ? channelCurrentServiceConfig[structure_key] : null">
        </div>
    </div>
</template>
<script>
import {mapGetters} from 'vuex'

export default {
    name: "ChannelProvidersForm",
    created() {

    },
    computed: {
        ...mapGetters('notifications', [
            'getCurrentServiceStructure',
            'channelCurrentServiceConfig',
        ])
    },
    methods: {
        setCurrentProviderService(data) {
            const service = data.target.value
            this.$store.commit('notifications/setCurrentProviderService', service)

        },
        setCurrentConfig(e) {
            const key = e.target.getAttribute('data-key')
            const value = e.target.value
            this.$store.commit('notifications/setServiceFieldCurrentConfig', {key, value})
        },

    }
}
</script>
