var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-client-table',{ref:_vm.idTable,attrs:{"data":_vm.items,"columns":_vm.columns,"options":_vm.options},scopedSlots:_vm._u([{key:"ac_icon",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"text-center",domProps:{"innerHTML":_vm._s(row.ac_icon)}})}},{key:"af_icon",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"text-center",domProps:{"innerHTML":_vm._s(row.af_icon)}})}},{key:"mix_icon",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"text-center",domProps:{"innerHTML":_vm._s(row.mix_icon)}})}},{key:"options",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('button',{staticClass:"btn btn-xs btn-warning",on:{"click":function($event){return _vm.editItem(row)}}},[_c('i',{staticClass:"fa fa-pencil"})]),_vm._v(" "),_c('button',{staticClass:"btn btn-xs btn-danger",on:{"click":function($event){return _vm.deleteItem(row)}}},[_c('i',{staticClass:"fa fa-trash"})]),_vm._v(" "),(_vm.btnConfig)?_c('button',{staticClass:"btn btn-xs btn-primary",on:{"click":function($event){return _vm.config(row)}}},[_c('i',{staticClass:"fa fa-cog"})]):_vm._e(),_vm._v(" "),(row.disabled === true)?_c('button',{staticClass:"btn btn-xs btn-info",attrs:{"title":_vm.restore_label},on:{"click":function($event){return _vm.restore(row)}}},[_c('i',{staticClass:"fa fa-sync"})]):_vm._e(),_vm._v(" "),_c('button',{staticClass:"btn btn-xs btn-dark",on:{"click":function($event){return _vm.showQr(row.client_code)}}},[_c('i',{staticClass:"fa fa-qrcode"})])])}}])},[_c('div',{attrs:{"slot":"afterFilter"},slot:"afterFilter"},[_c('checkpoint-filter',{staticClass:"mx-2 my-2",attrs:{"current":_vm.currentFilter,"installation_id":_vm.installation_id,"zone_id":_vm.zone_id,"refresh_fn":_vm.refreshData},on:{"setFilter":_vm.setFilter}})],1),_vm._v(" "),_c('div',{attrs:{"slot":"beforeTable"},slot:"beforeTable"},[_c('div',{staticClass:"text-right"},[_c('button',{staticClass:"btn btn-primary btn-sm mb-2",on:{"click":_vm.newItem}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v("\n                    "+_vm._s(this.$t('buttons.new'))+"\n                ")])])])]),_vm._v(" "),_c('modal-qr',{attrs:{"qr":_vm.currentQR}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }