var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-client-table',{ref:_vm.idTable,attrs:{"data":_vm.items,"columns":_vm.columns,"options":_vm.options},scopedSlots:_vm._u([{key:"link_cert",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"text-center"},[_c('div',{staticClass:"row"},_vm._l((row.images_cert),function(image){return _c('div',{staticClass:"col-2"},[_c('span',{staticClass:"m-2 cursor-pointer badge badge-primary",on:{"click":function($event){return _vm.goShowImageCert(image.image,image.cert_blockchain_hash,image.created_at)}}},[_c('i',{staticClass:"fa fa-image h4 p-0 m-0"})])])}),0)])}},{key:"ac_icon",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"text-center",domProps:{"innerHTML":_vm._s(row.ac_icon)}})}},{key:"af_icon",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"text-center",domProps:{"innerHTML":_vm._s(row.af_icon)}})}},{key:"mix_icon",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"text-center",domProps:{"innerHTML":_vm._s(row.mix_icon)}})}},{key:"options",fn:function(ref){
var row = ref.row;
return _c('div',{},[(_vm.$helpers.hasPermission('tasks.edit'))?_c('button',{staticClass:"btn btn-xs btn-warning",on:{"click":function($event){return _vm.editItem(row)}}},[_c('i',{staticClass:"fa fa-pencil"})]):_vm._e(),_vm._v(" "),(_vm.$helpers.hasPermission('tasks.delete'))?_c('button',{staticClass:"btn btn-xs btn-danger",on:{"click":function($event){return _vm.deleteItem(row)}}},[_c('i',{staticClass:"fa fa-trash"})]):_vm._e(),_vm._v(" "),_c('button',{class:"btn btn-secondary  btn-xs",attrs:{"disabled":!row.process_file_id},on:{"click":function($event){return _vm.downloadProcess(row)}}},[_c('i',{staticClass:"fa fa-download"})]),_vm._v(" "),(_vm.btnConfig)?_c('button',{staticClass:"btn btn-xs btn-primary",on:{"click":function($event){return _vm.config(row)}}},[_c('i',{staticClass:"fa fa-cog"})]):_vm._e(),_vm._v(" "),(row.disabled === true)?_c('button',{staticClass:"btn btn-xs btn-info",attrs:{"title":_vm.restore_label},on:{"click":function($event){return _vm.restore(row)}}},[_c('i',{staticClass:"fa fa-sync"})]):_vm._e()])}},{key:"optionsResponsive",fn:function(ref){
var row = ref.row;
return _c('div',{},[(_vm.$helpers.hasPermission('tasks.edit'))?_c('button',{staticClass:"btn btn-xs btn-warning",on:{"click":function($event){return _vm.editItem(row)}}},[_c('i',{staticClass:"fa fa-pencil"})]):_vm._e(),_vm._v(" "),_c('button',{staticClass:"btn btn-xs btn-danger",on:{"click":function($event){return _vm.deleteItem(row)}}},[_c('i',{staticClass:"fa fa-trash"})]),_vm._v(" "),_c('button',{staticClass:"btn btn-xs btn-primary px-2",on:{"click":function($event){return _vm.showDetail(row)}}},[_c('i',{staticClass:"fa text-white fa-info-circle"})])])}}])},[_c('div',{staticStyle:{"margin-left":"10px","align-items":"center","display":"flex","margin-bottom":"0"},attrs:{"slot":"afterFilter"},slot:"afterFilter"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(this.$tasks_locale.global.table_columns.state)+":")]),_vm._v(" "),_c('task-filter',{staticClass:"my-2",attrs:{"states":_vm.states,"current":_vm.currentFilter,"refresh_fn":_vm.refreshData},on:{"setFilter":_vm.setFilter}})],1),_vm._v(" "),_c('div',{attrs:{"slot":"beforeTable"},slot:"beforeTable"},[_c('div',{staticClass:"text-right"},[(this.$helpers.hasPermission('tasks.edit'))?_c('button',{staticClass:"btn btn-primary btn-sm mb-2",on:{"click":_vm.newItem}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v("\n                    "+_vm._s(this.$tasks_locale.global.buttons.new)+"\n                ")]):_vm._e()])]),_vm._v(" "),_vm._l((_vm.customFields),function(item){return [_c('div',{attrs:{"slot":item.field},domProps:{"innerHTML":_vm._s(item.content)},slot:item.field},[_vm._v(" "+_vm._s(item.content))])]})],2),_vm._v(" "),(_vm.transactionInfo!=null)?_c('transaction-info-modal',{attrs:{"transactionInfo":_vm.transactionInfo,"show":_vm.showModalTransaction},on:{"close":function($event){return _vm.closeModal()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }