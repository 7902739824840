<template>
    <div class="modal fade" :id="id" tabindex="-1" role="dialog" aria-labelledby="NewPermissionModal"
         aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header" style="border:none">
                    <button @click="hideModal" class="close" type="button" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <QR
                        v-if="this.qr"
                        :token="this.qr"
                        :width="300"
                        align="text-center"
                    />
                    <div class="text-center">
                        <small class="w-100 m-auto text-center">{{ qr }}</small>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import QR from "../../../../../resources/js/components/QR/QR.vue";

export default {
    components: {QR},
    props: {qr: {default: ''},id:{default:'modalQr'}},
    name: 'ModalQr',
    data() {

    },
    mounted: function () {
        document.addEventListener("keydown", (e) => {
            if (this.show && e.keyCode == 27) {
                this.close()
            }
        })
    },

    methods: {

        hideModal() {
            $(`#${this.id}`).modal('hide')
        },
    }
}
</script>

<style scoped lang="scss">
* {
    box-sizing: border-box;
}

.footer-modal {
    width: 100%;
    z-index: 10;
    bottom: 10px !important;

    button {
        width: 100%;
    }
}

.icon-close {
    position: absolute;
    right: 0;
    top: 0;
    background-color: black;
    color: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 0%;
    font-size: 20px;
    cursor: pointer;
}

.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    transition: opacity .3s ease;
    overflow-x: auto;
}

.modal-container {
    position: relative;
    width: 75%;
    height: 90%;
    margin: 20px auto;
    padding: 20px 30px 0px 30px !important;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    overflow: auto;
}

.modal-body {
    margin: 20px 0;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */
.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
</style>
