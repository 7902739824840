export const setUsersSelector = (state, payload) => {
    state.users_selector = payload
}

export const setUserEditData = (state, payload) => {
    state.user_notification_data = payload
}
export const setUsersNotifications = (state, payload) => {
    state.users_notifications = payload
}
export const setNotifications = (state, payload) => {
    state.notifications = payload
}
export const currentNotificationDetail = (state, payload) => {
    state.current_notification_detail = payload
}
export const toggleEnabled = (state) => {
    state.config.module_enabled = !state.config.module_enabled
}


export const setModuleConfig = (state, payload) => {
    state.config = payload
}
export const setUnreadNotifications = (state,payload) => {
    state.unread_notifications = payload
}
export const setTotalUnreadCount = (state,payload) => {
    state.total_unread_count = payload
}
export const setReadNotification = (state,payload) => {
    state.notifications.forEach(item => {
        if(item.id==payload){
            item.read = !item.read
        }
    })
    state.unread_notifications = state.unread_notifications.filter(item => item.id !== payload);

}
/**
 * CHANNELS
 */

export const setStatusChannels = (state, payload) => {
    state.channels.status = payload
}



export const setChannelsList = (state, payload) => {
    state.channels.list = payload
}


export const setCurrentProviderService = (state, payload) => {
    state.channels.current_provider_service = payload
    setProviderCurrentConfig(state)
}

export const setProviderFieldCurrentConfig = (state, {key,value}) => {
    const service = state.channels.current_provider_service
    state.channels.current_config[service][key] = value
}

export const setServiceFieldCurrentConfig = (state, {key,value}) => {
    state.channels.current_config[key] = value
}


export const setNewCurrentConfigStructure = (state) => {
    const structure = {}
    const protocol = state.channels.current_protocol
    structure[protocol] = state.channels.email_structure[protocol]
    state.channels.current_config = structure
}

export const setProviderCurrentConfig = (state) => {
    const service = state.channels.current_provider_service
    const structure = {}
    structure[service] = state.channels.current_channel_structure[service]
    state.channels.current_config = structure
}

export const setServiceCurrentConfig = (state) => {
    const structure = state.channels.current_channel_structure
    state.channels.current_config = structure
}

export const setChannelStructure = (state, payload) => {
    state.channels.current_channel_structure = payload
}


export const setCurrentChannel = (state, payload) => {
    state.channels.current_channel = payload
}

export const setCurrentChannelKey = (state, key) => {
    state.channels.current_channel_key = key
}

export const resetChannelsData = (state) => {
    state.channels.current_config = {}
    state.channels.current_provider_service = null
    state.channels.current_channel_structure = {}
}

export const setCurrentConfigOnStructure = (state) => {
    const current_channel = state.channels.current_channel
    const merge = {...state.channels.current_channel_structure, ...current_channel.config}
    state.channels.current_channel_structure = merge
}

export const setManagement = (state,management) => {
    state.management = management
}


