<template>
    <div>
        <card-template>
            <template slot="titleCard">{{ this.$t_notifications('users_management.title_list') }}</template>
            <div class="table-responsive">
                <v-client-table :ref='idTable' :data='getUsersNotificationsData' :columns='columns' :options='options'>
                    <div v-show="true" class="text-right" slot="beforeTable">
                        <button @click="newItem" class="btn btn-primary btn-sm mb-2"><i class="fa fa-plus"></i> {{this.$t('buttons.new')}}</button>
                    </div>
                    <template v-for="(item) in customFields">
                        <div v-html :slot="item.field" v-html="item.content"> {{ item.content }}</div>
                    </template>
                    <div slot='channels' slot-scope='{row}' class="text-left pl-2">
                        <img class="mr-1 " v-for="channel in row.channels" aria-selected="false" data-bs-toggle="tooltip" :title="channel.name" width="20" :src="'/notifications/images/'+channel.key +'.png'" alt="">

                    </div>
                    <div slot='enabled' slot-scope='{row}' class="text-center">
                        <div class="w-100 d-flex justify-content-center">
                            <VueToggles :checkedText="$t_notifications('users_management.enable') "
                                        :uncheckedText="$t_notifications('users_management.disable')"
                                        :checkedBg="'#1cc88a'" :uncheckedBg="'#e74a3b'" class="float-right "
                                        :value="enabledUserState(row.enabled)"
                                        @click="toggleEnableUserNotifications(row.id)"></VueToggles>
                        </div>
                    </div>
                    <div slot='options' slot-scope='{row}' class=" text-center">
                        <button @click='editItem(row)' class='btn btn-xs btn-warning'><i class='fa fa-pencil'></i></button>
                        <button @click='deleteItem(row)' class='btn btn-xs btn-danger'><i class='fa fa-trash'></i></button>
                    </div>
                </v-client-table>
            </div>
        </card-template>
        <modal-confirm-delete-fn />
    </div>
</template>
<script>
import VueToggles from 'vue-toggles/dist/vue-toggles.ssr';
import 'vue-toggles/dist/vue-toggles.ssr.css';
import {mapGetters} from "vuex";
import {getUsersNotificationsData} from "../../store/getters";
export default ({
    name: "UserManagementTable",
    computed: {
        ...mapGetters('notifications', ['getUsersNotificationsData']),

    },
    components:{VueToggles},
    created() {
        this.getUserNotifications()
        this.setColumns()
    },

    data() {
        return {
            columns: [],
            columnsData: [
                {
                    "field": "id",
                    "label": "#",
                    "display": "min_tabletP"
                },
                {
                    "field": "name",
                    "label": this.$t_notifications('users_management.columns.name')
                },
                {
                    "field": "email",
                    "label": this.$t_notifications('users_management.columns.email')
                },
                {
                    "field": "phone",
                    "label": this.$t_notifications('users_management.columns.phone')
                },
                {
                    "field": "user",
                    "label": this.$t_notifications('users_management.columns.user')
                },
                {
                    "field": "enabled",
                    "label": this.$t_notifications('users_management.columns.state')
                },
                {
                    "field": "channels",
                    "label": this.$t_notifications('users_management.columns.channels')
                },
                {
                    "field": "options",
                    "label": this.$t_notifications('users_management.columns.options')
                }
            ],
            currentFilter: [],
            options: {
                rowClassCallback: row => {
                    return row.disabled ? 'disabled-item' : null
                },
                resizableColumns: true,
                perPage: 10,
                perPageValues: [5, 10, 50, 100],
                pagination: {chunk: 5},
                datepickerOptions: {
                    showDropdowns: true,
                    autoUpdateInput: true,
                },
                sortIcon: {
                    base: 'fa float-right',
                    is: 'fa-sort',
                    up: 'fa-sort-up',
                    down: 'fa-sort-down',
                },
                texts: {
                    count: `${this.$t('Showing {from} to {to} of {count} records|{count} records|One record')}`,
                    first: `${this.$t('First')}`,
                    last: `${this.$t('Last')}`,
                    filter: `${this.$t('Filter')}:`,
                    filterPlaceholder: `${this.$t('Search query')}`,
                    limit: `${this.$t('Records')}:`,
                    page: `${this.$t('Page')}:`,
                    noResults: `${this.$t('No matching records')}`,
                    filterBy: `${this.$t('Filter by {column}')}`,
                    loading: `${this.$t('Loading')}...`,
                    defaultOption: `${this.$t('Select {column}')}`,
                    columns: `${this.$t('Columns')}`,
                },
                columnsDisplay: {},
            },
            selectChangeState: '',
            idTable: 'userNotifTable',
            customFilters: [

            ],
            customFields: [
            ],
        }
    },
    methods: {
        async getUserNotifications() {
            await this.$store.dispatch('notifications/getUserNotifications')
        },
        setColumns() {
            const headings = {}
            this.columnsData.map(item => {
                this.columns.push(item.field)
                headings[item.field] = item.label
                if (item.display) {
                    this.options.columnsDisplay[item.field] = item.display
                }
            })
            this.options.headings = headings
        },

        editItem(item) {
            window.location = `/notifications/users/${item.id}/edit`
        },
        deleteItem(item) {
            this.$bus.$emit('fireModalConfirmDeleteFn', {
                text: `${this.$t_notifications('users_management.delete_user') } ${item.name} `,
                parameters: item,
                fn: this.removeItem
            })
        },
       async removeItem(item) {
            await this.$store.dispatch('notifications/deleteUserConfig',item.id).then(res=>{
                this.$toast.success(this.$t('toast.delete_success'))
            })
        },
        newItem() {
            window.location = '/notifications/users/create'
        },
        toggleEnableUserNotifications(userId){
            const itemUser = this.getUsersNotificationsData.find(item=>item.id == userId);
            this.saveEnableUserNotifications(itemUser)
        },
        saveEnableUserNotifications(itemUser){
            this.$swal.fire({
                title: this.$t_notifications('users_management.change_user_enabled') ,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.$t('buttons.submit'),
                cancelButtonText: this.$t('buttons.cancel')
            }).then((result) => {
                if (result.isConfirmed) {
                    itemUser.enabled = !itemUser.enabled
                    this.$store.dispatch('notifications/saveEnableUserNotifications',{id:itemUser.id,enabled:itemUser.enabled}).then(resp=>{
                    }).catch(error=>{
                        itemUser.enabled = !itemUser.enabled
                    })
                }else{
                    itemUser.enabled = !itemUser.enabled
                }
                if(result.isDismissed){
                    itemUser.enabled = !itemUser.enabled
                }
            })
        },
        enabledUserState(state){
            if(state == 1){
                return true
            }
            return false
        }
    },
})
</script>

<style lang="scss">
.VueTables {
    label {
        margin-right: 10px;
    }
}

.VueTables__wrapper {
    max-height: 500px;
    overflow-y: scroll;
}

.VueTables__search-field {
    display: flex;
}

.VueTables__limit-field {
    display: flex;
}

.VueTables__columns-dropdown-wrapper {
    margin-right: 10px;
}

.VueTables__row {
    td {
        padding: 3px 5px;
        vertical-align: middle;
    }
}

.VueTables__columns-dropdown {
    input {
        margin-right: 3px;
    }
}

</style>
