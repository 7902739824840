<template>
    <h2>Hello Vue Example</h2>
</template>
<script>
export default{
    name:'EventsExample',
    props:{},
    created(){},
    data(){
        return{

        }
    },
    methods:{

    }
}
</script>
