<template>
    <card-template>
        <template slot="titleCard">{{ this.$readings_locale.global.readings_list }}</template>
        <div class="table-responsive">
            <inno-core-datatable
                :enableCreateBtn="false"
                :idTable="idTable"
                :columnsData="columns"
                :rowItems="items"
                :customFields="customFields"
                :btnConfig="false"
                @edit="editItem"
                @delete="deleteItem"
            >
            </inno-core-datatable>
        </div>
        <modal-confirm-delete @confirmed="removeItem"/>

    </card-template>
</template>
<script>

export default{
    name: 'ReadingList',
    props:['type'],
    created() {
    },
    mounted() {


        this.getReadings()
    },
    data() {
        return {
            idTable: 'readingsTable',
            items: [],
            customFields: [
                // {
                //     field: 'state',
                //     content: '<span class="badge badge-primary">ASDA</span>"'
                // }
            ],
            columns:[
                {
                    "field": "id",
                    "label": "#",
                    "display": "min_tabletP"
                },
                {
                    "field": "type",
                    "label": this.$readings_locale.global.table_columns.type
                },
                {
                    "field": "reading_date",
                    "label": this.$readings_locale.global.table_columns.reading_date
                },
                {
                    "field": "installation_name",
                    "label": this.$readings_locale.global.table_columns.installation
                },{
                    "field": "item_name",
                    "label": this.$readings_locale.global.table_columns.accumulator
                },
                {
                    "field": "purge",
                    "label": this.$readings_locale.global.table_columns.purge
                },
                {
                    "field": "ac_temperature",
                    "label": this.$readings_locale.global.table_columns.temperature_ac
                },
                {
                    "field": "user.name",
                    "label": this.$readings_locale.global.table_columns.operator
                }
            ]
        }
    },
    computed: {},
    methods: {
        getReadings() {
            axios.get('/api-admin/readings/getReadings/'+this.type).then(response => {
                const rows = response.data
                this.items = rows
            })
            // let rows = [
            //     {'id':1,'valor_min':40,'valor_max':60,'reading':'Cloro','zona':'Habitacion','checkpoint':'Ducha'},
            //     {'id':2,'valor_min':40,'valor_max':60,'reading':'Temperatura','zona':'Habitacion','checkpoint':'Ducha'},
            //     {'id':3,'valor_min':40,'valor_max':60,'reading':'Cloro','zona':'Baño','checkpoint':'Grifo'},
            //     {'id':4,'valor_min':40,'valor_max':60,'reading':'Temperatura','zona':'Baño','checkpoint':'Grifo'}]
            this.items = rows
        },
        editItem(item) {
            window.location = `/readings/${item.id}`
        },
        deleteItem(item) {
            this.$bus.$emit('fireModalConfirmDelete', {
                text: `${this.$readings_locale.global.modals.delete_task} ${item.name} `,
                parameters: item
            })
        },
        removeItem(item) {
            axios.delete(`/api-admin/readings/${item.id}`).then(response => {
                const rows = response.data
                this.items = rows
                this.$toast.success(this.$t('toast.delete_success'))
            })
        },
        newItem() {
            window.location = '/readings/create'
        },
        setItem(item){
            window.location = `/readings/${item.id}/config`
        }
    }

}
</script>
