<template>
    <div>
        <label v-if="label" :class="required ? 'required' : null">{{ this.$installations_locale.global.circuits }}</label>
        <v-select
            :placeholder="this.$installations_locale.global.circuits"
            :options="circuits"
            v-model="current_circuit"
            label="code"
            :multiple="multiple"
            code="code"></v-select>
    </div>

</template>
<script>
import vSelect from 'vue-select'
import "vue-select/dist/vue-select.css"
export default {
    name: 'CircuitCombo',
    props: {
        installation_id: {required:true},
        required: {default: false},
        current: {default: null},
        label: {default: true},
        multiple: {default: false}
    },
    components: {
        vSelect
    },
    created() {
        this.getData()
		if(this.current){
			this.current_circuit = [...this.current]
		}
    },
    watch: {
        current_circuit: function () {
            this.emitSelected()
        },
        current: function (value) {
                this.current_circuit = value ? value : null
        }
    },
    data() {
        return {
            circuits: [],
            current_circuit: null,
        }
    },
    methods: {
        getData() {
            const url = `/api-admin/installations/${this.installation_id}/circuits/combo`
            axios.get(url).then(response => {
                this.circuits = response.data
            })
        },
        emitSelected() {
            this.$emit('selected', this.current_circuit)
        }
    }
}
</script>
