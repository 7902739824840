export default {
    module_name: 'Notifications',
    users_selector: [],
    user_notification_data: [],
    users_notifications: [],
    notifications: [],
    user_notifications:[],
    current_notification_detail: {
        subject:'',
        message:'',
        users:[],
        date:''
    },
    config:{
        module_enabled: true,
        from_email: null,
        from_name: null,
        no_reply_email: null
    },
    channels:{
        providers:['email','sms','whatsapp'],
        services:['microsoft_teams'],
        list:[],
        status:{},
        current_channel_key: null,
        current_channel: {},
        current_config : {},
        current_provider_service: null,
        current_channel_structure:{}
    },
    total_unread_count:0,
    unread_notifications:[],
    management:false
}
