<template>
		<div class="modal fade" :id="id" tabindex="-1" role="dialog" aria-labelledby="NewPermissionModal"
				 aria-hidden="true">
				<div class="modal-dialog" role="document">
						<div class="modal-content">
								<div class="modal-header">
										<h5 class="modal-title">
												{{
														current !== null ? this.$installations_locale.global.circuit + ' ' + current.code : this.$installations_locale.global.modals.new_circuit
												}}
										</h5>
										<button @click="hideModal" class="close" type="button" data-dismiss="modal" aria-label="Close">
												<span aria-hidden="true">×</span>
										</button>
								</div>
								<form @submit="saveData">
										<div class="modal-body">
												<div class="mb-2">
														<label class="required">{{ this.$installations_locale.global.code }}</label>
														<input type="text" v-model="item.code" class="form-control">
												</div>
												<div class="mb-2">
														<label class="required">{{ this.$installations_locale.global.type }}</label>
														<select v-model="item.type" name="type" class="form-control">
																<option value=""></option>
																<option value="ac">{{ this.$installations_locale.global.circuit_hot }}</option>
																<option value="af">{{ this.$installations_locale.global.circuit_cold }}</option>
														</select>
												</div>
												<div class="mb-2">
														<label>{{ this.$installations_locale.global.description }}</label>
														<textarea class="form-control" v-model="item.description" name="description"></textarea>
												</div>
												<div class="mb-2">
														<div class="form-check">
																<input v-model="item.recirculation" type="checkbox" class="form-check-input" :id="'recirculation_'+circuit_type">
																<label class="form-check-label" :for="'recirculation_'+circuit_type">{{
																				this.$installations_locale.global.recirculation
																		}}</label>
														</div>
												</div>

												<div v-if="item.type === 'af'" class="mb-2">
														<div class="form-check">
																<input v-model="item.direct" type="checkbox" class="form-check-input" :id="'direct_'+circuit_type">
																<label class="form-check-label" :for="'direct_'+circuit_type">{{
																				this.$installations_locale.global.direct
																		}}</label>
														</div>
												</div>
										</div>

										<div class="modal-footer">
												<button @click="hideModal" class="btn btn-dark" type="button" data-dismiss="modal">
														{{ this.$installations_locale.global.buttons.cancel }}
												</button>
												<button type="submit" class="btn btn-primary">{{
																this.$installations_locale.global.buttons.save
														}}
												</button>
										</div>
								</form>
						</div>
				</div>
		</div>
</template>
<script>

export default {
		name: "CircuitModal",
		props: ['id', 'current', 'installation_id', 'circuit_type'],
		components: {},
		created() {
				this.resetItem()
		},
		watch: {
				current: function (newItem) {
						this.item = {...newItem}
						if (!newItem) this.resetItem()
				}
		},
		data() {
				return {
						item: {},
				}
		},
		methods: {
				resetItem() {
						this.item = {
								code: null,
								description: null,
								type: null,
								recirculation: false,
								direct: null
						}
				},

				hideModal() {
						$(`#${this.id}`).modal('hide')
				},
				saveData(e) {
						e.preventDefault()
						const data = {...this.item}
						if (data.code.includes(' ')) {
								return this.$toast.error('El código solo puede contener letras, números,-,_.  sin acentos ni espacios')
						}
						data.code = this.$helpers.sanitizeCode(data.code)
						data.installation_id = this.installation_id
						axios.post(`/api-admin/installations/${this.installation_id}/circuits`, data).then(response => {
								this.hideModal()
								this.$helpers.toastSuccess()
								this.$emit('saved', response.data)
						})
				}
		}
}
</script>
