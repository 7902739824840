<template>
	<div class='modal fade' :id='id' tabindex='-1' role='dialog' aria-labelledby='CheckpointModal'
		 aria-hidden='true'>
		<div class='modal-dialog' role='document'>
			<div class='modal-content'>
				<div class='modal-header'>
					<h5 v-if='checkpoint' class='modal-title'>

					</h5>
					<button @click='hideModal' class='close' type='button' data-dismiss='modal' aria-label='Close'>
						<span aria-hidden='true'>×</span>
					</button>
				</div>
				<div class='modal-body'>
					<CheckpointForm
						:zone_id='zone_id'
						:installation_id='installation_id'
						:current='current'
					/>
				</div>

			</div>
		</div>
	</div>
</template>
<script>
import CheckpointForm from './CheckpointForm'

export default {
	name: 'CheckpointsModal',
	props: {
		id: {required: true, type: String},
		current: {},
		installation_id: {required: true},
		zone_id: {required: true},
	},
	components: {
		CheckpointForm,
	},
	data() {
		return {
			checkpoint: null,
			current_checkpoint_type: null,
			current_checkpoint_cat: null,
			formData: {},
		}
	},
	methods: {
		hideModal() {
			$(`#${this.id}`).modal('hide')
		},
	},
}
</script>