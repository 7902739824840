<template>
    <transition name="modal">
        <div class="modal-mask" v-show="show">
            <div class="modal-container" >
                <slot></slot>
                <div @click.stop="close" class="icon-close">
                    X
                </div>
                <div class="footer-buttons">
                    <button type="button" class="btn btn-success">Aceptar</button>
                </div>
            </div>
        </div>

    </transition>
</template>

<script>
export default {
    props: ['show'],
    name: 'Modal',
    mounted: function () {
        document.addEventListener("keydown", (e) => {
            if (this.show && e.keyCode == 27) {
                this.close()
            }
        })
    },
    methods: {
        close: function() {
            this.$emit('close')
        }
    }
}
</script>

<style lang="scss">
* {
    box-sizing: border-box;
}
.footer-buttons{
    width: 100%;
    position: relative;
    bottom: 0;
    button{
        width: 100%;
    }
}
.icon-close{
    position:absolute;
    right: 0;
    top: 0;
    background-color: black;
    color:white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 0%;
    font-size: 20px;
    cursor:pointer;
}
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    transition: opacity .3s ease;
    overflow-x: auto;
}
.modal-container {
    position:relative;
    width: 75%;
    height: 90%;
    margin: 20px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    overflow: auto;
}
.modal-body {
    margin: 20px 0;
}
/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */
.modal-enter {
    opacity: 0;
}
.modal-leave-active {
    opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
</style>
