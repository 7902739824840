<template>
    <div class="col-4 mb-3">
        <label for="proc_task" class=" w-100">{{
                this.$tasks_locale.global.buttons.process_task
            }} </label>
        <div class="row">
            <v-select class="col-8" @option:selected="add" @option:deselected="add" label="name"
                      :reduce="(file) => file.id"
                      v-model="process_file" :options="files"
                      :settings="{tags:true }"/>
            <button class="btn col-2 btn-primary btn-sm" @click="editProcessFile()">
                <i class="fa fa-pencil"></i>
            </button>
            <button class="btn col-2 btn-info btn-sm" @click="viewProcessFilesList()">
                <i class="fa fa-list"></i>
            </button>
        </div>

    </div>
</template>

<script>
import VSelect from 'vue-select'

export default {
    name: "TaskProcessFiles",
    data() {
        return {
            process_file: null,
            files: []
        }
    },
    props: {
        current_process_file: {default: null}
    },
    components: {
        VSelect
    },
    created() {
        this.getFiles();
    },
    methods: {
        add(fileSelected) {
            this.process_file = fileSelected
            this.$emit('fileSelected', fileSelected.uid)
        },
        editProcessFile() {
            let aumentaWebUrl = this.$aumenta_locale.config.webapp_aumenta_url;
            let processFileURL = this.$aumenta_locale.config.webapp_aumenta_procedure_edit;
            window.open(aumentaWebUrl + processFileURL + this.process_file.uid, '_blank')
        },
        viewProcessFilesList() {
            let aumentaWebUrl = this.$aumenta_locale.config.webapp_aumenta_url;
            let processFileListURL = this.$aumenta_locale.config.webapp_aumenta_procedure;
            window.open(aumentaWebUrl + processFileListURL, '_blank')
        },
        getFiles(){
            if (this.$helpers.hasPermission('aumenta.granted'))
                 axios.get('/api-admin/aumenta/process-files').then(res => {
                    this.files = res.data
                     this.process_file = this.files.find(file => file.uid == this.current_process_file)
                })
        },
    }
}
</script>

<style scoped>

</style>
