<script>
import Modal from "./Modal.vue";

export default {
    name: "TransactionInfoModal",
    components: {Modal},
    methods: {
        close: function () {
            $('body').css('overflow', 'visible');
            this.$emit('close')
        },
        openImageLink() {
            window.open(this.transactionInfo.image, '_blank')
        }
    },
    created() {
        console.log(this.transactionInfo)
    },
    props: ['show', 'transactionInfo']
}
</script>
<template>
    <div>
        <modal :show="show" :header-title=" this.$tasks_locale.global.certification_info_title " @close="close"
               :show-icon-close="true" :show-cancel-button="false" :show-accept-button="false"
               :show-close-button="true">
            <div class="base col-lg-12 col-12 mb-2 modal-body">
                <ul class="nav nav-pills my-3 row  " id="certification-tabs" role="tablist">
                    <li class="nav-item col-6">
                        <a class="nav-link active nav-link-info border-primary border-bottom"
                           id="certification-image-tab" data-toggle="pill" :href="'#tab-image'" role="tab"
                           :aria-controls="'tab-image'" aria-selected="false">
                            {{ this.$tasks_locale.global.certification_image }}
                        </a>
                    </li>
                    <li class="nav-item col-6">
                        <a class="nav-link  nav-link-info border-primary border-bottom" id="transaction-details-tab"
                           data-toggle="pill" :href="'#tab-transaction'" role="tab" :aria-controls="'tab-transaction'"
                           aria-selected="false">
                            {{ this.$tasks_locale.global.transaction_details }}
                        </a>
                    </li>
                </ul>
                <div class="tab-content" id="certification-tabs-content">
                    <div class='tab-pane show fade  active' id='tab-image' role='tabpanel' aria-labelledby='tab-image'>
                        <button class="btn btn-dark position-absolute" @click="openImageLink"><i
                            class="fa fa-external-link"></i></button>
                        <img width="100%" :src="transactionInfo.image" alt="">
                    </div>
                    <div class='tab-pane  fade  ' id='tab-transaction' role='tabpanel'
                         aria-labelledby='tab-transaction'>
                        <table class="table table-bordered shadow">
                            <tbody>
                            <tr>
                                <td style="font-weight:bold;width:150px">Sender RS:</td>
                                <td style="width:70%;word-break:break-all"> {{ this.transactionInfo.senderRS }}
                                </td>
                            </tr>
                            <tr>
                                <td style="font-weight:bold;width:150px">Attachment data:</td>
                                <td style="width:70%;word-break:break-all">{{ this.transactionInfo.attachment.data }}</td>
                            </tr>
                            <tr>
                                <td style="font-weight:bold;width:150px">Full Hash:</td>
                                <td style="width:70%;word-break:break-all">
                                    {{ this.transactionInfo.fullHash }}
                                </td>
                            </tr>
                            <tr>
                                <td style="font-weight:bold;width:150px">Block:</td>
                                <td style="width:70%;word-break:break-all">{{ this.transactionInfo.block }}</td>
                            </tr>
                            <tr>
                                <td style="font-weight:bold;width:150px">Block Generation Time:</td>
                                <td style="width:70%;word-break:break-all">{{ this.transactionInfo.date_image_cert }}</td>
                            </tr>

                            </tbody>
                        </table>
                        <table class="table table-bordered  mt-2 shadow">
                            <tbody>
                            <tr>
                                <td style="font-weight:bold;width:150px">Channel:</td>
                                <td style="width:70%;word-break:break-all">{{ this.transactionInfo.attachment.channel }}</td>
                            </tr>

                            <tr>
                                <td style="font-weight:bold;width:150px">Block Timestamp:</td>
                                <td style="width:70%;word-break:break-all">{{ this.transactionInfo.blockTimestamp }}</td>
                            </tr>
                            <tr>
                                <td style="font-weight:bold;width:150px">Chain:</td>
                                <td style="width:70%;word-break:break-all">{{ this.transactionInfo.chain }}</td>
                            </tr>
                            <tr>
                                <td style="font-weight:bold;width:150px">Confirmations:</td>
                                <td style="width:70%;word-break:break-all">{{ this.transactionInfo.confirmations }}</td>
                            </tr>
                            <tr>
                                <td style="font-weight:bold;width:150px">Deadline:</td>
                                <td style="width:70%;word-break:break-all">{{ this.transactionInfo.deadline }}</td>
                            </tr>
                            <tr>
                                <td style="font-weight:bold;width:150px">EC Block Height:</td>
                                <td style="width:70%;word-break:break-all">{{ this.transactionInfo.ecBlockHeight }}</td>
                            </tr>
                            <tr>
                                <td style="font-weight:bold;width:150px">EC Block Id:</td>
                                <td style="width:70%;word-break:break-all">{{ this.transactionInfo.ecBlockId }}</td>
                            </tr>

                            <tr>
                                <td style="font-weight:bold;width:150px">Parent Transaction:</td>
                                <td style="width:70%;word-break:break-all"> {{ this.transactionInfo.fxtTransaction }}
                                </td>
                            </tr>
                            <tr>
                                <td style="font-weight:bold;width:150px">Height:</td>
                                <td style="width:70%;word-break:break-all"> {{ this.transactionInfo.height }}</td>
                            </tr>
                            <tr>
                                <td style="font-weight:bold;width:150px">Phased:</td>
                                <td style="width:70%;word-break:break-all"> {{ this.transactionInfo.phased }}</td>
                            </tr>
                            <tr>
                                <td style="font-weight:bold;width:150px">Sender:</td>
                                <td style="width:70%;word-break:break-all"> {{ this.transactionInfo.sender }}
                                </td>
                            </tr>
                            <tr>
                                <td style="font-weight:bold;width:150px">Sender Public Key:</td>
                                <td style="width:70%;word-break:break-all">
                                    {{ this.transactionInfo.senderPublicKey }}
                                </td>
                            </tr>

                            <tr>
                                <td style="font-weight:bold;width:150px">Signature:</td>
                                <td style="width:70%;word-break:break-all">
                                    {{ this.transactionInfo.signature }}
                                </td>
                            </tr>
                            <tr>
                                <td style="font-weight:bold;width:150px">Signature Hash:</td>
                                <td style="width:70%;word-break:break-all">
                                    {{ this.transactionInfo.signatureHash }}
                                </td>
                            </tr>
                            <tr>
                                <td style="font-weight:bold;width:150px">Subtype:</td>
                                <td style="width:70%;word-break:break-all">{{ this.transactionInfo.subtype }}</td>
                            </tr>
                            <tr>
                                <td style="font-weight:bold;width:150px">Timestamp:</td>
                                <td style="width:70%;word-break:break-all">{{ this.transactionInfo.timestamp }}</td>
                            </tr>
                            <tr>
                                <td style="font-weight:bold;width:150px">Transaction Index:</td>
                                <td style="width:70%;word-break:break-all">{{ this.transactionInfo.transactionIndex }}</td>
                            </tr>

                            <tr>
                                <td style="font-weight:bold;width:150px">Type:</td>
                                <td style="width:70%;word-break:break-all">{{ this.transactionInfo.type }}</td>
                            </tr>
                            <tr>
                                <td style="font-weight:bold;width:150px">Version:</td>
                                <td style="width:70%;word-break:break-all">{{ this.transactionInfo.version }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<style scoped lang="scss">

</style>
