<template>
    <div class="  mb-4 row ">
        <div class="row  m-0  align-items-center w-100 justify-content-between">
            <div class="col-md-4 col-12 d-flex align-items-center p-0">
                <label
                    class="mx-lg-3 my-0" for="">{{ this.$t('Installation') }}:</label>
                <v-select class="w-100" v-model="current_installation" @input='setInstallation' :options="installations"
                          label="name"
                          :settings="{width:'100%' }">

                </v-select>
            </div>
            <div class="col-md-auto col-12 d-flex align-items-center p-0 mt-1">
                                <label
                                    class="mx-lg-3 my-0" for="">{{ this.$t('filter_by_date') }}:</label>
                <Datepicker range circle show-clear-button lang="es" placeholder="Selecciona fecha"
                            v-model="filterDateRange" @reset='restore' :date-format="this.npformatDate" text-format="short"
                ></Datepicker>
            </div>
            <div class="col-md-auto col-12 align-items-center p-0 mt-1">
                <label
                    class="mx-lg-3 my-0" for="">{{ this.$t('Period') }}:</label>
                <button :class="this.periodDate=='today'?'btn-dark':'btn-outline-dark'" class="mx-1 btn"
                        @click="setFilterPeriod('today')">
                    {{ this.$t('today') }}
                </button>
                <button :class="this.periodDate=='week'?'btn-dark':'btn-outline-dark'" class="mx-1 btn"
                        @click="setFilterPeriod('week')">
                    {{ this.$t('week') }}
                </button>
            </div>
        </div>

        <card-template class="col-12 col-md-9 mt-3 sensor-chart-card">
            <template slot="titleCard">{{ this.$sensors_locale.global['sensor-history'].chart }}</template>
            <sensor-chart v-if="items.length > 0" :sensorData="items"></sensor-chart>
            <div v-else>{{ this.$t('No matching records') }}</div>
        </card-template>
        <card-template class="col-12 col-md-3 mt-3">
            <template slot="titleCard">{{ this.$sensors_locale.global['sensors'].nav }}</template>
            <sensor-dashboard ></sensor-dashboard>
        </card-template>
    </div>
</template>
<script>
import SensorHistoryTable from "../../../../Modules/Sensors/Resources/assets/components/SensorHistoryTable.vue";
import SensorChart from "../../../../Modules/Sensors/Resources/assets/components/SensorChart.vue";
import SensorFilter from "../../../../Modules/Sensors/Resources/assets/components/SensorFilter.vue";
import VSelect from "vue-select";
import VueDatepickerUi from 'vue-datepicker-ui'
import 'vue-datepicker-ui/lib/vuedatepickerui.css';
import SensorDashboard from "./SensorDashboard.vue";
export default {
    name: "Dashboard",
    components: {
        SensorDashboard, VSelect, SensorFilter, SensorChart, SensorHistoryTable,        Datepicker: VueDatepickerUi,
    },
    created() {
        this.getInstallations()
    },
    watch: {
        current_installation() {
            this.getSensorHistory()
        },
        filterDateRange(){
            this.getData()
        }
    },
    props: {
        installations: {required: true}
    },
    data() {
        return {
            idTable: 'sensorHistoryTable',
            items: [],
            current_installation: null,
            filterDate: null,
            customFilters: [],
            periodDate: 'today',
            customFields: [],
            filterDateRange: [null, null],
            current: [null, null],
            npformatDate:{
                day: '2-digit',
                month: '2-digit',
                year: 'numeric' },

            columns: [
                {
                    field: 'name',
                    label: this.$sensors_locale.global.columns.name,
                    display: 'min_tabletP',

                },
                {
                    field: 'code',
                    label: this.$sensors_locale.global.columns.code,
                    display: 'min_tabletP',

                },
                {
                    field: 'value',
                    label: this.$sensors_locale.global.columns.last_value
                },
                {
                    field: 'created_at',
                    label: this.$sensors_locale.global.columns.date,
                    display: 'min_tabletP',
                }
            ]
        }
    },
    computed: {},
    methods: {

        getData() {
            if (!this.filterDateRange.includes(null)) {
                this.filterDateRange[0]=new Date(this.filterDateRange[0]).toISOString().slice(0, 10);
                this.filterDateRange[1]=new Date(this.filterDateRange[1]).toISOString().slice(0, 10);
               this.setFilterDate(this.filterDateRange)
            }
        },
        restore() {
            this.$emit('setFilter', {'date_range': []})
        },
        setInstallation(installation) {
            this.current_installation = installation
        },
        setFilterPeriod(periodDate) {
            this.periodDate = periodDate
            this.getSensorHistory()
        },
        setFilterDate(date) {
            this.periodDate = null
            this.filterDate = date
            this.getSensorHistory()
        },
        async getInstallations() {
            this.current_installation = this.installations ? this.installations[0] : null

        },
        getSensorHistory() {
            this.items = []
            if (this.current_installation) {
                axios.post('/api-admin/sensors/history', {
                    date_period: this.periodDate,
                    date_range: this.filterDate,
                    installation: this.current_installation
                }).then(response => {
                    const rows = response.data
                    this.items = rows
                })
            }

        },

    },


}
</script>
<style lang="scss">

.table-responive {
    height: 60vh !important;
}
.sensor-chart-card{
    .card-body{
        padding:0 !important;
        .apexcharts-canvas{
            width: 100% !important;
        }
    }
}
</style>
