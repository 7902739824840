<template>
    <card-template>
        <template slot="titleCard">{{ this.$alarms_locale.global.alarm_item_config }} {{ this.$t('alarm_item') }}:
            <i >{{ this.item.code }}</i>
        </template>
        <div class='row'>
            <div class='col-12  my-3'>
                <ConfigAlarmForm :setted="item.code" @save='appendAlarmConfig'/>
            </div>
            <div class='col-12 col-xl-4 mb-2 mb-3'>
                <span></span>
                <ul class='list-group'>
                    <li v-for='(config,i) in currentAlarmConfig' :key='config.id'
                        class='list-group-item d-flex justify-content-between align-items-start'>
                        <div class='ms-2 me-auto'>
                            <div class='fw-bold'>{{item.code }}</div>
                            <span class='badge bg-primary rounded-pill'>{{ config.evaluate }}</span>
                        </div>
                        <div class='consign-actions'>
                            <i @click='remove(i)' class='fa fa-trash fa-xs text-danger mt-6 cursor-pointer'></i>
                        </div>
                    </li>
                </ul>
            </div>
            <slot></slot>
        </div>
    </card-template>
</template>

<script>
import ConfigAlarmForm from './ConfigAlarmForm'

export default {
    name: "ConfigAlarmComponent",
    props: {
        item: {required: false},
        item_alarm_config: {required: false}
    },
    components:{
        ConfigAlarmForm
    },
    created() {
        if (this.item_alarm_config) {
            this.currentAlarmConfig = this.item_alarm_config
        }
    },
    watch: {
        item_alarm_config: function() {
            this.currentAlarmConfig = this.item_alarm_config
        },
    },
    data() {
        return {
            alarmConfig: {
                attribute: null,
                evaluate: '',
            },
            currentAlarmConfig: []
        }
    },
    methods: {

        remove(index) {
            const position = this.currentAlarmConfig.findIndex((item, i) => i === index)
            this.currentAlarmConfig.splice(position, 1)
            this.$emit('setAlarmConfig', this.currentAlarmConfig)
        },
        appendAlarmConfig(data) {
            this.currentAlarmConfig = [data]
            this.$emit('setAlarmConfig', this.currentAlarmConfig)
        },
    },
}
</script>

<style scoped lang='scss'>
.consign-actions {
    position: absolute;
    right: 10px;
    bottom: 5px;
}
</style>
