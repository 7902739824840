<template>
    <div class="row justify-around">
        <div class="col-lg-12">
            <FullCalendar
                :options="calendarOptions"
                ref="calendar"
            ></FullCalendar>
        </div>
    </div>
</template>

<script>

import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import dayListPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import esLocale from '@fullcalendar/core/locales/es'

export default {
    name: 'TaskCalendar',
    components: {
        FullCalendar,
    },
    created() {
        this.getItems()
    },
    computed: {},
    data() {
        return {
            selectedDate: '',
            showTask: false,
            calendarOptions: {
                plugins: [dayGridPlugin, interactionPlugin, dayListPlugin],
                height: 'auto',
                headerToolbar: {
                    start: 'title',
                    center: 'dayGridMonth,dayGridWeek,today',
                    end: 'prev,next'
                },
                locale: esLocale,
                initialView: 'dayGridMonth',
                dateClick: this.onClickDay,
                eventClick: this.eventClick,
                eventMinHeight: '100px',
                events: [],
                eventTextColor: '#000000'
            }
        }
    },
    methods: {
        getItems: function (items) {
            return axios.get('/api-admin/tasks/getTasks/calendar').then(res => {
                this.calendarOptions.events = res.data.map(item => {
                    return {
                        id: item.id,
                        title: item.name,
                        start: item.date,
                        end: item.expiration_date,
                        // date: item.date,
                        task: item.task,
                        backgroundColor: this.eventBackColor(item),
                        classNames: ['q-px-sm']
                    }
                })
                return res
            })
        },
        eventClick(clickInfo) {

        },

        onClickDay(date) {
            this.$refs.calendar.calendar.gotoDate(date.dateStr)
            this.$refs.calendar.calendar.changeView('listDay')
        },

        eventBackColor: function (item) {
            if (item.state_id == 2) {
                return '#27d96d'
            } else if (item.state_id.type_id === 1 || item.state_id == 3) {
                return 'rgba(254,209,60,0.77)'
            }
            return '#1976d2'
        }

    }
}
</script>
<style>
.fc .fc-toolbar-title {
    font-size: 1em;
    line-height: 2rem;
}

.fc-toolbar-chunk {
    padding: 0 10px !important;
}

.fc-event {
    padding-top: 5px;
    min-height: 30px;
}
</style>
