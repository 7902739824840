<template>
    <card-template :show-header="false">

        <div class="row mb-4">
            <div class="col-12 col-sm-2 mb-2">
                <label class="mb-2" for="">{{ this.$t_notifications('module_enabled') }}</label>
                <VueToggles checkedText="ON"
                            uncheckedText="OFF"
                            v-model="config.module_enabled"
                            @click="toggleEnabled"></VueToggles>
            </div>
            <div class="col-12 col-sm-10 pt-4 mb-2">
                <button v-show="hasEmailChannelEnabled" @click="testEmail" class="btn btn-dark mb-1"><i class="mr-2 fa fa-envelope"></i> {{this.$t_notifications('buttons.test_email')}}</button>
                <button v-show="hasTeamsChannelEnabled" @click="testTeams" class="btn btn-dark mb-1"><i class="mr-2 fa fa-users"></i> {{this.$t_notifications('buttons.test_teams')}}</button>
                <button v-show="hasWhatsappChannelEnabled" @click="testWhatsapp" class="btn btn-dark mb-1"><i class="mr-2 fa fa-mobile"></i> {{this.$t_notifications('buttons.test_whatsapp')}}</button>
                <button v-show="hasSMSChannelEnabled" @click="testSms" class="btn btn-dark mb-1"><i class="mr-2 fa fa-phone"></i> {{this.$t_notifications('buttons.test_sms')}}</button>
            </div>
        </div>
        <div v-if="hasEmailChannelEnabled" class="row">
            <div class="col-12 col-sm-4 mb-2">
                <label for="" class="required">{{ this.$t_notifications('from_email') }}</label>
                <input type="email" class="form-control" v-model="config.from_email">
            </div>
            <div class="col-12 col-sm-4 mb-2">
                <label for="" class="required">{{ this.$t_notifications('from_name') }}</label>
                <input type="text" class="form-control"  v-model="config.from_name">
            </div>
            <div class="col-12 col-sm-4 mb-2">
                <label for="" class="required">{{ this.$t_notifications('no_reply_email') }}</label>
                <input type="email" class="form-control"  v-model="config.no_reply_email">
            </div>
        </div>

        <hr>
        <button @click="saveConfig" class="btn btn-success">{{ this.$t('buttons.save') }}</button>
    </card-template>
</template>
<script>
import {mapGetters} from 'vuex'
import VueToggles from 'vue-toggles'
export default {
    name: 'NotificationsConfig',
    components: {
        VueToggles
    },
    created() {
        this.$store.dispatch('notifications/getModuleConfigAction')
        this.$store.dispatch('notifications/getStatusChannelsAction')
    },
    computed: {
        ...mapGetters('notifications', [
            'hasNotificationsEnabled',
            'notificationsConfig',
            'hasEmailChannelEnabled',
            'hasTeamsChannelEnabled',
            'hasWhatsappChannelEnabled',
            'hasSMSChannelEnabled',
        ])
    },
    watch:{
        notificationsConfig(values) {
            this.config = {...values}
        }
    },
    data(){
        return {
            config: {}
        }
    },
    methods: {
        toggleEnabled() {
            this.config.module_enabled = !this.config.module_enabled
        },
        saveConfig() {
            this.$store.dispatch('notifications/saveModuleConfig',this.config)
        },
        testEmail(){
            axios.post('/api-admin/notifications/test/email',{}).then(response => {
                this.$toast.success(response);
            })
        },
        testTeams(){
            axios.post('/api-admin/notifications/test/teams',{}).then(response => {
                this.$toast.success(response);
            })
        },
        testWhatsapp(){
            axios.post('/api-admin/notifications/test/whatsapp',{}).then(response => {
                this.$toast.success(response);
            })
        },
        testSms(){
            axios.post('/api-admin/notifications/test/sms',{}).then(response => {
                this.$toast.success(response);
            })
        }
    }
}

</script>
