require('./bootstrap')
import locale from './locale'
import Alpine from 'alpinejs'

window.Vue = require('vue').default
const store = require('./store').default

//listen changes lang session
locale.listen()

require('./components/common_components')

window.Alpine = Alpine
Alpine.start()

require('./components')

//Load modules
function loadModuleComponentsFile(module) {
	require(`../../Modules/${module}/Resources/assets/js/app`)
}
function detectMobile() {
    const userAgent = navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
}

const sidebar = document.getElementById('accordionSidebar');

if(sidebar){
    if (detectMobile()) {
        sidebar.classList.add('toggled');
    } else {
        sidebar.classList.remove('toggled');
    }
}


loadModuleComponentsFile('Admin')
loadModuleComponentsFile('Profile')
loadModuleComponentsFile('Events')
loadModuleComponentsFile('Attachments')
loadModuleComponentsFile('Customers')
loadModuleComponentsFile('Installations')
loadModuleComponentsFile('Tasks')
loadModuleComponentsFile('Readings')
loadModuleComponentsFile('Alarms')
loadModuleComponentsFile('Sensors')
loadModuleComponentsFile('Aumenta')
loadModuleComponentsFile('Notifications')
if ($('#app').length) {
	const app = new Vue({
		el: '#app',
		store
	})
}
if ($('#toolbar-vue').length) {
    const app = new Vue({
        el: '#toolbar-vue',
        store
    })
}
