<template>
	<card-template :show_header='false'>
		<div class='row mb-3'>
			<div class='col-md-4'>
				<img class='img-fluid avatar mb-3' :src='image_path'>
				<div class='mb-3'>
					<div style="max-width: 380px;" class='custom-file'>
						<input type='file' @change='setFile' class='custom-file-input' id='file'>
						<label class='custom-file-label' for='file'>{{ fileName }}</label>
					</div>
				</div>
			</div>
			<div class='col-md-8'>
				<div class='row'>
					<div class="col-8 p-0">
                        <div class='col-md-6 mb-3'>
                            <label for='code' class='required'>{{ this.$installations_locale.global.code }}</label>
                            <input type='text' id='code' v-model='item.code' class='form-control'>
                        </div>
                        <div class='col-sm-12'>
                            <div class='form-group mb-3'>
                                <label>{{ this.$installations_locale.global.capacity }}</label>
                                <textarea class='form-control' v-model='item.capacity' id='capacity'></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <QR
                            :token="'cpp'+item.code"
                            :width="200"
                            align="text-center"
                        />
                    </div>
                    <div class='col-md-6 mb-3'>
                        <sensor-combo :current="this.item.sensors" @selected="setSensors" :multiple="true"></sensor-combo>
                    </div>
                    <div class='col-sm-12'>
                        <div class='form-group mb-3'>
                            <label>{{ this.$installations_locale.global.comments }}</label>
                            <textarea class='form-control' v-model='item.comments' id='comments'></textarea>
                        </div>
                    </div>
<!--					<div class='col-md-6 mb-3'>-->
<!--						<div class='from-group mb-3'>-->
<!--							<label for='temperature_control' class='required'>{{-->
<!--									this.$installations_locale.global.temperature-->
<!--								}}</label>-->
<!--							<input type='number' id='temperature_control' v-model='item.max_temperature' class='form-control'>-->
<!--						</div>-->
<!--					</div>-->

<!--					<div class='col-md-6 mb-5'>-->
<!--						<div class='form-check'>-->
<!--							<input v-model='item.access' type='checkbox' class='form-check-input' id='access'>-->
<!--							<label class='form-check-label' for='access'>{{-->
<!--									this.$installations_locale.global.deposit_access-->
<!--								}}</label>-->
<!--						</div>-->
<!--					</div>-->

<!--					<div class='col-md-6 mb-3'>-->
<!--						<div class='form-check'>-->
<!--							<input v-model='item.chlorine_auto' type='checkbox' class='form-check-input' id='chlorine_auto'>-->
<!--							<label class='form-check-label' for='chlorine_auto'>{{-->
<!--									this.$installations_locale.global.chlorine_auto-->
<!--								}}</label>-->
<!--						</div>-->
<!--					</div>-->
				</div>
			</div>
		</div>
		<div class='row'>


			<hr class='col-10 my-3'>

			<div class='col-12 my-3'>
<!--				<consigns-component @setConsigns='updateConsigns' :consigns='item.consigns' />-->
			</div>
		</div>

		<hr>
		<button @click='save' class='btn btn-primary'>{{ this.$installations_locale.global.buttons.save }}</button>
	</card-template>
</template>
<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
// import ConsignsComponent from '/../Modules/Alarms/Resources/assets/components/Consigns/ConsignsComponent'
import SensorCombo from '../../../../../../../Sensors/Resources/assets/components/SensorCombo'
import QR from "../../../../../../../../resources/js/components/QR/QR.vue";

export default {
	name: 'DepositForm',
	props: {
		installation_id: {required: true},
		circuit_id: {required: true},
		deposit_id: {},
	},
	components: {
        QR,
		vSelect,SensorCombo
		// ConsignsComponent
	},
	created() {
		if (this.deposit_id) {
			this.getItem()
		} else {
			this.resetItem()
		}
	},
	watch: {},
	data() {
		return {
			fileName: this.$installations_locale.global.choose_file,
			image_path: '/images/no-image.jpg',
			item: {},
		}
	},
	methods: {
		updateConsigns(consigns) {
			this.item.consigns = consigns
		},
		resetItem() {
			this.item = {
				circuit_id: this.circuit_id,
				code: null,
				access: false,
				capacity: null,
				max_temperature: 0.0,
				chlorine_auto: false,
				image: null,
				consigns: [],
				comments: '',
			}
		},
		getItem() {
			axios.get(`/api-admin/installations/${this.installation_id}/circuits/${this.circuit_id}/deposits/${this.deposit_id}`).then(response => {
				this.item = response.data
				this.item.access = response.data.access === 1 ? true : false
				this.item.chlorine_auto = response.data.chlorine_auto === 1 ? true : false
				this.image_path = response.data.foto
			})
		},
		setFile(e) {
			const file = e.target.files[0]
			this.fileName = file.name
			this.item.image = file
		},
        setSensors(sensors){
            this.item.sensors = sensors
        },
		save() {
			let formData = new FormData()
			const data = {...this.item}
			if (!data.code || data.code.includes(' ')) {
				return this.$toast.error('El código solo puede contener letras, números,-,_.  sin acentos ni espacios')
			}
			Object.keys(data).map(field => {
				formData.append(field, data[field] === null || data[field] === false ? '' : typeof data[field] === 'object' ? JSON.stringify(data[field]) : data[field])
			})
			axios.post(`/api-admin/installations/${this.installation_id}/circuits/${this.circuit_id}/deposits`, formData, {headers: {'content-type': 'multipart/form-data'}}).then(response => {
				this.$helpers.showLoading()
				this.$helpers.toastSuccess()
				setTimeout(() => {
					window.location = `/installations/${this.installation_id}/config/circuits/${this.circuit_id}/deposits/${response.data.id}`
				}, 1000)
			})
		},
	},

}
</script>
<style lang='css' scoped>
.custom-file-input:lang(en) ~ .custom-file-label:after {
	content: "\f093"; /* inspect an icon for this value */
	font-family: FontAwesome; /* intentionally no fallback */
	font-size: 16px;
}

.avatar {
	border-radius: 10px;
	border: solid 1px #ccc;
	padding: 10px;
}
</style>

