<template>
    <div>
        <label for="" class="required">{{$t_notifications('channels.select_service_title' )}}</label>

        <select @change="setCurrentProviderService"  class="form-control">
            <option value=""></option>
            <option :selected="ch === currentProviderService" v-for="ch in getComboChannels" :value="ch">{{ch}}</option>
        </select>
        <div v-if="currentProviderService" class="row mt-4 box-form">
            <hr>
            <div v-for="(structure_key) in Object.keys(getCurrentProviderStructure)" :key="structure_key" class="col-12 col-sm-6">
                <label for="" class="required">{{$t_notifications('channels.columns.' + structure_key)}}</label>
                <input class="form-control mb-2" :data-key="structure_key" @change="setCurrentConfig" type="text" :value="channelCurrentProviderConfig ? channelCurrentProviderConfig[structure_key] : null">
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters} from 'vuex'
export default {
    name: "ChannelProvidersForm",
    created(){

    },
    computed:{
        ...mapGetters('notifications',[
            'getComboChannels',
            'getCurrentProviderStructure',
            'currentProviderService',
            'channelCurrentProviderConfig',
            'currentChannelKey'
        ])
    },
    methods:{
        setCurrentProviderService(data){
            const service = data.target.value
            this.$store.commit('notifications/setCurrentProviderService',service)

        },
        setCurrentConfig(e){
            const key = e.target.getAttribute('data-key')
            const value = e.target.value
            this.$store.commit('notifications/setProviderFieldCurrentConfig',{key,value})
        },

    }
}
</script>

