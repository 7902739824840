import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate'
import {InstallationStore,ZoneStore,CheckpointStore} from '/../Modules/Installations/Resources/assets/store'
import notificationsState from '/app/Modules/Notifications/Resources/assets/js/store'

Vue.use(Vuex)


export default new Vuex.Store({
	modules:{
		installations: InstallationStore,
		zones: ZoneStore,
		checkpoints: CheckpointStore,
        notifications:notificationsState
	},
	plugins: [
		createPersistedState({
			storage: window.sessionStorage,
		}),
	],
})
