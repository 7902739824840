<template>
    <div>
        <modal :show="show" @close="close" @acept="countCpSelected" :id="'treeModal'">
            <div class="base col-lg-12 col-12 mb-2">
                <div class="w-100 text-center">
                    <span class="h4 font-weight-bold">{{ this.$tasks_locale.global.select_checkpoints_title }}</span>
                </div>

                <VueTree
                    ref="tree1"
                    showCheckbox
                    expandedAll
                    :treeData="this.treeData"
                    @on-checked-item="checkedItem"
                    @on-selected-change="clickNode"
                    @on-checked-change="clickCheckbox"
                />
            </div>
        </modal>
    </div>
</template>

<script>
import Modal from './Modal'
import VueTree from '../js/light-vue-tree';

export default {
    name: "CheckpointTreeModal",
    components: {
        Modal,
        'VueTree': VueTree,
    },
    data() {
        return {
            treeData: [],
            countCp: 0,
            formData: {
                checkpoints: []
            }
        }
    },
    props: ['show', 'checkpoints', 'deposits', 'accumulators','installation_id'],
    mounted() {
        this.setZonesCheckboxTree()
    },
    created() {

    },
    methods: {
        close: function () {
            $('body').css('overflow', 'visible');
            this.$emit('close')
        },
        countCpSelected: function () {
            let cpSelected = []
            let accSelected = []
            let depSelected = []
            $('.vue-tree input:checked').each((k, v) => {
                let node = $(v).closest('.vue-checkbox');
                let nodeDataId = node.attr('data-id');
                if (node.attr('data-type') == 'accum') {
                    nodeDataId != 0 ? accSelected.push(nodeDataId) : ''
                } else if (node.attr('data-type') == 'deposit') {
                    nodeDataId != 0 ? depSelected.push(nodeDataId) : ''
                } else {
                    nodeDataId != 0 ? cpSelected.push(nodeDataId) : ''
                }
            })
            $('#checkpointsSelected').val(cpSelected)
            $('#accumSelected').val(accSelected)
            $('#depSelected').val(depSelected)

            this.countCp = cpSelected.length + accSelected.length + depSelected.length
            $('#count-cp').text(this.countCp)
            $('.info-cp-selected').removeClass('d-none')
        },

        checkedItem({node, vNode}) {
            console.log('punto de control seleccionado')
        },
        clickNode(node) {
            // console.log("clickNode", node);
        },
        clickCheckbox({node, selectedData}) {
            // console.log("clickCheckbox", node, selectedData);
        },
        setZonesCheckboxTree() {
            axios.get(`/api-admin/installations/${this.installation_id}/zones/treeVue`,{hideLoader:true})
                .then((res) => {
                    res.forEach((v) => {
                        this.treeData.push(v)
                    });
                    this.checkpoints.forEach((v) => {
                        this.treeData.forEach((t) => {
                            this.searchTree(t, v)
                        });
                    })
                    this.setAccumulatorCheckboxTree()
                })
                .catch((err) => {
                })
        },
        setAccumulatorCheckboxTree() {
            axios.get(`/api-admin/installations/${this.installation_id}/circuits/treeVue`,{hideLoader:true})
                .then((res) => {
                    res.forEach((v) => {
                        this.treeData.push(v)
                    });
                    //this.setZonesCheckboxTree();
                    this.treeData.forEach((t) => {
                        this.accumulators.forEach((v) => {
                            this.searchTree(t, v)
                        });
                        this.deposits.forEach((v) => {
                            this.searchTree(t, v)
                        })
                    });
                })
                .catch((err) => {

                })
        },
        searchTree(element, matchingTitle) {
            if (element.id == matchingTitle) {
                element['checked'] = true;
                return element;
            } else if (element.children != null) {
                var i;
                var result = null;
                for (i = 0; result == null && i < element.children.length; i++) {
                    result = this.searchTree(element.children[i], matchingTitle);
                }
                return result;
            }
            return null;
        }
    }
}
</script>

<style scoped lang="scss">
    #treeModal{
        .footer-modal{
            position: sticky !important;
        }
    }
    .vue-checkbox-input{
        width: 15px;
        height: 15px;
    }
</style>
