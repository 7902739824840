<template>
    <div>
        <v-select
            style='min-width: 200px'
            :placeholder='this.$readings_locale.global.buttons.filter'
            :options='options'
            v-model='current'
            label='label'
            @input='getData'
            code='key'></v-select>
    </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
    name: "ReadingFilterCheckpoint",
    props: {
        refresh_fn: {required: true},
        // task_id: {required: true},
    },
    mounted() {
        console.log(this.options)
    },
    components: {
        vSelect,
    },
    data() {
        return {
            options: this.convertOptionsSelect(this.$t('checkpoint_types')),
            current: {},
        }
    },
    methods: {
        getData() {
            this.$emit('setFilter',{'checkpoint_filter':this.current.key})
        },
        convertOptionsSelect(data){
            let result=[];
            Object.keys(data).forEach((value,key)=>{
                result.push({'key':value,'label':data[value]})
            })
            return result;
        }
    },
}
</script>

<style scoped>

</style>
