<template>
	<div>
		<label v-if="label" :class="required ? 'required' : null">{{ this.$installations_locale.global.type }}</label>
		<v-select
			:placeholder="this.$installations_locale.global.type"
			:options="combo_types"
			v-model="current_type"
			label="label"
			@input='emitSelected'
			code="key"></v-select>
	</div>

</template>
<script>
import vSelect from 'vue-select'
import "vue-select/dist/vue-select.css"
export default{
	name:'CheckpointTypeCombo',
	props: {
		required: {default: false},
		current: {default: null},
		label: {default: true},
		installation_id: {required: true}
	},
	components: {
		vSelect
	},
	created() {
		this.getData()
		if(this.current) this.current_type = {...this.current}
	},
	watch: {
		current: function (value) {
			this.current_type = value ? {...value}: null
		}
	},
	data() {
		return {
			combo_types: [],
			current_type: null,
		}
	},
	methods: {
		getData() {
			const url = `/api-admin/installations/${this.installation_id}/checkpoints/types`
			axios.get(url).then(response => {
				this.combo_types = response
			})
		},
		emitSelected() {
			this.$emit('selected', this.current_type)
		}
	}
}
</script>