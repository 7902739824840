<template>
    <div class="col-sm-4 mb-2">
        <ul class="nav nav-pills" id="taskTabs" role="tablist">
            <li class="nav-item">
                <a class="nav-link  active nav-link-info" id="task-form-tab" data-toggle="pill"
                   href="#taskForm" role="tab" aria-controls="taskForm" aria-selected="true">
                    <i class="fa fa-edit"></i>
                </a>
            </li>
<!--            <li class="nav-item" v-if="task_id">-->
<!--                <a class="nav-link  nav-link-info " id="task-docs-tab" data-toggle="pill"-->
<!--                   href="#taskDocs"-->
<!--                   role="tab" aria-controls="taskDocs" aria-selected="false">-->
<!--                    <i class="fa fa-folder-open"></i>-->
<!--                </a>-->
<!--            </li>-->
        </ul>
    </div>
</template>

<script>
export default {
    name: "TaskNav",
    props: ['task_id']
}
</script>

<style scoped>

</style>
