<template>
    <div class="  mb-4 row ">
        <table class="table ">
            <tr class="w-100" v-for="sensorData in sensorsData" >
                <td>{{sensorData.name}}</td>
                <td>{{sensorData.last_value_reduce}} <i class="fa fa-warning ml-1 text-warning cursor-pointer" v-if="sensorData.hasAlarm" @click="goToAlarmDetail(sensorData.hasAlarm)"></i></td>
            </tr>

        </table>
    </div>
</template>
<script>

export default {
    name: "SensorDashboard",

    created() {
        this.getLastSensorsData()
    },

    data() {
        return {
            sensorsData:null
        }
    },
    computed: {},
    methods: {
        getLastSensorsData() {
            axios.get('/api-admin/sensors/last-values').then(response => {
                const rows = response.data
                this.sensorsData = rows
            })
        },
        goToAlarmDetail(alarmId){
            window.open(`/alarms/${alarmId}/edit`,'_blank')
        }

    },


}
</script>
<style lang="scss">

</style>
