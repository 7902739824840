<template>
    <card-template>
        <div class="row">
            <h4>Punto de control: {{this.checkPointEvent.id}} - Zona:  {{this.zoneName}}</h4>
        </div>
        <div class="row">
            <div class="col-12 col-md-2 mb-3">
                <div class="mb-3">
                    <div class="col-sm-12 mb-3">
                        <label for="tipoEvento" class="required">Tipo evento</label>
                        <select class="form-control" name="tipoEvento" id="tipoEvento">
                            <option value="incidencia" selected>Incidencia</option>
                            <option value="mantenimiento">Mantenimiento</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-2 mb-3">
                <div class="mb-3">
                    <div class="col-sm-12 mb-3">
                        <label for="fechaInicioEvento" class="required">Fecha inicio</label>
                        <input type="datetime-local"  id="fechaInicioEvento" class="form-control">
                    </div>
                </div>
            </div>
            <div class="col-md-2 col-12 mb-3">
                <div class="mb-3">
                    <div class="col-sm-12 mb-3">
                        <label for="fechaFinEvento" class="required">Fecha fin</label>
                        <input type="datetime-local"  id="fechaFinEvento" class="form-control">
                    </div>
                </div>
            </div>
            <div class="col-md-2 mb-3">
                <div class="mb-3">
                    <div class="col-sm-12 mb-3">
                        <label for="eventName" class="text-center w-100 required">¿Recurrente?</label>
                        <input type="checkbox"  @click="switchRecurrente()" id="isRecurrente" name="isRecurrente" class="form-control-sm form-control">
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-12 bloque-evento-recurrente recurrente-off">
                    <div class="row" style="margin-left: 1px">
                        <div id="repetir" class="bloque_repetir" style="/*filter:blur(2px)*/;">
                            <div class="row rounded pb-1 mx-0  p-1" style="border:1px solid black;">
                                <div class="mx-0 mt-1 btn-toolbar  col-12"
                                     role="toolbar" aria-label="Toolbar with button groups">
                                    <div class="form-group">
                                        <label for="repeatNumber" class="dark">
                                            Se repite Cada
                                        </label>
                                        <div class="btn-group" role="group" aria-label="First group">
                                            <input type="number" data-input="true" placeholder="Salto"
                                                   class="form-control required saveable col-4 recurrency repeatNumber" min="1"
                                                   max="99" id="repeatNumber"
                                                   name="repeatNumber" value="1">
                                            <button type="button" disabled class="btn btn-outline-primary btn-sm active"
                                                    id="btn-semanas">Semana/s
                                            </button>
                                            <button type="button" disabled class="btn btn-outline-primary btn-sm"
                                                    id="btn-meses">Mes/es
                                            </button>
                                            <button type="button" disabled class="btn btn-outline-primary btn-sm"
                                                    id="btn-anos">Año/s
                                            </button>
                                            <input name="hiddenPeriod{}[]"
                                                   id="hiddenPeriod{" type="hidden" value="#btn-semanas">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12 p-0 pl-1 mt-1">
                                    <div class="btn-toolbar col-12 row mx-0 p-0"
                                         role="toolbar" aria-label="Toolbar with button groups">

                                        <label class="dark pr-1" for="weekDays">
                                            Se repite los
                                        </label>
                                        <input name="weekDays" class="form-control days-of-week"
                                               id="weekDays" type="text"
                                               value="2"
                                               data-bind="value: weekDays">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <span class="small">Generadas:</span>
                                    <div id="infoOrden"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="offset-11 col-1 mt-5 pt-5 mb-3 borrarTarea borrarTarea">
                            <a role="button" class="btn btn-link p-0 btn-borrar-dir"
                               onclick="">
                                <img src="/images/iconos-tabla/borrar_2.png" height="30"/>
                            </a>
                        </div>
                    </div>
            </div>
        </div>
    </card-template>
</template>

<script>
export default {
    name: "EventForm",
    data() {
        return {
            idCheckpoint:null,
            checkPointEvent:[],
            zoneName:'',
            days:[
                {
                    Name: 'L',
                    Value: '1',
                    Checked: false
                },
                {
                    Name: 'M',
                    Value: '2',
                    Checked: false
                },
                {
                    Name: 'X',
                    Value: '3',
                    Checked: false
                },
                {
                    Name: 'J',
                    Value: '4',
                    Checked: false
                },
                {
                    Name: 'V',
                    Value: '5',
                    Checked: false
                },
                {
                    Name: 'S',
                    Value: '6',
                    Checked: false
                },
                {
                    Name: 'D',
                    Value: '7',
                    Checked: false
                }
            ]
        }
    },
    created() {
        this.getCheckpoint()

    },
    mounted() {
        this.daysOfWeekInputb()
        this.checkDaysSelected()
    },
    methods: {
        getCheckpoint(){
            this.idCheckpoint=1
            axios.get('/api-admin/events/checkpoint/'+this.idCheckpoint)
                .then((res)=>{
                    this.checkPointEvent=res
                    this.zoneName=res.zone.name
                    console.log(res)
                })
                .catch((err)=>{

                })
        },
        daysOfWeekInputb(){
            var $field = $('#weekDays');
            console.log($field)
            var currentDays = $field.val().split('');
            for (var i = 0; i < currentDays.length; i++) {
                var dayA = currentDays[i];
                for (var n = 0; n < this.days.length; n++) {
                    var dayB = this.days[n];
                    if (dayA === dayB.Value || new Date().getDay() == dayB.value || (new Date().getDay() == 0 && dayB.value)) {
                        dayB.Checked = true;
                        currentDays[n] = this.days[n].Value;
                    }
                }
            }
            $field.val(currentDays.join(''))
            $field.attr('type', 'hidden');
            var options = '';
            var n = 1;
            // while ($('.days' + n).length) {
            //     n = n + 1;
            // }
            var optionsContainer = 'days' + n;
            $field.before('<div class="days ' + optionsContainer + '"></div>');

            for (var i = 0; i < this.days.length; i++) {
                var day = this.days[i];
                var id = 'day' + day.Name + n;
                var checked = day.Checked ? 'checked="checked"' : '';
                options = options + '<div><input  disabled type="checkbox" value="' + day.Value + '" id="' + id + '" ' + checked + ' /><label for="' + id + '">' + day.Name + '</label></div>';
            }

            $('.' + optionsContainer).html(options);
        },
        initDaysSelector(){
            $('.days-of-week').daysOfWeekInputb()
        },
        switchRecurrente(){
            let recurrenteSection = $('.bloque-evento-recurrente')
            let daysCheckbox = recurrenteSection.find('input[type="checkbox"]')
            if(recurrenteSection.hasClass('recurrente-off')){
                recurrenteSection.removeClass('recurrente-off')
                daysCheckbox.removeAttr('disabled')
            }else{
                recurrenteSection.addClass('recurrente-off')
                daysCheckbox.attr('disabled',true)
            }

        },
        checkDaysSelected(){
            $('body').on('change', '.days input[type=checkbox]', function () {
                var value = $(this).val();
                var index = getIndex(value);
                if (this.checked) {
                    updateField(value, index);
                } else {
                    updateField(' ', index);
                }
            });
        },
        updateField(){
            let value = event.target.value;
            console.log(value)
        }
    }
}
</script>

<style lang="css" >
.bloque-evento-recurrente.recurrente-off{
    filter: blur(3px);
}
.weekDays input[type=checkbox]:checked + label {
    background: #7B9FCE;
    color: #f9fffe;
}

.days input[type=checkbox] {
    display: none;
}

.days input[type=checkbox] + label {
    cursor: pointer;
    border-radius: 50% !important;
    padding: calc(0.1rem) !important;
    min-width: 1.6rem !important;
    min-height: 1.6rem !important;
    width: 1.6rem !important;
    height: 1.6rem;
    margin-right: 3px;
    font-size: 0.9rem;
    text-align: center;
    background: #f9fffe;
    color: #1e1e1e;
    border: 1px solid #7B9FCE;
}

.days input[type=checkbox]:checked + label {
    background: #7B9FCE;
    color: #f9fffe;
}

.days > div {
    display: inline-block;
}
</style>
