<template>
    <div>
        <v-select
            style='min-width: 200px'
            :placeholder='this.$tasks_locale.global.buttons.filter'
            :options='options'
            v-model='current'
            label='label'
            @input='getData'
            code='key'></v-select>
    </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
    name: "TaskFilter",
    props: {
        refresh_fn: {required: true},
        states: {required: true},
        // task_id: {required: true},
    },
    components: {
        vSelect,
    },
    data() {
        return {
            options: [
                {key: 'all', label: this.$tasks_locale.global.states.filter_all}
            ],
            current: {},
        }
    },
    created() {
        this.setStateOptions()
    },
    methods: {
        getData() {
            this.$emit('setFilter', this.current)
        },
        setStateOptions() {
            this.states.forEach((v) => {
                this.options.push({key: v.id, label: this.$tasks_locale.global.states_trans[v.text]})
            })
            this.current={key: 1, label: this.$tasks_locale.global.states_trans.open}
        }
    },
}
</script>

<style scoped>

</style>
