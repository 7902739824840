<template>
    <div class="modal fade" :id="id" tabindex="-1" role="dialog" aria-labelledby="notificationsDetailModal"
         aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header bg bg-primary">
                    <h5 class="modal-title" id="notificationsModalTitle">
                        <i class="fa fa-bell mr-1"></i>
                        {{ $t_notifications('notification') }}: <b> {{ getCurrentNotificationDetail.subject }}</b>
                    </h5>
                    <button @click="hideModal" class="close" type="button" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div>
                    <div class="modal-body">
                        <div class="notification-details">
                            <div class="notification-message">
                                <p>{{ getCurrentNotificationDetail.message }}</p>
                            </div>
                            <div class="notification-metadata">
                                <div class="notification-date">
                                    <b>{{ getCurrentNotificationDetail.date }}</b>
                                </div>
                                <div class="notification-receivers ">
                                    <table class="table table-bordered">
                                        <thead>
                                        <tr>
                                            <th>{{ $t_notifications('management.columns.user') }}</th>
                                            <th>{{ $t_notifications('management.columns.channels') }}</th>

                                            <th v-if="management">
                                                {{ $t_notifications('management.columns.users_system') }}
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="receiver in getCurrentNotificationDetail.users" :key="receiver.id">
                                            <td>{{ receiver.name }}</td>
                                            <td >
                                                <img class="mr-1"
                                                     v-for="channel in receiver.channels"
                                                     aria-selected="false" data-bs-toggle="tooltip"
                                                     :title="channel.name" width="20"
                                                     :src="'/notifications/images/'+channel.key +'.png'" alt="">
                                            </td>

                                            <td v-if="management">
                                                <span class="text text-success"
                                                      v-if="receiver.user_id"><b>{{
                                                        $t_notifications('yes')
                                                    }}</b></span>
                                                <span class="text text-danger"
                                                      v-else><b>{{ $t_notifications('no') }}</b></span>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button @click="hideModal" class="btn btn-dark" type="button" data-dismiss="modal">
                            {{ this.$t('buttons.cancel') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>


import vSelect from "vue-select";
import {mapGetters} from "vuex";

export default {
    name: 'NotificationDetailsModal',
    components: {vSelect},
    props: {
        showModal: {default: false},
        management: {default: false}
    },

    watch: {
        showModal() {
            this.show_modal = this.showModal
            if (this.showModal) {
                $(`#${this.id}`).modal({backdrop: "static"})
                $(`#${this.id}`).modal('show')
            }

        }
    },
    created() {

    },
    computed: {
        ...mapGetters('notifications', ['getCurrentNotificationDetail'])
    },
    data() {
        return {
            id: "notificationsDetailModal",
            show_modal: false
        }
    },
    methods: {
        hideModal() {
            $(`#${this.id}`).modal('hide')
            this.$emit('showModalDetail', false)
        }
    }
}

</script>
<style scoped>
.notification-details {
    background-color: #fff;
    border-radius: 0.25rem;
    box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.3);
    padding: 1rem;
}

.notification-message {
    font-size: 1.25rem;
    margin-bottom: 1rem;
}

.notification-date {
    font-size: 0.875rem;
}

.notification-receivers {

    overflow-y: auto;
    margin-top: 1rem;
}

</style>
