<template>
    <div style="min-height: 400px">
        <sensor-filter
            :installations="installations"
            :data="items"
            @setFilter="setFilter"
            class='my-2'
            :refresh_fn='refreshData'></sensor-filter>
        <v-client-table :ref='idTable' :data='items' :columns='columnsData' :options='options'>
            <div slot="checkpoints" slot-scope="{row}">
                <ul class="m-0">setFilter
                    <li v-for="checkpoint in row.checkpoints">
                        <span>{{ checkpoint }}</span>
                    </li>
                </ul>
            </div>
        </v-client-table>
    </div>
</template>
<script>
import SensorFilter from './SensorFilter'
import 'animate.css';

export default {
    name: "SensorHistoryTable",
    props: {
        installations: {required: true},
    },
    components: {
        SensorFilter
    },
    created() {
        this.setColumns()
        this.setInstallation()
        this.filterResults()
    },
    data() {
        return {
            items: [],
            idTable: 'sensorHistoryTable',
            filters: {
                date_range: [null, null],
                date_period: 'today',
                installation: null
            },
            columnsData: [],
            columns: [
                {
                    field: 'created_at',
                    label: this.$sensors_locale.global.columns.date,

                },
                {
                    field: 'name',
                    label: this.$sensors_locale.global.columns.name,

                },
                {
                    field: 'code',
                    label: this.$sensors_locale.global.columns.code,
                    display: 'min_tabletP',

                },
                {
                    field: 'value',
                    label: this.$sensors_locale.global.columns.last_value
                }

            ],
            options: {
                rowClassCallback: row => {
                    return row.disabled ? 'disabled-item' : null
                },
                orderBy: {
                    column: 'created_at',
                    ascending: false
                },
                filterable: true,
                resizableColumns: true,
                perPage: 10,
                perPageValues: [],
                pagination: {chunk: 5},
                datepickerOptions: {
                    showDropdowns: true,
                    autoUpdateInput: true,
                },
                sortIcon: {
                    base: 'fa float-right',
                    is: 'fa-sort',
                    up: 'fa-sort-up',
                    down: 'fa-sort-down',
                },
                texts: {
                    count: `${this.$t('Showing {from} to {to} of {count} records|{count} records|One record')}`,
                    first: `${this.$t('First')}`,
                    last: `${this.$t('Last')}`,
                    filter: `${this.$t('Filter')}:`,
                    filterPlaceholder: `${this.$t('Search query')}`,
                    limit: `${this.$t('Records')}:`,
                    page: `${this.$t('Page')}:`,
                    noResults: `${this.$t('No matching records')}`,
                    filterBy: `${this.$t('Filter by {column}')}`,
                    loading: `${this.$t('Loading')}...`,
                    defaultOption: `${this.$t('Select {column}')}`,
                    columns: `${this.$t('Columns')}`,
                },
                columnsDisplay: {},
            },
        }
    },
    methods: {
        setColumns() {
            const headings = {}
            this.columns.map(item => {
                this.columnsData.push(item.field)
                headings[item.field] = item.label
                if (item.display) {
                    this.options.columnsDisplay[item.field] = item.display
                }
            })
            this.options.headings = headings
        },
        refreshData(items) {
            this.items = items
        },
        setFilter(filter) {
            console.log(filter)
            Object.keys(filter).map(item => {
                this.filters[item] = filter[item]
            });
            this.filterResults()
        },
        filterResults() {
            axios.post('/api-admin/sensors/history', this.filters).then(response => {
                const rows = response.data
                this.items = rows
                // this.refreshData(rows)
            })
        },
        restore(item) {
            axios.get(`/api-admin/sensors/history`).then(response => {
                this.filterResults()
            })
        },
        setInstallation(installation = null) {
            if (installation) {
                this.current_installation = installation
            } else {
                this.current_installation = this.installations ? this.installations[0] : null
            }
            this.filters.installation = this.current_installation
        },
    },
}
</script>

<style lang='scss'>

tr.VueTables__row.disabled-item {
    background-color: #ffe5e5 !important;
}

.VueTables {
    label {
        margin-right: 10px;
    }
}

.VueTables__wrapper {
    max-height: 500px;
    overflow-y: scroll;
}

.VueTables__search-field {
    display: flex;
}

.VueTables__limit-field {
    display: flex;
}

.VueTables__columns-dropdown-wrapper {
    margin-right: 10px;
}

.VueTables__row {
    td {
        padding: 3px 5px;
        vertical-align: middle;
    }
}

.VueTables__columns-dropdown {
    input {
        margin-right: 3px;
    }
}

.VueTables__limit-field {
    select {
        margin: 5% 0;
    }

}

.table-reading-detail {
    font-size: 13px;

    tr {
        text-align: left;
    }

    td {
        text-align: center;
        padding: 2px 0;
        color: black;
        vertical-align: middle;
    }
}

#sensorHistoryTable {
    .table-responsive {
        max-height: 60vh !important;
    }
}

.modal-detail-reading {
    color: black;
    font-size: 13px;

}
</style>
