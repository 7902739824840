<template>
    <div>
        <label>{{$t_notifications('users_management.select_channels')}}</label>
        <v-select  @input="add" v-model="selected" :multiple="multiple" :options="items" label="key" code="id"></v-select>
    </div>
</template>
<script>
import vSelect from "vue-select";

export default{
    name:'ChannelsSelector',
    components: {vSelect},
    props: {
        'current': {type: Array},
        'multiple': {default: false}
    },
    watch: {
        current: function () {
            this.selected = [...this.current]
        }
    },
    data() {
        return {
            selected: [],
            items: []
        }
    },
    created() {
        this.getChannels()
        this.selected = this.current ? [...this.current] : []
    },
    methods:{
        getChannels(){
            axios.get('/api-admin/notifications/channels').then(response => {
                this.items = response.data
            })
        },
        add() {
            this.$emit('add', [...this.selected])
        }
    }
}

</script>
