<template>
    <div>
        <notifications-general :management="true"></notifications-general>
    </div>
</template>
<script>

import {mapGetters} from "vuex";
import NotificationsGeneral from "../NotificationsGeneral.vue";
import {setManagement} from "../../store/mutations";

export default{
    name:'NotificationsTable',
    components: {NotificationsGeneral},
    created() {
        this.$store.commit('notifications/setManagement',true)
    },
    computed: {
    },
    data() {
        return {

        }
    },
    methods:{
    }
}

</script>
