<template>
    <card-template>
        <div class="row">
            <div class="col-12 mb-3">
                <ToggleChannel :channel="getCurrentChannel"/>
            </div>
        </div>

        <ChannelProvidersForm  v-if="isProviderChannel"/>
        <ChannelServiceForm v-else/>
        <hr>
        <button @click="saveConfig" class="btn btn-success">{{ this.$t('buttons.save') }}</button>
        <a href="/notifications/channels" class="btn btn-danger">{{ this.$t('buttons.cancel') }}</a>
    </card-template>
</template>
<script>
import ChannelProvidersForm from "./ChannelProvidersForm";
import ChannelServiceForm from "./ChannelServiceForm.vue";
import ToggleChannel from "./ToggleChannel.vue";
import {mapGetters} from 'vuex'
import {getChannelStructureAction} from "../../store/actions";

export default {
    name: 'ConfigChannel',
    props: {
        key_channel: {required: true}
    },
    components: {
        ChannelProvidersForm,
        ChannelServiceForm,
        ToggleChannel
    },
    computed: {
        ...mapGetters('notifications', ['getCurrentChannel','isProviderChannel']),
    },
    async created() {
        this.$store.commit('notifications/resetChannelsData')
        this.$store.commit('notifications/setCurrentChannelKey', this.key_channel)
        this.$store.dispatch('notifications/getChannelStructureAction')
        await this.$store.dispatch('notifications/getCurrentChannelAction')
        this.$store.commit('notifications/setCurrentConfigOnStructure')
        this.$store.dispatch('notifications/upsetCurrentProvider')
        this.$store.dispatch('notifications/upsetCurrentService')
    },

    methods: {
        saveConfig() {
            this.$store.dispatch('notifications/saveChannelConfig', this.key_channel);
        }
    }
}
</script>

<style>
.box-form{
    background: #e3e3e3;
    padding: 20px;
    margin: 0 0;
    box-shadow: 0 0 10px #c3c3c3;
    border-radius: 10px;
}
</style>
