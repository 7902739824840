<template>
    <div class="row">
        <div class="col-12">
                    <h5 style="cursor: pointer">
                        <span class=" badge badge-primary badge-xl" @click="setValue">
                       {{ this.title }}
                        <i class="fa fa-info-circle ml-1" :title="this.info"></i>
                        <i v-if="setted" class="fa fa-times text-danger ml-1" @click="removeValue"></i>
                    </span>
                    </h5>
        </div>
    </div>
</template>

<script>

export default {
    name: "CurrentTimestampEvaluate",
    props: ['current_value'],
    data() {
        return {
            title: 'current_timestamp',
            value: 'current_timestamp',
            setted:false,
            info: 'Tiempo actual a comparar contra la condición seleccionada. '
        }
    },
    methods: {
        setValue() {
           if( !this.setted){
               this.$emit('setValue',this.value)
               this.setted =true
           }else{
               this.setted = false
               this.$emit('removeValue')
           }

        }
    }
}
</script>

<style scoped>

</style>
