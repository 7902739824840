<template>
    <div id="ZonesModule">
        <div id="zoneToolbar" class="alert alert-dark mb-2">
            <div class="row">
                <div class="col-sm-4 mb-2">
                    <ul class="nav nav-pills" id="zoneViews" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active nav-link-info" id="zone-tree-tab" data-toggle="pill" href="#zoneTree" role="tab" aria-controls="zoneTree" aria-selected="true">
                                <i class="fa fa-folder-tree"></i>
                            </a>

                        </li>

                        <li class="nav-item">
                            <a class="nav-link nav-link-info" id="zone-search-tab" data-toggle="pill" href="#zoneSearch" role="tab" aria-controls="zoneSearch" aria-selected="false">
                                <i class="fa fa-search"></i>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-sm-8 text-right mb-2">
                    <a :href="newZoneUrl"  class="btn btn-success btn-sm"><i class="fa fa-plus"></i>
                        {{ this.$installations_locale.global.new_main_zone }}
                    </a>
                </div>
            </div>

        </div>

        <div class="tab-content" id="pills-tabContent">
            <card-template class="tab-pane show fade active" role="tabpanel" id="zoneTree" :show_header="false" padding="p-0">
                <zone-component :refresh_fn="refreshData" :edit_fn="editZone"  :installation_id="installation_id" :zones="zones"/>
            </card-template>
            <card-template class="tab-pane fade" role="tabpanel" id="zoneSearch" :show_header="false" >
                <zone-search-component :edit_fn="editZone"  :installation_id="installation_id"></zone-search-component>
            </card-template>
        </div>

        <zone-modal :parent_zone="parent_zone" :refresh_fn="refreshData" :current="current" :installation_id="installation_id" :id="modal_id"/>
        <modal-confirm-delete-fn />
        <modal-confirm-fn />
    </div>
</template>
<script>
import ZoneComponent from './ZoneComponent'
import ZoneModal from './ZoneModal'
import ZoneSearchComponent from './ZoneSearchComponent'
import {mapGetters} from 'vuex'
export default {
    name: 'ZoneModule',
    props: {
        installation_id: {required: true}
    },
    components: {
        ZoneComponent,
        ZoneModal,
        ZoneSearchComponent,
    },
	computed:{
		...mapGetters('zones',['newZoneUrl']),
	},
    created() {
		// this.$store.commit('zones/removeCurrent')
        this.getData()
    },
    data() {
        return {
            parent_zone: null,
            current: null,
            modal_id: 'ZoneModalForm',
            zones: {}
        }
    },
    methods: {
        getData() {
            axios.get(`/api-admin/installations/${this.installation_id}/zones/tree`).then(response => {
                this.zones = response
            })
        },
        refreshData(tree) {
            this.zones = tree
        },
        hasChildren(zone) {
            return zone.filter((item, key) => key !== 'zone')
        },

        editZone(item) {
            this.current = item
            $(`#${this.modal_id}`).modal('show')
        },



    }
}
</script>
