<template>
    <card-template>
        <template slot="titleCard">{{ this.$readings_locale.global.readings_list }}</template>
        <div class="table-responsive">
            <reading-table
                :type="'af'"
                :enableCreateBtn="false"
                :idTable="idTable"
                :columnsData="columns"
                :customFilters="customFilters"
                :rowItems="items"
                :customFields="customFields"
                :btnConfig="false"
                @edit="editItem"
                @delete="deleteItem"
            ></reading-table>
        </div>
        <modal-confirm-delete @confirmed="removeItem"/>

    </card-template>
</template>
<script>

export default{
    name: 'ReadingDepositList',
    props:['readings_ids'],
    mounted() {
        this.checkTabClick()
    },
    data() {
        return {
            idTable: 'readingsTable',
            items: [],
            customFilters: [
                'date_range'
            ],
            customFields: [

            ],
            columns:[
                {
                    "field": "id",
                    "label": "#",
                    "display": "min_tabletP"
                },
                {
                    "field": "reading_date",
                    "label": this.$readings_locale.global.table_columns.reading_date
                },
                {
                    "field": "installation_name",
                    "label": this.$readings_locale.global.table_columns.installation
                },{
                    "field": "item_name",
                    "label": this.$readings_locale.global.table_columns.deposit
                },
                {
                    "field": "options",
                    "label": this.$readings_locale.global.table_columns.detail
                }
            ]
        }
    },
    computed: {},
    methods: {
        checkTabClick(){
          $('#pills-af-tab').one('click',()=>{
              this.getReadings()
          })
        },
        getReadings() {
            let queryParams = '';
            queryParams=`?readings_id=${JSON.stringify(this.readings_ids)}`
            axios.get(`/api-admin/readings/getReadings/af/${queryParams}`).then(response => {
                const rows = response.data
                this.items = rows
            })
        },
        editItem(item) {
            window.location = `/readings/${item.id}`
        },
        deleteItem(item) {
            this.$bus.$emit('fireModalConfirmDelete', {
                text: `${this.$readings_locale.global.modals.delete_task} ${item.name} `,
                parameters: item
            })
        },
        removeItem(item) {
            axios.delete(`/api-admin/readings/${item.id}`).then(response => {
                const rows = response.data
                this.items = rows
                this.$toast.success(this.$t('toast.delete_success'))
            })
        },
        newItem() {
            window.location = '/readings/create'
        },
        setItem(item){
            window.location = `/readings/${item.id}/config`
        }
    }

}
</script>

<style lang="scss">
.VueTables__table{
    td{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        max-width: 300px;
        font-size: 14px;

    }
    //td:hover{
    //    max-width: 300px;
    //    font-size: 14px;
    //    white-space: normal;
    //    overflow: visible;
    //}

}
.table-responsive:not(.VueTables .table-responsive){
    overflow: hidden;
    height: 100vh;
}
</style>
